import React from "react";
import ReactDOM from "react-dom/client";



import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import axios from "axios";


axios.defaults.baseURL=process.env.REACT_APP_API_URL;


// Render the root component
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
     <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>
);





















// import React from "react";
// import ReactDOM from "react-dom/client";

// import App from "./App";
// import axios from "axios";


// //axios.defaults.baseURL = "https://cb5c-105-161-116-52.ngrok-free.app";
// //axios.defaults.baseURL=process.env.REACT_APP_API_URL;

// // Create Axios instance with retry configuration
// const axiosInstance = axios.create({
//   baseURL:process.env.REACT_APP_API_URL,
//   timeout: 5000,
// });

// axiosInstance.defaults.retry = 3; // Maximum number of retries
// axiosInstance.defaults.retryDelay = 1000; // Delay between retries (in milliseconds)

// // Add interceptor to handle retries
// axiosInstance.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
//   const config = err.config;
//   // If config does not exist or the retry option is not set, reject
//   if (!config || !config.retry) return Promise.reject(err);

//   // Set the variable for keeping track of the retry count
//   config.retryCount = config.retryCount || 0;

//   // Check if we've maxed out the total number of retries
//   if (config.retryCount >= config.retry) {
//     return Promise.reject(err);
//   }

//   // Increase the retry count
//   config.retryCount += 1;

//   // Create new promise to handle exponential backoff
//   const backoff = new Promise(function (resolve) {
//     setTimeout(function () {
//       resolve();
//     }, config.retryDelay || 1);
//   });

//   // Return the promise in which recalls axios to retry the request
//   return backoff.then(function () {
//     return axiosInstance(config);
//   });
// });




// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
