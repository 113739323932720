import React, { useEffect } from "react";

const GHPageHub = () => {
  useEffect(() => {
    // Redirect immediately upon component mount
    window.location.href = "https://www.megacashluckybox.com/gh";
  }, []);

  return (
    <div>
      <p>Redirecting to Mega Cash Lucky Box...</p>
    </div>
  );
};

export default GHPageHub;


// import React, { useEffect, useState, useCallback } from "react";
// import { useLocation } from "react-router-dom";
// import swal from 'sweetalert2';
// import DisplayTable from "../components/PawapayDisplayTable";
// import Header from "../components/HeaderGhana";
// import HubtelPaymentForm from "../components/HubtelPaymentForm";
// import Footer from "../components/FooterGhana";
// import airteltigoLogo from '../assets/airteltigo_gha.png';
// import mtnLogo from '../assets/mtn_momo_gha.png';
// import vodafoneLogo from '../assets/vodafone_gha.png';
// import PrizeTable from "../components/PrizeTable";
// import LoadingSpinner from '../components/LoadingSpinner';

// const DEFAULT_REFERRAL_CODE = "2000";
// const MIN_AGE = 18;

// const GHPageHub = () => {
//   const [ageVerified, setAgeVerified] = useState(false);
//   const [age, setAge] = useState(null);
//   const [referralCode, setReferralCode] = useState(DEFAULT_REFERRAL_CODE);

//   const location = useLocation();

//   const checkAgeVerification = useCallback(async () => {
//     const storedAgeVerified = localStorage.getItem('ageVerified');
//     const storedAge = localStorage.getItem('age');

//     if (storedAgeVerified && storedAge) {
//       setAgeVerified(true);
//       setAge(parseInt(storedAge, 10));
//       return;
//     }

//     const { value: inputAge } = await swal.fire({
//       title: 'Age Verification',
//       text: `You must be ${MIN_AGE} or older to access this site.`,
//       input: 'number',
//       inputAttributes: {
//         placeholder: 'Enter your age',
//         min: 0,
//         max: 99,
//         step: 1
//       },
//       confirmButtonColor: '#e76a20',
//       showCancelButton: false,
//       confirmButtonText: 'Continue',
//       allowOutsideClick: false,
//       allowEscapeKey: false,
//       preConfirm: (age) => {
//         if (age < MIN_AGE) {
//           swal.showValidationMessage(`You must be ${MIN_AGE} or older to access this site.`);
//         }
//         return age;
//       }
//     });

//     if (inputAge >= MIN_AGE) {
//       localStorage.setItem('ageVerified', 'true');
//       localStorage.setItem('age', inputAge.toString());
//       setAge(inputAge);
//       setAgeVerified(true);
//     } else {
//       swal.fire('Access Denied', `You must be ${MIN_AGE} or older to access this site.`, 'error');
//     }
//   }, []);

//   useEffect(() => {
//     const params = new URLSearchParams(location.search);
//     const referral = params.get("referral");
//     if (referral) {
//       setReferralCode(referral);
//     }

//     console.log("Referral Code:", referral || DEFAULT_REFERRAL_CODE);

//     if (!ageVerified) {
//       checkAgeVerification();
//     }
//   }, [ageVerified, location.search, checkAgeVerification]);

//   if (!ageVerified) {
//     return (
//       <div style={{ textAlign: 'center' }}>
//         <h2>Age Verification Pending...</h2>
//         <LoadingSpinner />
//       </div>
//     );
//   }

//   const HubtelPaymentFormProps = {
//     currency: "GHS",
//     currencyOperator: "GHS",
//     countrycode: 233,
//     countryName: "Ghana",
//     minAmount: 5,
//     maxAmount: 2000,
//     platform: "WEB",
//     serviceCode: referralCode,
//     winuptoAmount: "500,000",
//     validPrefixes: ["02", "05"],
//     operators: [
//       { id: 'AirtelTigo', name: 'AirtelTigo', logo: airteltigoLogo },
//       { id: 'MTN', name: 'MTN', logo: mtnLogo },
//       { id: 'Telecel', name: 'Telecel', logo: vodafoneLogo }
//     ],
//     operatorValues: {
//       "AirtelTigo": "AT",
//       "MTN": "MTN",
//       "Telecel": "TELECEL"
//     },
//     age,
//     referralCode
//   };

//   return (
//     <div className="App container-fluid d-flex flex-column min-vh-100">
//       <Header />
//       <main className="flex-grow-1" style={{ marginTop: '50px' }}>
//         <section className="hero-section">
//           <div className="container">
//             <div className="row">
//               <HubtelPaymentForm {...HubtelPaymentFormProps} />
//               <DisplayTable
//                 countryCode={HubtelPaymentFormProps.countrycode.toString()}
//                 currency={HubtelPaymentFormProps.currency}
//                 firstDigits={HubtelPaymentFormProps.validPrefixes.map(Number)}
//                 minBetAmount={HubtelPaymentFormProps.minAmount}
//                 maxBetAmount={HubtelPaymentFormProps.maxAmount}
//                 count={16}
//                 tableHeading="Top Recent Winners"
//                 age={age}
//                 referralCode={referralCode}
//               />
//             </div>
//           </div>
//         </section>
//         <PrizeTable />
//       </main>
//       <Footer />
//     </div>
//   );
// };

// export default GHPageHub;



// #####################################################################################################
// import React, { useEffect, useState } from "react";
// import swal from 'sweetalert2';
// import DisplayTable from "../components/PawapayDisplayTable";
// import Header from "../components/HeaderGhana"
// import HubtelPaymentForm from "../components/HubtelPaymentForm";
// import Footer from "../components/FooterGhana";
// import airteltigoLogo from '../assets/airteltigo_gha.png';
// import mtnLogo from '../assets/mtn_momo_gha.png';
// import vodafoneLogo from '../assets/vodafone_gha.png';
// import PrizeTable from "../components/PrizeTable";
// import LoadingSpinner from '../components/LoadingSpinner';

// const GHPageHub = () => {
//   const [ageVerified, setAgeVerified] = useState(false);
//   const [age, setAge] = useState(null); // Add age state

//   useEffect(() => {
//     const checkAgeVerification = async () => {
//       const ageVerified = localStorage.getItem('ageVerified');
//       if (!ageVerified) {
//         const { value: age } = await swal.fire({
//           title: 'Age Verification',
//           text: 'You must be 18 or older to access this site.',
//           input: 'number',
//           inputAttributes: {
//             placeholder: 'Enter your age',
//             min: 0,
//             max: 99,
//             step: 1
//           },
//           confirmButtonColor: '#e76a20',
//           showCancelButton: false,
//           confirmButtonText: 'Continue',
//           allowOutsideClick: false,
//           allowEscapeKey: false,
//           preConfirm: (age) => {
//             if (age < 18) {
//               swal.showValidationMessage('You must be 18 or older to access this site.');
//             }
//           }
//         });

//         if (age >= 18) {
//           localStorage.setItem('ageVerified', 'true');
//           setAge(age); // Store the age
//           setAgeVerified(true);
//         } else {
//           swal.fire('Access Denied', 'You must be 18 or older to access this site.', 'error');
//         }
//       } else {
//         setAgeVerified(true);
//         setAge(localStorage.getItem('age')); // Retrieve stored age
//       }
//     };

//     if (!ageVerified) {
//       checkAgeVerification();
//     }
//   }, [ageVerified]);

//   if (!ageVerified) {
//     return (
//       <div style={{ textAlign: 'center' }}>
//         <h2>Age Verification Pending...</h2>
//         <LoadingSpinner />
//       </div>
//     );
//   }

//   const HubtelPaymentFormProps = {
//     currency: "GHS",
//     currencyOperator: "GHS",
//     countrycode: 233,
//     countryName:"Ghana",
//     minAmount: 5,
//     maxAmount: 2000,
//     platform: "WEB",
//     serviceCode: "2000",
//     winuptoAmount: "500,000",
//     validPrefixes: ["02", "05"],
//     operators: [
//       { id: 'AirtelTigo', name: 'AirtelTigo', logo: airteltigoLogo },
//       { id: 'MTN', name: 'MTN', logo: mtnLogo },
//       { id: 'Telecel', name: 'Telecel', logo: vodafoneLogo }
//     ],
//     operatorValues: {
//       "AirtelTigo": "AT",
//       "MTN": "MTN",
//       "Telecel": "TELECEL"
//     },
//     age: age, // Pass the age
//   };

//   return (
//     <div className="App container-fluid d-flex flex-column min-vh-100">
//       <Header />
//       <main className="flex-grow-1" style={{ marginTop: '50px' }}>
//         <section className="hero-section">
//           <div className="container">
//             <div className="row">
//               <HubtelPaymentForm {...HubtelPaymentFormProps} />
//               <DisplayTable
//                 countryCode={HubtelPaymentFormProps.countrycode.toString()}
//                 currency={HubtelPaymentFormProps.currency}
//                 firstDigits={HubtelPaymentFormProps.validPrefixes.map(Number)}
//                 minBetAmount={HubtelPaymentFormProps.minAmount}
//                 maxBetAmount={HubtelPaymentFormProps.maxAmount}
//                 count={16}
//                 tableHeading="Top Recent Winners"
//               />
//             </div>
//           </div>
//         </section>
//         <PrizeTable />
//       </main>
//       <Footer />
//     </div>
//   );
// };

// export default GHPageHub;





// import React, { useEffect, useState } from "react";
// import swal from 'sweetalert2';
// import DisplayTable from "../components/PawapayDisplayTable";
// import Header from "../components/HeaderGhana";
// import PaymentForm from "../components/PawapayPaymentForm";
// import HubtelPaymentForm from "../components/HubtelPaymentForm";
// import Footer from "../components/FooterGhana";
// import airteltigoLogo from '../assets/airteltigo_gha.png';
// import mtnLogo from '../assets/mtn_momo_gha.png';
// import vodafoneLogo from '../assets/vodafone_gha.png';
// import PrizeTable from "../components/PrizeTable";
// import LoadingSpinner from '../components/LoadingSpinner';

// const GHPageHub = () => {
//   const [ageVerified, setAgeVerified] = useState(false);
//   const [phoneVerified, setPhoneVerified] = useState(false);
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [otp, setOtp] = useState('');
//   const [age, setAge] = useState(null); // Add age state

//   useEffect(() => {
//     const checkAgeVerification = async () => {
//       const ageVerified = localStorage.getItem('ageVerified');
//       if (!ageVerified) {
//         const { value: age } = await swal.fire({
//           title: 'Age Verification',
//           text: 'You must be 18 or older to access this site.',
//           input: 'number',
//           inputAttributes: {
//             placeholder: 'Enter your age',
//             min: 0,
//             max: 99,
//             step: 1
//           },
//           confirmButtonColor: '#e76a20',
//           showCancelButton: false,
//           confirmButtonText: 'Continue',
//           allowOutsideClick: false,
//           allowEscapeKey: false,
//           preConfirm: (age) => {
//             if (age < 18) {
//               swal.showValidationMessage('You must be 18 or older to access this site.');
//             }
//           }
//         });

//         if (age >= 18) {
//           localStorage.setItem('ageVerified', 'true');
//           setAge(age); // Store the age
//           setAgeVerified(true);
//         } else {
//           swal.fire('Access Denied', 'You must be 18 or older to access this site.', 'error');
//         }
//       } else {
//         setAgeVerified(true);
//         setAge(localStorage.getItem('age')); // Retrieve stored age
//       }
//     };

//     const checkPhoneVerification = async () => {
//       const phoneVerified = localStorage.getItem('phoneVerified');
//       if (!phoneVerified) {
//         const { value: phone } = await swal.fire({
//           title: 'Phone Verification',
//           text: 'Please enter your phone number to receive an OTP.',
//           input: 'tel',
//           inputAttributes: {
//             placeholder: 'Enter your phone number',
//             maxLength: 10
//           },
//           confirmButtonColor: '#e76a20',
//           showCancelButton: false,
//           confirmButtonText: 'Continue',
//           allowOutsideClick: false,
//           allowEscapeKey: false,
//           preConfirm: (phone) => {
//             if (!phone.match(/^\d{10}$/)) {
//               swal.showValidationMessage('Please enter a valid 10-digit phone number.');
//             }
//           }
//         });

//         if (phone) {
//           setPhoneNumber(phone);
//           // Simulate sending OTP (replace with actual API call)
//           const simulatedOtp = '123456'; // Replace with actual OTP from backend
//           setOtp(simulatedOtp);

//           const { value: enteredOtp } = await swal.fire({
//             title: 'Enter OTP',
//             text: `An OTP has been sent to ${phone}. Please enter it below.`,
//             input: 'text',
//             inputAttributes: {
//               placeholder: 'Enter OTP'
//             },
//             confirmButtonColor: '#e76a20',
//             showCancelButton: false,
//             confirmButtonText: 'Verify',
//             allowOutsideClick: false,
//             allowEscapeKey: false,
//             preConfirm: (enteredOtp) => {
//               if (enteredOtp !== simulatedOtp) {
//                 swal.showValidationMessage('Invalid OTP. Please try again.');
//               }
//             }
//           });

//           if (enteredOtp === simulatedOtp) {
//             localStorage.setItem('phoneVerified', 'true');
//             setPhoneVerified(true);
//           } else {
//             swal.fire('Verification Failed', 'The OTP entered was incorrect.', 'error');
//           }
//         }
//       } else {
//         setPhoneVerified(true);
//         setPhoneNumber(localStorage.getItem('phoneNumber')); // Retrieve stored phone number
//       }
//     };

//     if (!ageVerified) {
//       checkAgeVerification();
//     } else if (!phoneVerified) {
//       checkPhoneVerification();
//     }
//   }, [ageVerified, phoneVerified]);

//   if (!ageVerified || !phoneVerified) {
//     return (
//       <div style={{ textAlign: 'center' }}>
//         <h2>{!ageVerified ? 'Age Verification Pending...' : 'Phone Verification Pending...'}</h2>
//         <LoadingSpinner />
//       </div>
//     );
//   }

//   const HubtelPaymentFormProps = {
//     currency: "GHS",
//     currencyOperator: "GHS",
//     countrycode: 233,
//     countryName:"Ghana",
//     minAmount: 5,
//     maxAmount: 2000,
//     platform: "WEB",
//     serviceCode: "2000",
//     winuptoAmount: "500,000",
//     validPrefixes: ["02", "05"],
//     operators: [
//       { id: 'AirtelTigo', name: 'AirtelTigo', logo: airteltigoLogo },
//       { id: 'MTN', name: 'MTN', logo: mtnLogo },
//       { id: 'Telecel', name: 'Telecel', logo: vodafoneLogo }
//     ],
//     operatorValues: {
//       "AirtelTigo": "AT",
//       "MTN": "MTN",
//       "Telecel": "TELECEL"
//     },
//     age: age, // Pass the age
//     phoneNumber: phoneNumber // Pass the phone number
//   };

//   return (
//     <div className="App container-fluid d-flex flex-column min-vh-100">
//       <Header />
//       <main className="flex-grow-1" style={{ marginTop: '50px' }}>
//         <section className="hero-section">
//           <div className="container">
//             <div className="row">
//               <HubtelPaymentForm {...HubtelPaymentFormProps} />
//               <DisplayTable
//                 countryCode={HubtelPaymentFormProps.countrycode.toString()}
//                 currency={HubtelPaymentFormProps.currency}
//                 firstDigits={HubtelPaymentFormProps.validPrefixes.map(Number)}
//                 minBetAmount={HubtelPaymentFormProps.minAmount}
//                 maxBetAmount={HubtelPaymentFormProps.maxAmount}
//                 count={16}
//                 tableHeading="Top Recent Winners"
//               />
//             </div>
//           </div>
//         </section>
//         <PrizeTable />
//       </main>
//       <Footer />
//     </div>
//   );
// };

// export default GHPageHub;



// import React, { useEffect, useState } from "react";
// import swal from 'sweetalert2';
// import DisplayTable from "../components/PawapayDisplayTable";
// import Header from "../components/HeaderGhana";
// import PaymentForm from "../components/PawapayPaymentForm";

// import HubtelPaymentForm from "../components/HubtelPaymentForm";
// import Footer from "../components/FooterGhana";
// import airteltigoLogo from '../assets/airteltigo_gha.png';
// import mtnLogo from '../assets/mtn_momo_gha.png';
// import vodafoneLogo from '../assets/vodafone_gha.png';
// import PrizeTable from "../components/PrizeTable";
// import LoadingSpinner from '../components/LoadingSpinner';

// const GHPageHub = () => {
//   const [ageVerified, setAgeVerified] = useState(false);
//   const [phoneVerified, setPhoneVerified] = useState(false);
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [otp, setOtp] = useState('');

//   useEffect(() => {
//     const checkAgeVerification = async () => {
//       const ageVerified = localStorage.getItem('ageVerified');
//       if (!ageVerified) {
//         const { value: age } = await swal.fire({
//           title: 'Age Verification',
//           text: 'You must be 18 or older to access this site.',
//           input: 'number',
//           inputAttributes: {
//             placeholder: 'Enter your age',
//             min: 0,
//             max: 99,
//             step: 1
//           },
//           confirmButtonColor: '#e76a20',
//           showCancelButton: false,
//           confirmButtonText: 'Continue',
//           allowOutsideClick: false,
//           allowEscapeKey: false,
//           preConfirm: (age) => {
//             if (age < 18) {
//               swal.showValidationMessage('You must be 18 or older to access this site.');
//             }
//           }
//         });

//         if (age >= 18) {
//           localStorage.setItem('ageVerified', 'true');
//           setAgeVerified(true);
//         } else {
//           swal.fire('Access Denied', 'You must be 18 or older to access this site.', 'error');
//         }
//       } else {
//         setAgeVerified(true);
//       }
//     };

//     const checkPhoneVerification = async () => {
//       const phoneVerified = localStorage.getItem('phoneVerified');
//       if (!phoneVerified) {
//         const { value: phone } = await swal.fire({
//           title: 'Phone Verification',
//           text: 'Please enter your phone number to receive an OTP.',
//           input: 'tel',
//           inputAttributes: {
//             placeholder: 'Enter your phone number',
//             maxLength: 10
//           },
//           confirmButtonColor: '#e76a20',
//           showCancelButton: false,
//           confirmButtonText: 'Continue',
//           allowOutsideClick: false,
//           allowEscapeKey: false,
//           preConfirm: (phone) => {
//             if (!phone.match(/^\d{10}$/)) {
//               swal.showValidationMessage('Please enter a valid 10-digit phone number.');
//             }
//           }
//         });

//         if (phone) {
//           setPhoneNumber(phone);
//           // Simulate sending OTP (replace with actual API call)
//           const simulatedOtp = '123456'; // Replace with actual OTP from backend
//           setOtp(simulatedOtp);

//           const { value: enteredOtp } = await swal.fire({
//             title: 'Enter OTP',
//             text: `An OTP has been sent to ${phone}. Please enter it below.`,
//             input: 'text',
//             inputAttributes: {
//               placeholder: 'Enter OTP'
//             },
//             confirmButtonColor: '#e76a20',
//             showCancelButton: false,
//             confirmButtonText: 'Verify',
//             allowOutsideClick: false,
//             allowEscapeKey: false,
//             preConfirm: (enteredOtp) => {
//               if (enteredOtp !== simulatedOtp) {
//                 swal.showValidationMessage('Invalid OTP. Please try again.');
//               }
//             }
//           });

//           if (enteredOtp === simulatedOtp) {
//             localStorage.setItem('phoneVerified', 'true');
//             setPhoneVerified(true);
//           } else {
//             swal.fire('Verification Failed', 'The OTP entered was incorrect.', 'error');
//           }
//         }
//       } else {
//         setPhoneVerified(true);
//       }
//     };

//     if (!ageVerified) {
//       checkAgeVerification();
//     } else if (!phoneVerified) {
//       checkPhoneVerification();
//     }
//   }, [ageVerified, phoneVerified]);

//   if (!ageVerified || !phoneVerified) {
//     return (
//       <div style={{ textAlign: 'center' }}>
//         <h2>{!ageVerified ? 'Age Verification Pending...' : 'Phone Verification Pending...'}</h2>
//         <LoadingSpinner />
//       </div>
//     );
//   }

//   const HubtelPaymentFormProps = {
//     currency: "GHS",
//     currencyOperator: "GHS",
//     countrycode: 233,
//     countryName:"Ghana",
//     minAmount: 5,
//     maxAmount: 2000,
//     platform: "WEB",
//     serviceCode: "2000",
//     winuptoAmount: "500,000",
//     validPrefixes: ["02", "05"],
//     operators: [
//       { id: 'AirtelTigo', name: 'AirtelTigo', logo: airteltigoLogo },
//       { id: 'MTN', name: 'MTN', logo: mtnLogo },
//       { id: 'Telecel', name: 'Telecel', logo: vodafoneLogo }

//       //MTN, TELECEL, AT
//     ],
//     operatorValues: {
//       "AirtelTigo": "AT",
//       "MTN": "MTN",
//       "Telecel": "TELECEL"
//     },
//     age: age, // Pass the age
//     phoneNumber: phoneNumber // Pass the phone number
//   };

//   return (
//     <div className="App container-fluid d-flex flex-column min-vh-100">
//       <Header />
//       <main className="flex-grow-1" style={{ marginTop: '50px' }}>
//         <section className="hero-section">
//           <div className="container">
//             <div className="row">
//               <HubtelPaymentForm {...HubtelPaymentFormProps} />
//               <DisplayTable
//                 countryCode={HubtelPaymentFormProps.countrycode.toString()}
//                 currency={HubtelPaymentFormProps.currency}
//                 firstDigits={HubtelPaymentFormProps.validPrefixes.map(Number)}
//                 minBetAmount={HubtelPaymentFormProps.minAmount}
//                 maxBetAmount={HubtelPaymentFormProps.maxAmount}
//                 count={16}
//                 tableHeading="Top Recent Winners"
//               />
//             </div>
//           </div>
//         </section>

//         <PrizeTable />
//       </main>
//       <Footer />
//     </div>
//   );
// };

// export default GHPageHub;