
// Helper component for rendering operator logos and names
const Operator = ({ logo, name, onClick, selected }) => (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1,
      padding: '10px',
      cursor: 'pointer',
      border: selected ? '2px solid #ee6525' : 'none',  // Use the new highlight color
      borderRadius: '10px',  // Rounded corners for the highlight box
      transition: 'border 0.3s'  // Smooth transition for the border color change
    }} onClick={onClick}>
      <img src={logo} alt={name} style={{ width: '100px', height: '50px', objectFit: 'contain' }} />
      <span style={{ textAlign: 'center', marginTop: '10px' }}>{name}</span>
    </div>
  );




  export default Operator;