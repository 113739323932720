// import React, { useEffect, useState } from 'react';
// import './Header.css';
// import getCountry from '../services/countryService';

// function HeaderGhana() {
//   const [flagUrl, setFlagUrl] = useState('');

//   useEffect(() => {
//     const fetchCountryData = async () => {
//       try {
//         const data = await getCountry();
//         if (data && data.flagUrl) {
//           setFlagUrl(data.flagUrl);
//         }
//       } catch (error) {
//         console.error("Failed to fetch country data:", error);
//       }
//     };

//     fetchCountryData();
//   }, []);

//   return (
//     <div>
//       <header className="header">
//         <div className="branding">
//           <div className="container-fluid position-relative py-3">
//             <div className="logo-wrapper d-flex justify-content-center justify-content-md-start">
//               <div className="site-logo">
//                 <a className="navbar-brand" href="index.html">
//                   <img style={{ height: "50px", width: "auto", marginRight: "5px" }} src="assets/images/clogo.png" alt="logo" />
//                   {flagUrl && (
//                     <img
//                       style={{
//                         height: "24px",
//                         width: "auto",
//                         verticalAlign: "middle",
//                         marginLeft: "10px"
//                       }}
//                       src={flagUrl}
//                       alt="Country flag"
//                     />
//                   )}
//                   <span
//                     style={{
//                       display: "inline-block",
//                       fontSize: "24px",
//                       verticalAlign: "middle"
//                     }}
//                   >
//                     Mega Cash Box
//                   </span>
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </header>
//     </div>
//   );
// }



// export default HeaderGhana;

// import React, { useEffect, useState } from 'react';
// import getCountry from '../services/countryService';
// import './Header.css';

// function HeaderGhana() {
//   const [flagUrl, setFlagUrl] = useState('');

//   useEffect(() => {
//     const fetchCountryData = async () => {
//       try {
//         const data = await getCountry();
//         if (data && data.flagUrl) {
//           setFlagUrl(data.flagUrl);
//         }
//       } catch (error) {
//         console.error("Failed to fetch country data:", error);
//       }
//     };

//     fetchCountryData();
//   }, []);

//   return (
//     <header className="header">
//       <div className="header-container">
//         <div className="logo-wrapper">
//           <a className="site-logo" href="index.html">
//             <img src="assets/images/clogo.png" alt="logo" />
//             {flagUrl && (
//               <img className="flag" src={flagUrl} alt="Country flag" />
//             )}
//             <span>Mega Cash Box</span>
//           </a>
//         </div>
//         <nav>
//           <a href="index.html">Home</a>
//           <a href="results.html">Results</a>
//           <a href="winners.html">Winners</a>
//           <a href="howtoplay.html">How to Play</a>
//           <a href="terms.html">Terms & Conditions</a>
//           <a href="contacts.html">Contacts</a>
//         </nav>
//       </div>
//     </header>
//   );
// }

// export default HeaderGhana;



// import React, { useEffect, useState } from 'react';
// import getCountry from '../services/countryService';
// import './Header.css';

// function HeaderGhana() {
//   const [flagUrl, setFlagUrl] = useState('');
//   const [menuOpen, setMenuOpen] = useState(false);

//   useEffect(() => {
//     const fetchCountryData = async () => {
//       try {
//         const data = await getCountry();
//         if (data && data.flagUrl) {
//           setFlagUrl(data.flagUrl);
//         }
//       } catch (error) {
//         console.error("Failed to fetch country data:", error);
//       }
//     };

//     fetchCountryData();
//   }, []);

//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//   return (
//     <header className="header">
//       <div className="header-container">
//         <div className={`logo-wrapper ${menuOpen ? 'hide' : ''}`}>
//           <a className="site-logo" href="index.html">
//             <img src="assets/images/clogo.png" alt="logo" />
//             {flagUrl && (
//               <img className="flag" src={flagUrl} alt="Country flag" />
//             )}
//             <span>Mega Cash Box</span>
//           </a>
//         </div>
//         <button className="menu-toggle" onClick={toggleMenu}>
//           ☰
//         </button>
//         <button className="menu-close" onClick={closeMenu}>
//           ✕
//         </button>
//         <nav className={menuOpen ? "nav-open" : ""}>
//           <a href="index.html">Home</a>
//           <a href="results.html">Results</a>
//           <a href="winners.html">Winners</a>
//           <a href="howtoplay.html">How to Play</a>
//           <a href="terms.html">Terms & Conditions</a>
//           <a href="contacts.html">Contacts</a>
//         </nav>
//       </div>
//     </header>
//   );
// }

// export default HeaderGhana;

// import React, { useEffect, useState, useRef } from 'react';
// import getCountry from '../services/countryService';
// import './Header.css';

// function HeaderGhana() {
//   const [flagUrl, setFlagUrl] = useState('');
//   const [menuOpen, setMenuOpen] = useState(false);
//   const menuRef = useRef(null);

//   useEffect(() => {
//     const fetchCountryData = async () => {
//       try {
//         const data = await getCountry();
//         if (data && data.flagUrl) {
//           setFlagUrl(data.flagUrl);
//         }
//       } catch (error) {
//         console.error("Failed to fetch country data:", error);
//       }
//     };

//     fetchCountryData();
//   }, []);

//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (menuRef.current && !menuRef.current.contains(event.target)) {
//         closeMenu();
//       }
//     };

//     if (menuOpen) {
//       document.addEventListener('mousedown', handleClickOutside);
//     } else {
//       document.removeEventListener('mousedown', handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [menuOpen]);

//   return (
//     <header className="header">
//       <div className="header-container">
//         <div className={`logo-wrapper ${menuOpen ? 'hide' : ''}`}>
//           <a className="site-logo" href="index.html">
//             <img src="assets/images/clogo.png" alt="logo" />
//             {flagUrl && (
//               <img className="flag" src={flagUrl} alt="Country flag" />
//             )}
//             <span>Mega Cash Box</span>
//           </a>
//         </div>
//         <button className="menu-toggle" onClick={toggleMenu}>
//           ☰
//         </button>
//         <nav ref={menuRef} className={menuOpen ? "nav-open" : ""}>
//           <a href="index.html">Home</a>
//           {/* <a href="results.html">Results</a> */}
//           {/* <a href="winners.html">Winners</a> */}
//           <a href="prizetable">Prizes</a>
//           <a href="howtoplay.html">How to Play</a>
//           {/* <a href="contacts.html">Contacts</a> */}
//         </nav>
//       </div>
//     </header>
//   );
// }

// export default HeaderGhana;


import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import getCountry from '../services/countryService';
import './Header.css';

function HeaderGhana() {
  const [flagUrl, setFlagUrl] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const data = await getCountry();
        if (data && data.flagUrl) {
          setFlagUrl(data.flagUrl);
        }
      } catch (error) {
        console.error("Failed to fetch country data:", error);
      }
    };

    fetchCountryData();
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <header className="header">
      <div className="header-container">
        <div className={`logo-wrapper ${menuOpen ? 'hide' : ''}`}>
          <a className="site-logo" href="https://www.megacashluckybox.com/gh">
            <img src="assets/images/clogo.png" alt="logo" />
            {flagUrl && (
              <img className="flag" src={flagUrl} alt="Country flag" />
            )}
            <span>Mega Cash Box</span>
          </a>
        </div>
        <button className="menu-toggle" onClick={toggleMenu}>
          ☰
        </button>
        <nav ref={menuRef} className={menuOpen ? "nav-open" : ""}>
          <Link to="/gh">Home</Link>
          <Link to="/prizetable">Prizes</Link>
          <Link to="/winnerstable">Winners</Link>
          <Link to="/howtoplay">How to Play</Link>
          

        </nav>
      </div>
    </header>
  );
}

export default HeaderGhana;