import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner';
import Header from '../components/Header';

const HomePage = () => {
  const [userCountry, setUserCountry] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json');
        const { country_name } = response.data;
        if (country_name) {
          setUserCountry(country_name);
        } else {
          // If no country is found, set a default value
          setUserCountry('other');
        }
      } catch (error) {
        console.error('Error fetching user country:', error);
        setError(error.message || 'Failed to fetch user country.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserCountry();
  }, []);

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Header />
        <h2>Welcome to Chapabox</h2>
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  switch (userCountry) {
    case 'United States':
      return <Navigate to="/us" replace />;
    case 'United Kingdom':
      return <Navigate to="/uk" replace />;
    case 'Uganda':
        return <Navigate to="/ug" replace />;
    case 'Zambia':
          return <Navigate to="/zam" replace />;
    case 'Kenya':
      return <Navigate to="/ke" replace />;
    case 'Ghana':
      return <Navigate to="/gh" replace />;
    default:
      return userCountry ? <Navigate to="/other" replace /> : <div>No country detected or unsupported country.</div>;
  }
};

export default HomePage;


// import React, { useEffect, useState } from 'react';
// import { Navigate } from 'react-router-dom';
// import swal from 'sweetalert2';
// import LoadingSpinner from '../components/LoadingSpinner';
// import Header from '../components/Header';

// const HomePage = () => {
//   const [userCountry, setUserCountry] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [ageVerified, setAgeVerified] = useState(false);

//   useEffect(() => {
//     // Manually set the user country for testing purposes
//     const country = 'Ghana'; // Change this value to 'Ghana' to test for Ghana
//     setUserCountry(country);
//     setLoading(false);
//   }, []);

//   useEffect(() => {
//     const verifyAge = async () => {
//       const { value: age } = await swal.fire({
//         title: 'Age Verification',
//         text: 'You must be 18 or older to access this site.',
//         input: 'number',
//         inputAttributes: {
//           placeholder: 'Enter your age',
//           min: 0,
//           max: 99,
//           step: 1
//         },
//         confirmButtonColor: '#e76a20',
//         showCancelButton: false,
//         confirmButtonText: 'Continue',
//         allowOutsideClick: false,
//         allowEscapeKey: false,
//         preConfirm: (age) => {
//           if (age < 18) {
//             swal.showValidationMessage('You must be 18 or older to access this site.');
//           }
//         }
//       });

//       if (age >= 18) {
//         setAgeVerified(true);
//       } else {
//         swal.fire('Access Denied', 'You must be 18 or older to access this site.', 'error');
//       }
//     };

//     if (userCountry === 'Ghana' || userCountry === 'Kenya') {
//       verifyAge();
//     } else {
//       setAgeVerified(true);
//     }
//   }, [userCountry]);

//   if (loading) {
//     return (
//       <div style={{ textAlign: 'center' }}>
//         <Header />
//         <h2>Welcome to Chapabox</h2>
//         <LoadingSpinner />
//       </div>
//     );
//   }

//   if (!ageVerified) {
//     return (
//       <div style={{ textAlign: 'center' }}>
//         <Header />
//         <h2>Age Verification Pending...</h2>
//         <LoadingSpinner />
//       </div>
//     );
//   }

//   switch (userCountry) {
//     case 'United States':
//       return <Navigate to="/us" replace />;
//     case 'United Kingdom':
//       return <Navigate to="/uk" replace />;
//     case 'Uganda':
//       return <Navigate to="/ug" replace />;
//     case 'Zambia':
//       return <Navigate to="/zam" replace />;
//     case 'Kenya':
//       return <Navigate to="/ke" replace />;
//     case 'Ghana':
//       return <Navigate to="/gh" replace />;
//     default:
//       return userCountry ? <Navigate to="/other" replace /> : <div>No country detected or unsupported country.</div>;
//   }
// };

// export default HomePage;


// import React, { useEffect, useState } from 'react';
// import { Navigate } from 'react-router-dom';
// import axios from 'axios';
// import swal from 'sweetalert2';
// import LoadingSpinner from '../components/LoadingSpinner';
// import Header from '../components/Header';

// const HomePage = () => {
//   const [userCountry, setUserCountry] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [ageVerified, setAgeVerified] = useState(false);

//   useEffect(() => {
//     const fetchUserCountry = async () => {
//       try {
//         const response = await axios.get('https://ipapi.co/json');
//         const { country_name } = response.data;
//         setUserCountry(country_name || 'other');
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching user country:', error);
//         setError(error.message || 'Failed to fetch user country.');
//         setLoading(false);
//       }
//     };

//     fetchUserCountry();
//   }, []);

//   useEffect(() => {
//     const verifyAge = async () => {
//       const { value: age } = await swal.fire({
//         title: 'Age Verification',
//         text: 'You must be 18 or older to access this site.',
//         input: 'number',
//         inputAttributes: {
//           placeholder: 'Enter your age',
//           min: 0,
//           max: 99,
//           step: 1
//         },
//         confirmButtonColor: '#e76a20',
//         showCancelButton: false,
//         confirmButtonText: 'Continue',
//         allowOutsideClick: false,
//         allowEscapeKey: false,
//         preConfirm: (age) => {
//           if (age < 18) {
//             swal.showValidationMessage('You must be 18 or older to access this site.');
//           }
//         }
//       });

//       if (age >= 18) {
//         setAgeVerified(true);
//       } else {
//         swal.fire('Access Denied', 'You must be 18 or older to access this site.', 'error');
//       }
//     };

//     if (userCountry === 'Ghana' || userCountry === 'Kenya') {
//       verifyAge();
//     } else {
//       setAgeVerified(true);
//     }
//   }, [userCountry]);

//   if (loading) {
//     return (
//       <div style={{ textAlign: 'center' }}>
//         <Header />
//         <h2>Welcome to Chapabox</h2>
//         <LoadingSpinner />
//       </div>
//     );
//   }

//   if (!ageVerified) {
//     return (
//       <div style={{ textAlign: 'center' }}>
//         <Header />
//         <h2>Age Verification Pending...</h2>
//         <LoadingSpinner />
//       </div>
//     );
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   switch (userCountry) {
//     case 'United States':
//       return <Navigate to="/us" replace />;
//     case 'United Kingdom':
//       return <Navigate to="/uk" replace />;
//     case 'Uganda':
//       return <Navigate to="/ug" replace />;
//     case 'Zambia':
//       return <Navigate to="/zam" replace />;
//     case 'Kenya':
//       return <Navigate to="/ke" replace />;
//     case 'Ghana':
//       return <Navigate to="/gh" replace />;
//     default:
//       return userCountry ? <Navigate to="/other" replace /> : <div>No country detected or unsupported country.</div>;
//   }
// };

// export default HomePage;

// import React, { useEffect, useState } from 'react';
// import { Navigate } from 'react-router-dom';
// import axios from 'axios';
// import swal from 'sweetalert2';
// import LoadingSpinner from '../components/LoadingSpinner';
// import Header from '../components/Header';

// const HomePage = () => {
//   const [userCountry, setUserCountry] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [ageVerified, setAgeVerified] = useState(false);

//   useEffect(() => {
//     const verifyAge = async () => {
//       const { value: age } = await swal.fire({
//         title: 'Age Verification',
//         text: 'You must be 18 or older to access this site.',
//         input: 'number',
//         inputAttributes: {
//           placeholder: 'Enter your age',
//           min: 0,
//           max: 99,
//           step: 1
//         },
//         showCancelButton: false,
//         confirmButtonText: 'Continue',
//         allowOutsideClick: false,
//         allowEscapeKey: false,
//         preConfirm: (age) => {
//           if (age < 18) {
//             swal.showValidationMessage('You must be 18 or older to access this site.');
//           }
//         }
//       });

//       if (age >= 18) {
//         setAgeVerified(true);
//       } else {
//         swal.fire('Access Denied', 'You must be 18 or older to access this site.', 'error');
//       }
//     };

//     verifyAge();
//   }, []);

//   useEffect(() => {
//     if (ageVerified) {
//       const fetchUserCountry = async () => {
//         try {
//           const response = await axios.get('https://ipapi.co/json');
//           const { country_name } = response.data;
//           setUserCountry(country_name || 'other');
//         } catch (error) {
//           console.error('Error fetching user country:', error);
//           setError(error.message || 'Failed to fetch user country.');
//         } finally {
//           setLoading(false);
//         }
//       };

//       fetchUserCountry();
//     }
//   }, [ageVerified]);

//   if (!ageVerified) {
//     return (
//       <div style={{ textAlign: 'center' }}>
//         <Header />
//         <h2>Age Verification Pending...</h2>
//         <LoadingSpinner />
//       </div>
//     );
//   }

//   if (loading) {
//     return (
//       <div style={{ textAlign: 'center' }}>
//         <Header />
//         <h2>Welcome to Chapabox</h2>
//         <LoadingSpinner />
//       </div>
//     );
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   switch (userCountry) {
//     case 'United States':
//       return <Navigate to="/us" replace />;
//     case 'United Kingdom':
//       return <Navigate to="/uk" replace />;
//     case 'Uganda':
//       return <Navigate to="/ug" replace />;
//     case 'Zambia':
//       return <Navigate to="/zam" replace />;
//     case 'Kenya':
//       return <Navigate to="/ke" replace />;
//     case 'Ghana':
//       return <Navigate to="/gh" replace />;
//     default:
//       return userCountry ? <Navigate to="/other" replace /> : <div>No country detected or unsupported country.</div>;
//   }
// };

// export default HomePage;



// import React, { useEffect, useState } from 'react';
// import { Navigate } from 'react-router-dom';
// import axios from 'axios';
// import LoadingSpinner from '../components/LoadingSpinner';
// import Header from '../components/Header';

// const HomePage = () => {
//   const [userCountry, setUserCountry] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchUserCountry = async () => {
//       try {
//         const response = await axios.get('https://ipapi.co/json');
//         const { country_name } = response.data;
//         if (country_name) {
//           setUserCountry(country_name);
//         } else {
//           // If no country is found, set a default value
//           setUserCountry('other');
//         }
//       } catch (error) {
//         console.error('Error fetching user country:', error);
//         setError(error.message || 'Failed to fetch user country.');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUserCountry();
//   }, []);

//   if (loading) {
//     return (
//       <div style={{ textAlign: 'center' }}>
//         <Header />
//         <h2>Welcome to Chapabox</h2>
//         <LoadingSpinner />
//       </div>
//     );
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   switch (userCountry) {
//     case 'United States':
//       return <Navigate to="/us" replace />;
//     case 'United Kingdom':
//       return <Navigate to="/uk" replace />;
//     case 'Uganda':
//         return <Navigate to="/ug" replace />;
//     case 'Zambia':
//           return <Navigate to="/zam" replace />;
//     case 'Kenya':
//       return <Navigate to="/ke" replace />;
//     case 'Ghana':
//       return <Navigate to="/gh" replace />;
//     default:
//       return userCountry ? <Navigate to="/other" replace /> : <div>No country detected or unsupported country.</div>;
//   }
// };

// export default HomePage;




// import React, { useEffect, useState } from 'react';
// import { Navigate } from 'react-router-dom';
// import axios from 'axios';
// import LoadingSpinner from '../components/LoadingSpinner';
// import Header from '../components/Header';

// const HomePage = () => {
//   const [userCountry, setUserCountry] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchUserCountry = async () => {
//       const apiKey = process.env.REACT_APP_GEOLOCATION_API_KEY;
//       if (!apiKey) {
//         setError('API key is not defined in environment variables');
//         setLoading(false);
//         return;
//       }

//       try {
//         const response = await axios.get(`https://ipgeolocation.abstractapi.com/v1/?api_key=${apiKey}`);
//         const { country } = response.data;

//         if (country) {
//           setUserCountry(country);
//         } else {
//           throw new Error("No country data available");
//         }
//       } catch (error) {
//         console.error('Error fetching user country:', error);
//         setError(error.message || 'Failed to fetch user country.');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUserCountry();
//   }, []);

//   if (loading) {
//     return (
//       <div style={{ textAlign: 'center' }}>
//         <Header />
//         <h2>Welcome to Chapabox</h2>
//         <LoadingSpinner />
//       </div>
//     );
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   switch (userCountry) {
//     case 'United States':
//       return <Navigate to="/us" replace />;
//     case 'United Kingdom':
//       return <Navigate to="/uk" replace />;
//     case 'Kenya':
//       return <Navigate to="/ke" replace />;
//     case 'Ghana':
//       return <Navigate to="/gh" replace />;
//     default:
//       return userCountry ? (
//         <Navigate to="/other" replace />
//       ) : (
//         <div>No country detected or unsupported country.</div>
//       );
//   }
// };

// export default HomePage;
