import React from 'react'
import Header from "../components/HeaderGhana";
import Footer from "../components/FooterGhana";
import DisplayTable from '../components/PawapayDisplayTable';

function Winnerspage() {

    const PawapayPaymentformProps = {
        currency: "GHS",
        currencyOperator: "GHS",
        countrycode: 233,
        minAmount: 20,
        maxAmount: 100,
        platform: "web",
        serviceCode: "2000",
        winuptoAmount: "500,000",
        validPrefixes: ["02", "05"],
      };
    
    return (
        <div className="App container-fluid d-flex flex-column min-vh-100">
          <Header />
          <main className="flex-grow-1" style={{ marginTop: '50px' }}>
            <section className="hero-section">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-10 d-flex justify-content-center" style={{ marginBottom: '50px' }}>
                    <DisplayTable
                      countryCode={PawapayPaymentformProps.countrycode.toString()}
                      currency={PawapayPaymentformProps.currency}
                      firstDigits={PawapayPaymentformProps.validPrefixes.map(Number)}
                      minBetAmount={PawapayPaymentformProps.minAmount}
                      maxBetAmount={PawapayPaymentformProps.maxAmount}
                      count={11}
                      tableHeading="Top Recent Winners"
                    />   
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </div>
      );
}

export default Winnerspage;
