import React, { useState, useEffect } from "react";
import './Table.css'; // Import the CSS file

const generateRandomPhoneNumber = (firstDigits) => {
  const firstDigit = firstDigits[Math.floor(Math.random() * firstDigits.length)];
  const remainingDigits = Array.from({ length: 6 }, () => Math.floor(Math.random() * 10));
  return `${firstDigit}${remainingDigits.join('')}`;
};

const generateRandomBetAmount = (minBetAmount, maxBetAmount) => {
  const range = maxBetAmount - minBetAmount + 1;
  const betAmount = Math.floor(Math.random() * range) + minBetAmount;
  return Math.floor(betAmount / 5) * 5;
};

const generateFakeData = (count, countryCode, firstDigits, minBetAmount, maxBetAmount) => {
  return Array.from({ length: count }, (_, i) => {
    const betAmount = generateRandomBetAmount(minBetAmount, maxBetAmount);
    const randomFactor = Math.floor(Math.random() * 6) + 5;
    const winningAmount = betAmount * randomFactor;
    return {
      _id: `fake_id_${i}`,
      date: new Date().toISOString(),
      betAmount,
      winningAmount,
      phoneNumber: `${countryCode}${generateRandomPhoneNumber(firstDigits)}`,
      status: "Win",
    };
  }).sort((a, b) => b.winningAmount - a.winningAmount);
};

function DisplayTable({
  countryCode,
  currency,
  firstDigits,
  minBetAmount,
  maxBetAmount,
  count,
  tableHeading,
}) {
  const [games, setGames] = useState([]);

  useEffect(() => {
    const updateData = () => setGames(generateFakeData(count, countryCode, firstDigits, minBetAmount, maxBetAmount));
    updateData();
    const interval = setInterval(updateData, 5000);
    return () => clearInterval(interval);
  }, [countryCode, firstDigits, minBetAmount, maxBetAmount, count]);

  return (
    <div className="col-12 col-md-7 pt-5 mb-5">
      <div className="promo pe-md-3 pe-lg-5">
        <h1 className="headline mb-3" style={{ textAlign: "center" }}>{tableHeading}</h1>
        <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Win</th>
                <th>Phone</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {games.length === 0 ? (
                <tr><td className="text-center" colSpan={4}>No Record Found</td></tr>
              ) : (
                games.map(game => (
                  <tr key={game._id}>
                    <td>{new Date(game.date).toDateString()}</td>
                    <td>{`${currency} ${game.winningAmount}`}</td>
                    <td>{`${game.phoneNumber.slice(0, 6)}xxxx${game.phoneNumber.slice(-1)}`}</td>
                    <td className={game.status.toLowerCase()}>{game.status}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DisplayTable;


///////////////////////////////////////////////////////////     fake table winner no colors

// import React, { useState, useEffect } from "react";
// import '../Table.css';

// const generateRandomPhoneNumber = () => {
//   const firstDigits = [2, 5];
//   const firstDigit = firstDigits[Math.floor(Math.random() * firstDigits.length)];
//   const remainingDigits = Array.from({ length: 6 }, () => Math.floor(Math.random() * 10));
//   return `${firstDigit}${remainingDigits.join('')}`;
// };

// const generateRandomBetAmount = () => {
//   const minBetAmount = 5;
//   const maxBetAmount = 200;
//   const range = maxBetAmount - minBetAmount + 1;
//   const betAmount = Math.floor(Math.random() * range) + minBetAmount;
//   return Math.floor(betAmount / 5) * 5;
// };

// const generateFakeData = (count) => {
//   return Array.from({ length: count }, (_, i) => {
//     const betAmount = generateRandomBetAmount();
//     const randomFactor = Math.floor(Math.random() * 6) + 5;
//     const winningAmount = betAmount * randomFactor;
//     return {
//       _id: `fake_id_${i}`,
//       date: new Date().toISOString(),
//       betAmount,
//       winningAmount,
//       phoneNumber: `233${generateRandomPhoneNumber()}`,
//       status: "Win",
//     };
//   }).sort((a, b) => b.winningAmount - a.winningAmount);
// };

// function DisplayTable() {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     const updateData = () => setGames(generateFakeData(11));
//     updateData();
//     const interval = setInterval(updateData, 5000);
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1 className="headline mb-3" style={{ textAlign: "center" }}>Top Recent Winners</h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table">
//             <thead>
//               <tr>
//                 <th>Date</th>
//                 <th>Win</th>
//                 <th>Phone</th>
//                 <th>Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {games.length === 0 ? (
//                 <tr><td className="text-center" colSpan={4}>No Record Found</td></tr>
//               ) : (
//                 games.map(game => (
//                   <tr key={game._id}>
//                     <td>{new Date(game.date).toDateString()}</td>
//                     <td>GH₵ {game.winningAmount}</td>
//                     <td>{`${game.phoneNumber.slice(0, 6)}xxxx${game.phoneNumber.slice(-1)}`}</td>
//                     <td className={game.status.toLowerCase()}>{game.status}</td>
//                   </tr>
//                 ))
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;





///////////////////////////////////////////////     dispaly high stakes wbetter


// import React, { useState, useEffect } from "react";

// function DisplayTable() {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     // Fetch fake data initially
//     const fakeData = generateFakeData(8);
//     setGames(fakeData);

//     // Set interval to update fake data every 5 seconds
//     const interval = setInterval(() => {
//       const newFakeData = generateFakeData(8);
//       setGames(newFakeData);
//     }, 5000);

//     // Clean up interval on component unmount
//     return () => clearInterval(interval);
//   }, []);

//   // Function to generate fake data
//   const generateFakeData = (count) => {
//     const fakeGames = [];
//     for (let i = 0; i < count; i++) {
//       const betAmount = generateRandomBetAmount();
//       const fakeGame = {
//         _id: `fake_id_${i}`,
//         date: new Date().toISOString(),
//         betAmount: betAmount,
//         phoneNumber: `254${generateRandomPhoneNumber()}`,
//         status: Math.random() < 0.5 ? "Win" : "Lost",
//       };
//       fakeGames.push(fakeGame);
//     }
//     // Sort fakeGames array in descending order based on betAmount
//     fakeGames.sort((a, b) => b.betAmount - a.betAmount);
//     return fakeGames;
//   };

//   // Function to generate random phone number
//   const generateRandomPhoneNumber = () => {
//     const digits = [];
//     for (let i = 0; i < 7; i++) {
//       digits.push(Math.floor(Math.random() * 10));
//     }
//     return digits.join('');
//   };

//   // Function to generate random bet amount between 50 and 100, ending with 5 or 0
//   const generateRandomBetAmount = () => {
//     const minBetAmount = 20;
//     const maxBetAmount = 200;
//     const range = maxBetAmount - minBetAmount + 1;
//     const betAmount = Math.floor(Math.random() * range) + minBetAmount;
//     return Math.floor(betAmount / 5) * 5; // Ensure bet amount ends with 5 or 0
//   };

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5 ">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1 className="headline mb-3" style={{ textAlign: "center" }}>
//           Recent Top 10 Stakes
//         </h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table">
//             <thead>
//               <tr>
//                 <th scope="col">Date</th>
//                 <th scope="col">Wager</th>
//                 <th scope="col">Phone</th>
//                 <th scope="col">Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {games.length < 1 ? (
//                 <tr>
//                   <td className="text-center" colSpan={4}>
//                     No Record Found
//                   </td>
//                 </tr>
//               ) : (
//                 games.map((game) => (
//                   <tr key={game._id}>
//                     <td>{new Date(game.date).toDateString()}</td>
//                     <td>Ksh {game.betAmount}</td>
//                     <td>
//                       {game.phoneNumber.substring(0, 6)}xxxx
//                       {game.phoneNumber.slice(-1)}
//                     </td>
//                     <td>{game.status}</td>
//                   </tr>
//                 ))
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;





//////////////////////////// fake and oringal
// import React, { useState, useEffect } from "react";
// import axios from "axios";

// function DisplayTable() {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     // Generate fake data
//     const fakeData = generateFakeData(8);
//     setGames(fakeData);

//     // Fetch original data after a delay
//     setTimeout(() => {
//       axios
//         .get("/webgame/last10games1")
//         .then((response) => {
//           // Set the state with the data fetched from the Axios request
//           setGames(response.data);
//           console.log(response.data);
//         })
//         .catch((err) => console.log(err.message));
//     }, 2000); // Adjust the delay as needed
//   }, []);

//   // Function to generate fake data
//   const generateFakeData = (count) => {
//     const fakeGames = [];
//     for (let i = 0; i < count; i++) {
//       const betAmount = generateRandomBetAmount();
//       const fakeGame = {
//         _id: `fake_id_${i}`,
//         date: new Date().toISOString(),
//         betAmount: betAmount,
//         phoneNumber: `254${generateRandomPhoneNumber()}`,
//         status: Math.random() < 0.5 ? "Win" : "Lost",
//       };
//       fakeGames.push(fakeGame);
//     }
//     return fakeGames;
//   };

//   // Function to generate random phone number
//   const generateRandomPhoneNumber = () => {
//     const digits = [];
//     for (let i = 0; i < 7; i++) {
//       digits.push(Math.floor(Math.random() * 10));
//     }
//     return digits.join('');
//   };

//   // Function to generate random bet amount ending with 0 or 5
//   const generateRandomBetAmount = () => {
//     const lastDigit = Math.random() < 0.5 ? 0 : 5;
//     const otherDigits = Math.floor(Math.random() * 100); // Generate random 2-digit number
//     return otherDigits * 10 + lastDigit;
//   };

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5 ">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1 className="headline mb-3" style={{ textAlign: "center" }}>
//           Recent Top Bets
//         </h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table">
//             <thead>
//               <tr>
//                 <th scope="col">Date</th>
//                 <th scope="col">Wager</th>
//                 <th scope="col">Phone</th>
//                 <th scope="col">Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {games.length < 1 ? ( // Check games.length instead of transactions.length
//                 <tr>
//                   <td className="text-center" colSpan={4}>
//                     No Record Found
//                   </td>
//                 </tr>
//               ) : (
//                 games.map((game) => (
//                   <tr key={game._id}>
//                     <td>{new Date(game.date).toDateString()}</td>
//                     <td>Ksh {game.betAmount}</td>
//                     <td>
//                       {game.phoneNumber.substring(0, 6)}xxxx
//                       {game.phoneNumber.slice(-1)}
//                     </td>
//                     <td>{game.status}</td>
//                   </tr>
//                 ))
//               )}
//             </tbody>
//           </table>
//         </div>
//         {/*//cta-holder*/}
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;















// import React, { useState, useEffect } from "react";
// import axios from "axios";

// function DisplayTable() {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     // Generate fake data
//     const fakeData = generateFakeData(8);
//     setGames(fakeData);

//     // Fetch original data after a delay
//     setTimeout(() => {
//       axios
//         .get("/webgame/last10games1")
//         .then((response) => {
//           // Set the state with the data fetched from the Axios request
//           setGames(response.data);
//           console.log(response.data);
//         })
//         .catch((err) => console.log(err.message));
//     }, 2000); // Adjust the delay as needed
//   }, []);

//   // Function to generate fake data
//   const generateFakeData = (count) => {
//     const fakeGames = [];
//     for (let i = 0; i < count; i++) {
//       const fakeGame = {
//         _id: `fake_id_${i}`,
//         date: new Date().toISOString(),
//         betAmount: Math.floor(Math.random() * 100) + 1,
//         phoneNumber: `254${generateRandomPhoneNumber()}`,
//         status: Math.random() < 0.5 ? "Win" : "Lost",
//       };
//       fakeGames.push(fakeGame);
//     }
//     return fakeGames;
//   };

//   // Function to generate random phone number
//   const generateRandomPhoneNumber = () => {
//     const digits = [];
//     for (let i = 0; i < 7; i++) {
//       digits.push(Math.floor(Math.random() * 10));
//     }
//     return digits.join('');
//   };

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5 ">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1 className="headline mb-3" style={{ textAlign: "center" }}>
//           Recent Bets
//         </h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table">
//             <thead>
//               <tr>
//                 <th scope="col">Date</th>
//                 <th scope="col">Wager</th>
//                 <th scope="col">Phone</th>
//                 <th scope="col">Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {games.length < 1 ? ( // Check games.length instead of transactions.length
//                 <tr>
//                   <td className="text-center" colSpan={4}>
//                     No Record Found
//                   </td>
//                 </tr>
//               ) : (
//                 games.map((game) => (
//                   <tr key={game._id}>
//                     <td>{new Date(game.date).toDateString()}</td>
//                     <td>Ksh {game.betAmount}</td> {/* Added "Ksh" before the wager amount */}
//                     <td>
//                       {game.phoneNumber.substring(0, 6)}xxxx
//                       {game.phoneNumber.slice(-1)}
//                     </td>
//                     <td>{game.status}</td>
//                   </tr>
//                 ))
//               )}
//             </tbody>
//           </table>
//         </div>
//         {/*//cta-holder*/}
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;







// import React, { useState, useEffect } from "react";
// import axios from "axios";

// function DisplayTable() {
//   const [games, setGames] = useState([]);

//   useEffect(() => {
//     axios
//       .get("/webgame/last10games")
//       .then((response) => {
//         // Set the state with the data fetched from the Axios request
//         setGames(response.data);
//         console.log(response.data);
//       })
//       .catch((err) => console.log(err.message));
//   }, []);

//   return (
//     <div className="col-12 col-md-7 pt-5 mb-5 ">
//       <div className="promo pe-md-3 pe-lg-5">
//         <h1 className="headline mb-3" style={{ textAlign: "center" }}>
//           Recent Bets
//         </h1>
//         <div className="cta-holder row gx-md-3 gy-3 gy-md-0">
//           <table className="table">
//             <thead>
//               <tr>
//                 <th scope="col">Date</th>
//                 <th scope="col">Wager</th>
//                 <th scope="col">Phone</th>
//                 <th scope="col">Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {games.length < 1 ? ( // Check games.length instead of transactions.length
//                 <tr>
//                   <td className="text-center" colSpan={4}>
//                     No Record Found
//                   </td>
//                 </tr>
//               ) : (
//                 games.map((game) => (
//                   <tr key={game._id}>
//                     <td>{new Date(game.date).toDateString()}</td>
//                     <td>{game.betAmount}</td>
//                     <td>
//                       {game.phoneNumber.substring(0, 4)}xxxx
//                       {game.phoneNumber.slice(-2)}
//                     </td>
//                     <td>{game.status}</td>
//                   </tr>
//                 ))
//               )}
//             </tbody>
//           </table>
//         </div>
//         {/*//cta-holder*/}
//       </div>
//     </div>
//   );
// }

// export default DisplayTable;