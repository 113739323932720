import React from 'react'
import HeaderGhanaApp from '../components/Ghana/GHV2Header';
import './GhpageV2.css'; // Import the CSS file

function GHpageV2() {
    return (
        <div className='background-color: #00091e;' >
         <HeaderGhanaApp />
         </div>
        );
      
       
}

export default GHpageV2