// Add this component inside your PaymentForm component
const RewardsGrid = ({ rewards, userBox }) => {
    const boxColors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700", "#9370DB"];
    const gridStyles = {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
      gap: "10px",
      marginTop: "20px",
      marginBottom: "20px",
    };
    const boxStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      cursor: "pointer",
    };
    const boxNumberStyle = {
      fontSize: "1.5em",
      color: "white",
      fontWeight: "bold",
    };
  
    const parsedRewards = rewards.map((reward) => {
      const [boxString, amount] = reward.split(" - ");
      const boxNumber = parseInt(boxString.replace("[BOX", "").replace("]", ""));
      return { boxNumber, amount };
    });
  
    return (
      <div style={gridStyles}>
        {parsedRewards.map(({ boxNumber, amount }, index) => (
          <div
            key={index}
            className="box"
            style={{
              ...boxStyle,
              backgroundColor: boxColors[index % boxColors.length],
              boxShadow: boxNumber === userBox ? "0px 0px 5px 3px #000" : "none",
            }}
          >
            <span style={boxNumberStyle}>
              Box {boxNumber}: {amount}
            </span>
          </div>
        ))}
      </div>
    );
  };
  

  export default RewardsGrid