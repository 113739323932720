import React, { useEffect, useState } from 'react';
// import './Header.css';
import getCountry from '../services/countryService';

function Header() {
  const [flagUrl, setFlagUrl] = useState('');

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const data = await getCountry();
        if (data && data.flagUrl) {
          setFlagUrl(data.flagUrl);
        }
      } catch (error) {
        console.error("Failed to fetch country data:", error);
      }
    };

    fetchCountryData();
  }, []);

  return (
    <div>
      <header className="header">
        <div className="branding">
          <div className="container-fluid position-relative py-3">
            <div className="logo-wrapper d-flex justify-content-center justify-content-md-start">
              <div className="site-logo">
                <a className="navbar-brand" href="index.html">
                  <img style={{ height: "50px", width: "auto", marginRight: "5px" }} src="assets/images/clogo.png" alt="logo" />
                  {flagUrl && (
                    <img
                      style={{
                        height: "24px",
                        width: "auto",
                        verticalAlign: "middle",
                        marginLeft: "10px"
                      }}
                      src={flagUrl}
                      alt="Country flag"
                    />
                  )}
                  <span
                    style={{
                      display: "inline-block",
                      fontSize: "24px",
                      verticalAlign: "middle"
                    }}
                  >
                    Chapabox
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;


