// import React from 'react';
// import spark from '../assets/spark.png';

// const Footer = () => {
//   return (
//     <footer className="text-white text-center text-lg-start" style={{ backgroundColor: '#e76a20' }}>
//       {/* Grid container */}
//       <div className="container p-4">
//         {/* Logo */}
//         <div className="text-center mb-4">
//           <img src={spark} alt="Spark Logo" style={{ width: '150px' }} />
//         </div>
//         {/* Grid row */}
//         <div className="row justify-content-center">
//           {/* Grid column */}
//           <div className="col-lg-6 col-md-12 mb-4 mb-md-0 text-center">
//             <h5 className="text-uppercase"></h5>
//             <p>
//               Mega Cash Box is an exciting, interactive game designed to bring a thrilling experience of chance and reward to its players. The game revolves around a simple yet captivating concept where users are presented with an array of six boxes, each containing hidden treasures or surprises.
//             </p>
//           </div>
//           {/* Grid column */}

//           {/* Grid column */}
//           <div className="col-lg-3 col-md-6 mb-4 mb-md-0 text-center">
//             <h5 className="text-uppercase"></h5>
//             <ul className="list-unstyled mb-0">
//               <li>
//                 <a href="#!" className="text-white">Terms and Conditions</a>
//               </li>
//               <li>
//                 <a href="#!" className="text-white">Privacy Policy</a>
//               </li>
//             </ul>
//           </div>
//           {/* Grid column */}
//         </div>
//         {/* Grid row */}
//       </div>
//       {/* Grid container */}

//       {/* Copyright */}
//       <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
//         © 2024 Copyright:
//         <a className="text-white" href="https://www.megacashluckybox.com/gh">Mega Cash Box</a>
//       </div>
//       {/* Copyright */}
//     </footer>
//   );
// };

// export default Footer;



// import React from 'react';
// import spark from '../assets/spark.png';

// const Footer = () => {
//   return (
//     <footer className="text-white text-center text-lg-start" style={{ backgroundColor: '#e76a20' }}>
//       {/* Grid container */}
//       <div className="container p-4">
//         {/* Logo */}
//         <div className="text-center mb-4">
//           <img src={spark} alt="Spark Logo" style={{ width: '150px' }} />
//         </div>
//         {/* Grid row */}
//         <div className="row">
//           {/* Grid column */}
//           <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
//             <h5 className="text-uppercase"></h5>
//             <p>
//             Mega Cash Box is an exciting, interactive game designed to bring a thrilling experience of chance and reward to its players. The game revolves around a simple yet captivating concept where users are presented with an array of six boxes, each containing hidden treasures or surprises
//             </p>
//           </div>
//           {/* Grid column */}

//           {/* Grid column */}
//           <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
//             <h5 className="text-uppercase"></h5>
//             <ul className="list-unstyled mb-0">
//               <li>
//                 <a href="#!" className="text-white">Terms and Conditions</a>
//               </li>
//               <li>
//                 <a href="#!" className="text-white">Privacy Policy</a>
//               </li>
//             </ul>
//           </div>
//           {/* Grid column */}
//         </div>
//         {/* Grid row */}
//       </div>
//       {/* Grid container */}

//       {/* Copyright */}
//       <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
//         © 2024 Copyright:
//         <a className="text-white" href="https://mdbootstrap.com/">Mega Cash</a>
//       </div>
//       {/* Copyright */}
//     </footer>
//   );
// };

// export default Footer;






import React from 'react';
import './Header.css';

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: '#ed6525', // Branding color
        color: 'white',
        width: '100%',
        padding: '1rem 0', // Add some padding for better spacing
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <p style={{ margin: 0 }}>&copy; 2024 Chapabox. All rights reserved.</p>
          </div>
          <div className="col-12 col-md-6 text-md-end">
            <ul className="list-inline" style={{ margin: 0, padding: 0 }}>
              <li className="list-inline-item" style={{ display: 'inline-block', marginRight: '1rem' }}>
                <a href="#" style={{ color: 'white', textDecoration: 'none' }}>
                  Terms and Conditions
                </a>
              </li>
              <li className="list-inline-item" style={{ display: 'inline-block', marginRight: '1rem' }}>
                <a href="#" style={{ color: 'white', textDecoration: 'none' }}>
                  About
                </a>
              </li>
              <li className="list-inline-item" style={{ display: 'inline-block', marginRight: '1rem' }}>
                <a href="#" style={{ color: 'white', textDecoration: 'none' }}>
                  Privacy Policy
                </a>
              </li>
              <li className="list-inline-item" style={{ display: 'inline-block' }}>
                <a href="#" style={{ color: 'white', textDecoration: 'none' }}>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;