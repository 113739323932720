import React from "react";
import DisplayTable from "../components/PawapayDisplayTable";
import Header from "../components/Header";
import PaymentForm from "../components/PawapayPaymentForm";
import Footer from "../components/Footer";
import airteltigoLogo from '../assets/airtel_zmb.png';
import mtnLogo from '../assets/mtn_momo_zmb.png';
import zamtelLogo from '../assets/zamtel_zmb.png';

const ZAMPage = () => {
  const PawapayPaymentformProps = {
    currency: "ZMW",
    currencyOperator: "ZMW",
    countrycode: 260,
    minAmount: 20,
    maxAmount: 5000,
    platform:"web",
    serviceCode:"4000",
    winuptoAmount:"500,000",
    validPrefixes: ["07","09"],
    operators: [  
      { id: 'MTN', name: 'MTN', logo: mtnLogo },
      { id: 'Airtel', name: 'Airtel', logo: airteltigoLogo },
      { id: 'Zamtel', name: 'Zamtel', logo: zamtelLogo }
    ],
    operatorValues: {
      "MTN": "MTN_MOMO_ZMB",
      "Airtel": "AIRTEL_OAPI_ZMB",
      "Zamtel":"ZAMTEL_ZMB"
    }
  };

  return (
    <div className="App container-fluid d-flex flex-column min-vh-100">
      <Header />
      <main className="flex-grow-1">
        <section className="hero-section">
          <div className="container">
            <div className="row">
              <PaymentForm {...PawapayPaymentformProps} />
              <DisplayTable
                countryCode={PawapayPaymentformProps.countrycode.toString()}
                currency={PawapayPaymentformProps.currency}
                firstDigits={PawapayPaymentformProps.validPrefixes.map(Number)}
                minBetAmount={PawapayPaymentformProps.minAmount}
                maxBetAmount={PawapayPaymentformProps.maxAmount}
                count={11}
                tableHeading="Top Recent Winners"
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default ZAMPage;