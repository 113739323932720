
import DisplayTable from "../components/DisplayTable";
import Header from "../components/Header";
import PaymentForm from "../components/PaymentForm";
import Footer from "../components/Footer";

const KEPage = () => {
  return (
    <div className="App container-fluid d-flex flex-column min-vh-100">
      <Header />
      <main className="flex-grow-1 main-content" style={{ marginTop: '50px' }}>
        <section className="hero-section">
          <div className="container">
            <div className="row">
              <PaymentForm />
              <DisplayTable />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default KEPage;
