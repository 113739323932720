import React from 'react';
import Header from "../components/HeaderGhana";
import Footer from "../components/FooterGhana";

function HowToPlay() {
    return (
        <div className="App container-fluid d-flex flex-column min-vh-100">
            <Header />
            <main className="flex-grow-1" style={{ marginTop: '120px' }}>
                <section className="hero-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10">
                                <h2 className="text-center mb-4">Step-by-Step Instructions for Playing the Game</h2>
                                <ol className="list-group list-group-numbered">
                                    <li className="list-group-item">
                                        <h4>Enter Your Mobile Number</h4>
                                        <p>In the designated field, enter your mobile number. Ensure it is correctly formatted to avoid any issues.</p>
                                    </li>
                                    <li className="list-group-item">
                                        <h4>Enter the Amount</h4>
                                        <p>Input the amount you wish to play with in the "Amount" field. For example, you can enter 25 GHS.</p>
                                    </li>
                                    <li className="list-group-item">
                                        <h4>Choose Your Mobile Money Operator</h4>
                                        <p>Select your mobile money operator from the available options: AirtelTigo, MTN, or Vodafone.</p>
                                    </li>
                                    <li className="list-group-item">
                                        <h4>Choose Your Lucky Box</h4>
                                        <p>Pick one of the six available boxes. Each box contains a different prize. Click on the box you wish to select. In this example, Box 2 is selected.</p>
                                    </li>
                                    <li className="list-group-item">
                                        <h4>Initiate Payment</h4>
                                        <p>Click the "PLAY" button to start the payment process. An STK push notification will be sent to your phone.</p>
                                    </li>
                                    <li className="list-group-item">
                                        <h4>Confirm Payment</h4>
                                        <p>On your mobile device, enter your mobile money PIN to confirm the payment. Ensure you complete this step promptly to avoid any timeout issues.</p>
                                    </li>
                                    <li className="list-group-item">
                                        <h4>View Your Prize</h4>
                                        <p>After the payment is processed, you will see a confirmation screen showing the prize you have won. In this example, selecting Box 2 wins a prize of GHS 1300.</p>
                                    </li>
                                    <li className="list-group-item">
                                        <h4>Play Again (Optional)</h4>
                                        <p>If you wish to play again, click the "Play Again" button and repeat the steps above.</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default HowToPlay;
