import React, { useState } from "react";
import ReactDOM from 'react-dom/server';
import LoadingSpinner from "./LoadingSpinner";
import Swal from "sweetalert2";
import RewardsGrid from "./RewardsGrid";

import axios from "axios";

function PaymentForm() {
  const currency = "KSh"; // This can be set dynamically based on user location or other logic
  const currencyOperator="KES"
  const platform="WEB"
  const serviceCode="1000"
  const countryName="Kenya"
  const countrycode=254
  const [details, setDetails] = useState({
    phone: "",
    amount: "",
  });
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedBox, setSelectedBox] = useState(null);

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted with details:', details);

    const betAmount = parseFloat(details.amount);

    if (!details.phone || isNaN(betAmount) || selectedBox === null) {
      setError(true);
      setErrorMsg("Please fill all fields and select a box.");
      return;
    }
    if (details.phone.length !== 10) {
      setError(true);
      setErrorMsg("Please enter a valid 10-digit phone number.");
      return;
    }
    if (
      details.phone.substring(0, 2) !== "07" &&
      details.phone.substring(0, 2) !== "01"
    ) {
      setError(true);
      setErrorMsg("Phone number should start with '07' or '01'.");
      return;
    }
    if (betAmount < 20) { // Check if bet amount is less than 20
      setError(true);
      setErrorMsg("Bet amount should be at least 20 Ksh.");
      return;
    }

    setError(false);
    setErrorMsg("");

    setLoading(true);

    try {
      const paymentResponse = await axios.post("/web/mpesakenya", {
        betAmount: betAmount,
        phone: details.phone,
        boxNumber: selectedBox,
        countrycode:countrycode,
        platform:platform,
        serviceCode:serviceCode,
        countryName:countryName
        
      });

      if (paymentResponse.status === 200) {
        const sessionId = paymentResponse.data.sessionId;
        console.log('checkout id :', paymentResponse.data.paymentData.data.CheckoutRequestID);

        const stkQueryExecuted = sessionStorage.getItem(`stkQuery_${sessionId}`);
        if (!stkQueryExecuted) {
          stkPushQuery(paymentResponse.data.paymentData.data.CheckoutRequestID, sessionId);
          sessionStorage.setItem(`stkQuery_${sessionId}`, 'true');
        }

        setLoading(false);
        Swal.fire(
          "SUCCESS!",
          "Your bet request has been received. You will receive an M-Pesa prompt on your phone.",
          "success"
        );
      } else {
        setLoading(false);
        setError(true);
        setErrorMsg("Payment failed. Please try again later.");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      setLoading(false);
      setError(true);
      setErrorMsg(
        "An error occurred while processing the payment. Please try again later."
      );
    }
  };

  
  const stkPushQuery = (checkOutRequestID, sessionId) => {
    let retries = 0;
    const maxRetries = 20; // Maximum number of retries
    const retryDelay = 5000; // Delay in milliseconds between retries
  
    const retryRequest = () => {
      axios
        .post("/webmpesa/stkpushquery", {
          CheckoutRequestID: checkOutRequestID,
        })
        .then((response) => {
          if (response.data.ResultCode === "0") {
            // Check if the game has already been played in this session
            const playedInSession = sessionStorage.getItem(sessionId);
            if (!playedInSession) {
              clearInterval(timer);
              setLoading(false);
              Swal.fire(
                "SUCCESS!",
                "We've received your payment. The game is currently in progress",
                "success"
              );
              setDetails({
                phone: details.phone,
                amount: details.amount,
              });
              setSelectedBox(null);
              // If the game has not been played, call playGame function
              playGame(details.amount, selectedBox, details.phone, sessionId);
              // Set the flag to true in the session storage
              sessionStorage.setItem(sessionId, 'true');
            }
          }else if (response.data.ResultCode === "17") {

            Swal.fire({
              icon: "warning",
              title: "Mpesa high traffic",
              text: "Unable to process payment. Please try again in 1 minutes.",
            });
            
              clearInterval(timer);
              setLoading(false);
              setError(true);
              setErrorMsg("The M-Pesa servers are currently experiencing high traffic.Please try again in 1 minutes.");
            
          } else if (response.data.ResultCode === "500.001.1001") {
            retries++;
            if (retries < maxRetries) {
              setTimeout(retryRequest, retryDelay);
            } else {
              clearInterval(timer);
              setLoading(false);
              setError(true);
              setErrorMsg("The M-Pesa servers are currently experiencing high traffic. Please try again later.");
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Mpesa",
              text: response.data.ResultDesc,
              ///footer: '<a href="#">Please try again</a>'
            });
            clearInterval(timer);
            setLoading(false);
            setError(true);
            setErrorMsg(response.data.ResultDesc);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
  
    const timer = setInterval(() => {
      console.log('Checking payment status...');
      if (retries === 20) {
        clearInterval(timer);
        setLoading(false);
        setErrorMsg("You took too long to pay");
        setError(true);
        return;
      }
      retryRequest();
    }, 2000);
  };

 

  const playGame = (amount, boxNumber, phone, sessionId) => {
    // Check if the game has already been played in this session
    const playedInSession = sessionStorage.getItem(`game_${sessionId}`);
    if (playedInSession) {
      // If the game has been played, return early
      return;
    }

    console.log('Playing game with details:', { amount, boxNumber, phone, sessionId });
    const formattedPhone = `254${phone.substring(phone.length - 9)}`;
    console.log("symbol of  the currency ",currency)
    axios
      .post("/webgame/play-lucky-box", {
        betAmount: amount,
        userBox: boxNumber,
        phoneNumber: formattedPhone,
        sessionId: sessionId,
        currency:currency
      })
      .then((gameResult) => {
        console.log("Game response:", gameResult.data);

        //const rewards = gameResult.data.details.Rewards;
        const rewards = gameResult.data.details.LostfakeRewards;
        const userBox = gameResult.data.userBox;
        const selectedBoxNumber = gameResult.data.userBox;

        const rewardsGridHTML = ReactDOM.renderToString(
          <RewardsGrid rewards={rewards} userBox={userBox} />
        );

        Swal.fire({
          title: gameResult.data.result,
          html: `
            <div>You selected Box ${selectedBoxNumber}</div>
            <div>Your prize: ${gameResult.data.prize}</div>
            ${rewardsGridHTML}
          `,
          icon: "success",
          backdrop: `
            rgba(0,0,123,0.4)
            left top
            no-repeat
          `,
          confirmButtonText: "Play Again",
        });

        // Set the flag in the session storage
        sessionStorage.setItem(`game_${sessionId}`, 'true');
      })
      .catch((gameError) => {
        console.error("Error playing the game:", gameError);
      });
  };

  // Rest of the component code...


  // Rest of the component code...



  const handleBoxClick = (boxNumber) => {
    setSelectedBox(boxNumber === selectedBox ? null : boxNumber);
  };

  // CSS styles for the box grid
  const boxColors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700", "#9370DB"]; // Different box colors
  const gridStyles = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))", // Responsive grid
    gap: "10px",
    marginTop: "20px",
    marginBottom: "20px", // Added margin bottom for space
  };
  const boxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    cursor: "pointer",
  };
  const boxNumberStyle = {
    fontSize: "1.5em",
    color: "white",
    fontWeight: "bold",
  };

  return (
    <div className="col-12 col-md-5 mb-5 mt-5">
      <div className="book-cover-holder">
        <h1 className="headline mb-3">Pick a box </h1>
        <div className="subheadline mb-4">Win up to 500,000 Ksh</div>
        {error && (
          <div
            className="alert alert-danger mt-1 mb-1"
            role="alert"
            style={{ textAlign: "center" }}
          >
            {errorMsg}
          </div>
        )}
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div>
              <LoadingSpinner />
              <h3>Processing payment...</h3>
              <h3 className="subheadline mb-4">STK PUSH SENT TO YOUR PHONE</h3>
              <h3
                className="subheadline mb-4 text-primary"
                style={{ fontWeight: "bold" }}
              >
                {details.phone}
              </h3>
              <h3 className="subheadline mb-4">Enter PIN to confirm payment</h3>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="phoneInput" className="form-label">
                Phone Number
              </label>
              <input
                type="tel"
                className="form-control"
                id="phoneInput"
                required
                autoFocus
                name="phone"
                onChange={handleChange}
                placeholder="e.g., 07xxx or 01xx"
                value={details.phone}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="amountInput" className="form-label">
                Amount
              </label>
              <input
                type="number"
                className="form-control"
                id="amountInput"
                required
                name="amount"
                placeholder="Ksh 20 - Ksh 1000"
                onChange={handleChange}
                value={details.amount}
              />
            </div>
            <label htmlFor="boxnumberInput" className="form-label">
            Pick a box
              </label>
            <div style={gridStyles}>
              {[1, 2, 3, 4, 5, 6].map((boxNumber, index) => (
                <div
                  key={boxNumber}
                  className="box"
                  style={{
                    ...boxStyle,
                    backgroundColor:
                      boxColors[index % boxColors.length], // Cycle through colors
                    boxShadow:
                      selectedBox === boxNumber
                        ? "0px 0px 5px 3px #000"
                        : "none", // Highlight on click
                  }}
                  onClick={() => handleBoxClick(boxNumber)}
                >
                  
                  <span style={boxNumberStyle}>Box {boxNumber}</span>
                </div>
              ))}
            </div>
            {error && (
          <div
            className="alert alert-danger mt-1 mb-1"
            role="alert"
            style={{ textAlign: "center" }}
          >
            {errorMsg}
          </div>
        )}
            <div className="col-12 col-md-auto mt-3">
              <button type="submit" className="btn btn-primary w-100">
                BET {details.amount}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}



export default PaymentForm;