// OtherPage.js
// OtherPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header'; // Ensure the path to the Header component is correct
import './OtherPage.css'; // Make sure the CSS file is imported

const OtherPage = () => {
  return (
    <div>
      <Header />
      <div className="container">
      <h1>Welcome to Our International Services Page</h1>
     <h2 className="heading">Countries Where Our Services Are Available:</h2>
        <div className="flags">
          <Link to="/ke"><img src="https://flagcdn.com/w320/ke.png" alt="Kenya" className="flag" /></Link>
          <Link to="/gh"><img src="https://flagcdn.com/w320/gh.png" alt="Ghana" className="flag" /></Link>
          <Link to="/ug"><img src="https://flagcdn.com/w320/ug.png" alt="Uganda" className="flag" /></Link>
          <Link to="/zam"><img src="https://flagcdn.com/w320/zm.png" alt="Zambia" className="flag" /></Link>      
        </div>
      </div>
    </div>
  );
};

export default OtherPage;



//<Link to="/drc"><img src="https://flagcdn.com/w320/cd.png" alt="DRC Congo" className="flag" /></Link>



