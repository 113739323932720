import React from "react";
import Header from "../components/HeaderGhana";
import Footer from "../components/FooterGhana";
import PrizeTable from "../components/PrizeTable";
import SuprizePrizeTable from "../components/SuprizePrizeTable";

const Prizepage = () => {
  return (
    <div className="App container-fluid d-flex flex-column min-vh-100">
      <Header />
      <main className="flex-grow-1" style={{ marginTop: '120px' }}>
        <section className="hero-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8" style={{ marginBottom: '50px' }}>


                <PrizeTable currency="GHS"/>
                <SuprizePrizeTable/>
                <div className="prize-description text-left" style={{ marginTop: '30px', marginBottom: '30px', padding: '20px' }}>
                  <h2>Description of Prize Levels</h2>
                  <p style={{ margin: '10px 0' }}><strong>Mini Win:</strong> Enjoy frequent small rewards that keep the excitement alive with every play.</p>
                  <p style={{ margin: '10px 0' }}><strong>Small Win:</strong> Experience the thrill of bigger prizes that give you a taste of substantial wins.</p>
                  <p style={{ margin: '10px 0' }}><strong>Medium Win:</strong> Aim for mid-range prizes that offer more generous rewards, perfect for boosting your winnings.</p>
                  <p style={{ margin: '10px 0' }}><strong>Large Win:</strong> Go for the highest regular prize tier and enjoy significant rewards that make every play worthwhile.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Prizepage;



// import React from "react";
// import Header from "../components/HeaderGhana";
// import Footer from "../components/FooterGhana";
// import PrizeTable from "../components/PrizeTable";

// const Prizepage = () => {
//   return (
//     <div className="App container-fluid d-flex flex-column min-vh-100">
//       <Header />
//       <main className="flex-grow-1" style={{ marginTop: '120px' }}>
//         <section className="hero-section">
//           <div className="container">
//             <div className="row justify-content-center">
//               <div className="col-12 col-md-8" style={{ marginBottom: '50px' }}>
//                 <PrizeTable currency="GHS"/>
//                 <div className="prize-description text-center">
//                   <h2>Description of Prize Levels</h2>
//                   <p><strong>Mini Win:</strong> The smallest but frequent prize, offering consistent small rewards.</p>
//                   <p><strong>Small Win:</strong> A slightly larger prize, giving players a taste of bigger wins.</p>
//                   <p><strong>Medium Win:</strong> A mid-range prize, ideal for more substantial winnings.</p>
//                   <p><strong>Large Win:</strong> The highest regular prize tier, offering significant rewards.</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </main>
//       <Footer />
//     </div>
//   );
// };

// export default Prizepage;

// import React from "react";
// import Header from "../components/HeaderGhana";
// import Footer from "../components/FooterGhana";
// import PrizeTable from "../components/PrizeTable";

// const Prizepage = () => {
//   return (
//     <div className="App container-fluid d-flex flex-column min-vh-100">
//       <Header />
//       <main className="flex-grow-1" style={{ marginTop: '120px' }}>
//         <section className="hero-section">
//           <div className="container">
//             <div className="row justify-content-center">
//               <div className="col-12 col-md-8" style={{ marginBottom: '50px' }} >
//                 <PrizeTable currency="GHS"/>
//               </div>
//             </div>
//           </div>
//         </section>
//       </main>
//       <Footer />
//     </div>
//   );
// };

// export default Prizepage;



// import React from "react";
// import DisplayTable from "../components/PawapayDisplayTable";
// import Header from "../components/HeaderGhana";
// import PaymentForm from "../components/PawapayPaymentForm";
// import Footer from "../components/FooterGhana";
// import airteltigoLogo from '../assets/airteltigo_gha.png';
// import mtnLogo from '../assets/mtn_momo_gha.png';
// import vodafoneLogo from '../assets/vodafone_gha.png';
// import PrizeTable from "../components/PrizeTable";

// const Prizepage = () => {
//   const PawapayPaymentformProps = {
//     currency: "GHS",
//     currencyOperator: "GHS",
//     countrycode: 233,
//     minAmount: 20,
//     maxAmount: 100,
//     platform: "web",
//     serviceCode: "2000",
//     winuptoAmount: "500,000",
//     validPrefixes: ["02", "05"],
//     operators: [
//       { id: 'AirtelTigo', name: 'AirtelTigo', logo: airteltigoLogo },
//       { id: 'MTN', name: 'MTN', logo: mtnLogo },
//       { id: 'Vodafone', name: 'Vodafone', logo: vodafoneLogo }
//     ],
//     operatorValues: {
//       "AirtelTigo": "AIRTELTIGO_GHA",
//       "MTN": "MTN_MOMO_GHA",
//       "Vodafone": "VODAFONE_GHA"
//     }
//   };

//   return (
//     <div className="App container-fluid d-flex flex-column min-vh-100">
//       <Header />
//       <main className="flex-grow-1" style={{ marginTop: '50px' }}>
//         <section className="hero-section">
//           <div className="container">
//             <div className="row">
//                 <PrizeTable/>
//             </div>
//           </div>
//         </section>
//       </main>
//       <Footer />
//     </div>
//   );
// };

// export default Prizepage;
