import React from 'react';
import Header from "../components/HeaderGhana";
import Footer from "../components/FooterGhana";

function Privacy() {
    return (
        <div className="App container-fluid d-flex flex-column min-vh-100">
            <Header />
            <main className="flex-grow-1" style={{ marginTop: '120px' }}>
                <section className="hero-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10">
                                <h2 className="text-center mb-4">Privacy Policy</h2>
                                <p>Effective Date: 19/4/2023</p>
                                <p>At Sparkhouse Promotions Ltd, we are committed to ensuring the privacy and security of the personal information collected during our Game promotion. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of personal information. By participating in our Game promotion, you consent to the terms outlined in this Privacy Policy.</p>
                                
                                <h4>Information We Collect</h4>
                                <p>
                                    <strong>Participant Information</strong><br />
                                    - Name<br />
                                    - Contact Information (Email Address, Phone Number)<br />
                                    - Mobile Money Account Details (for prize distribution)
                                </p>
                                <p>
                                    <strong>Demographic Information</strong><br />
                                    - Age<br />
                                    - Gender<br />
                                    - Location
                                </p>
                                <p>
                                    <strong>Transaction Information</strong><br />
                                    - Payment details if applicable
                                </p>

                                <h4>How We Use Collected Information</h4>
                                <p>
                                    <strong>Game Participation</strong><br />
                                    Use participant information for the purpose of Game entry and prize distribution.
                                </p>
                                <p>
                                    <strong>Marketing and Communication</strong><br />
                                    Send promotional updates, notifications, and marketing materials related to the Game promotion.
                                </p>
                                <p>
                                    <strong>Demographic Analysis</strong><br />
                                    Analyze aggregated demographic information to enhance the promotion's relevance.
                                </p>
                                <p>
                                    <strong>Legal Compliance</strong><br />
                                    Fulfill legal and regulatory requirements related to the promotion.
                                </p>

                                <h4>Information Sharing</h4>
                                <p>
                                    <strong>Third-Party Service Providers</strong><br />
                                    Share participant information with trusted third-party service providers strictly for promotion-related activities (e.g., prize distribution).
                                </p>
                                <p>
                                    <strong>Legal Compliance</strong><br />
                                    Disclose information as required by law or in response to a legal process.
                                </p>

                                <h4>Security Measures</h4>
                                <p>
                                    <strong>Data Encryption</strong><br />
                                    Employ encryption protocols to safeguard participant information during data transmission.
                                </p>
                                <p>
                                    <strong>Access Controls</strong><br />
                                    Restrict access to participant information to authorized personnel only.
                                </p>
                                <p>
                                    <strong>Data Security Audits</strong><br />
                                    Conduct regular security audits to identify and address potential vulnerabilities.
                                </p>

                                <h4>Participant Rights</h4>
                                <p>
                                    <strong>Access and Correction</strong><br />
                                    Participants have the right to access and correct their personal information.
                                </p>
                                <p>
                                    <strong>Withdrawal of Consent</strong><br />
                                    Participants can withdraw consent for data processing at any time.
                                </p>

                                <h4>Data Retention</h4>
                                <p>
                                    <strong>Participant Data</strong><br />
                                    Retain participant data for the duration of the promotion and for a reasonable period thereafter for legal and audit purposes.
                                </p>

                                <h4>Changes to Privacy Policy</h4>
                                <p>
                                    <strong>Policy Updates</strong><br />
                                    We reserve the right to update this Privacy Policy. Any changes will be communicated through our official communication channels.
                                </p>

                                <h4>Contact Information</h4>
                                <p>If you have any questions or concerns about this Privacy Policy, please contact us at 0244079441.</p>
                                <p>By participating in our Game promotion, you acknowledge that you have read and understood this Privacy Policy. Your continued participation constitutes acceptance of its terms.</p>
                                <p>Sparkhouse Promotions Ltd<br />Accra Ghana<br />Sparkhousepromotions@gmail.com<br />0244079441 / 0202339493</p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default Privacy;



// import React from 'react';
// import Header from "../components/HeaderGhana";
// import Footer from "../components/FooterGhana";

// function Privacy() {
//     return (
//         <div className="App container-fluid d-flex flex-column min-vh-100">
//             <Header />
//             <main className="flex-grow-1" style={{ marginTop: '120px' }}>
//                 <section className="hero-section">
//                     <div className="container">
//                         <div className="row justify-content-center">
//                             <div className="col-12 col-md-10">
//                                 <h2 className="text-center mb-4">Privacy Policy</h2>
//                                 <p>Effective Date: 19/4/2023</p>
//                                 <p>At Sparkhouse Promotions Ltd, we are committed to ensuring the privacy and security of the personal information collected during our Game promotion. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of personal information. By participating in our Game promotion, you consent to the terms outlined in this Privacy Policy.</p>
                                
//                                 <h4>Information We Collect</h4>
//                                 <ol className="list-group list-group-numbered">
//                                     <li className="list-group-item">
//                                         <h5>Participant Information</h5>
//                                         <ul>
//                                             <li>Name</li>
//                                             <li>Contact Information (Email Address, Phone Number)</li>
//                                             <li>Mobile Money Account Details (for prize distribution)</li>
//                                         </ul>
//                                     </li>
//                                     <li className="list-group-item">
//                                         <h5>Demographic Information</h5>
//                                         <ul>
//                                             <li>Age</li>
//                                             <li>Gender</li>
//                                             <li>Location</li>
//                                         </ul>
//                                     </li>
//                                     <li className="list-group-item">
//                                         <h5>Transaction Information</h5>
//                                         <p>Payment details if applicable</p>
//                                     </li>
//                                 </ol>

//                                 <h4>How We Use Collected Information</h4>
//                                 <ol className="list-group list-group-numbered">
//                                     <li className="list-group-item">
//                                         <h5>Game Participation</h5>
//                                         <p>Use participant information for the purpose of Game entry and prize distribution.</p>
//                                     </li>
//                                     <li className="list-group-item">
//                                         <h5>Marketing and Communication</h5>
//                                         <p>Send promotional updates, notifications, and marketing materials related to the Game promotion.</p>
//                                     </li>
//                                     <li className="list-group-item">
//                                         <h5>Demographic Analysis</h5>
//                                         <p>Analyze aggregated demographic information to enhance the promotion's relevance.</p>
//                                     </li>
//                                     <li className="list-group-item">
//                                         <h5>Legal Compliance</h5>
//                                         <p>Fulfill legal and regulatory requirements related to the promotion.</p>
//                                     </li>
//                                 </ol>

//                                 <h4>Information Sharing</h4>
//                                 <ol className="list-group list-group-numbered">
//                                     <li className="list-group-item">
//                                         <h5>Third-Party Service Providers</h5>
//                                         <p>Share participant information with trusted third-party service providers strictly for promotion-related activities (e.g., prize distribution).</p>
//                                     </li>
//                                     <li className="list-group-item">
//                                         <h5>Legal Compliance</h5>
//                                         <p>Disclose information as required by law or in response to a legal process.</p>
//                                     </li>
//                                 </ol>

//                                 <h4>Security Measures</h4>
//                                 <ol className="list-group list-group-numbered">
//                                     <li className="list-group-item">
//                                         <h5>Data Encryption</h5>
//                                         <p>Employ encryption protocols to safeguard participant information during data transmission.</p>
//                                     </li>
//                                     <li className="list-group-item">
//                                         <h5>Access Controls</h5>
//                                         <p>Restrict access to participant information to authorized personnel only.</p>
//                                     </li>
//                                     <li className="list-group-item">
//                                         <h5>Data Security Audits</h5>
//                                         <p>Conduct regular security audits to identify and address potential vulnerabilities.</p>
//                                     </li>
//                                 </ol>

//                                 <h4>Participant Rights</h4>
//                                 <ol className="list-group list-group-numbered">
//                                     <li className="list-group-item">
//                                         <h5>Access and Correction</h5>
//                                         <p>Participants have the right to access and correct their personal information.</p>
//                                     </li>
//                                     <li className="list-group-item">
//                                         <h5>Withdrawal of Consent</h5>
//                                         <p>Participants can withdraw consent for data processing at any time.</p>
//                                     </li>
//                                 </ol>

//                                 <h4>Data Retention</h4>
//                                 <ol className="list-group list-group-numbered">
//                                     <li className="list-group-item">
//                                         <h5>Participant Data</h5>
//                                         <p>Retain participant data for the duration of the promotion and for a reasonable period thereafter for legal and audit purposes.</p>
//                                     </li>
//                                 </ol>

//                                 <h4>Changes to Privacy Policy</h4>
//                                 <ol className="list-group list-group-numbered">
//                                     <li className="list-group-item">
//                                         <h5>Policy Updates</h5>
//                                         <p>We reserve the right to update this Privacy Policy. Any changes will be communicated through our official communication channels.</p>
//                                     </li>
//                                 </ol>

//                                 <h4>Contact Information</h4>
//                                 <p>If you have any questions or concerns about this Privacy Policy, please contact us at 0244079441.</p>
//                                 <p>By participating in our Game promotion, you acknowledge that you have read and understood this Privacy Policy. Your continued participation constitutes acceptance of its terms.</p>
//                                 <p>Sparkhouse Promotions Ltd<br />Accra Ghana<br />Sparkhousepromotions@gmail.com<br />0244079441 / 0202339493</p>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//             </main>
//             <Footer />
//         </div>
//     );
// }

// export default Privacy;
