import React from "react";
import './Table.css'; // Import the CSS file

function PrizeTable() {
  // Set the currency as a constant for easy modification if necessary
  const currency = "GHS";

  // Array of objects representing different betting amounts and corresponding winnings
  const winnings = [
    { betAmount: 5, miniWin: 5, smallWin: 10, mediumWin: 20, largeWin: 50, megaWin: '10,000' },
    { betAmount: 10, miniWin: 10, smallWin: 20, mediumWin: 40, largeWin: 100, megaWin: '20,000' },
    { betAmount: 20, miniWin: 20, smallWin: 40, mediumWin: 80, largeWin: 200, megaWin: '30,000' },
    { betAmount: 50, miniWin: 50, smallWin: 100, mediumWin: 200, largeWin: 500, megaWin: '40,000' },
    { betAmount: 100, miniWin: 100, smallWin: 200, mediumWin: 400, largeWin: 1000, megaWin: '50,000' },
    { betAmount: 200, miniWin: 200, smallWin: 400, mediumWin: 800, largeWin: 2000, megaWin: '80,000' },
    { betAmount: 500, miniWin: 500, smallWin: 1000, mediumWin: 2000, largeWin: 5000, megaWin: '100,000' },
    { betAmount: 1000, miniWin: 1000, smallWin: 2000, mediumWin: 4000, largeWin: 10000, megaWin: '200,000' },
    { betAmount: 2000, miniWin: 2000, smallWin: 4000, mediumWin: 8000, largeWin: 20000, megaWin: '500,000' },
  ];

  // JSX to render the table
  return (
    <div className="table-container">
      <h2 className="text-center mb-4">Cash Prize Table Estimates</h2>
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Play Amount ({currency})</th>
              <th scope="col">Mega Win ({currency})</th>
              <th scope="col">Large Win ({currency})</th>
              <th scope="col">Medium Win ({currency})</th>
              <th scope="col">Small Win ({currency})</th>
              <th scope="col">Draw ({currency})</th>
            </tr>
          </thead>
          <tbody>
            {winnings.map((win, index) => (
              <tr key={index}>
                <td>{win.betAmount}</td>
                <td style={{ fontWeight: 'bold' }}>{win.megaWin}</td>
                <td>{win.largeWin}</td>
                <td>{win.mediumWin}</td>
                <td>{win.smallWin}</td>
                <td>{win.miniWin}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PrizeTable;


// import React from "react";
// import './Table.css'; // Import the CSS file

// function PrizeTable() {
//   // Set the currency as a constant for easy modification if necessary
//   const currency = "GHS";

//   // Array of objects representing different betting amounts and corresponding winnings
//   const winnings = [
//     { betAmount: 5, miniWin: 5, smallWin: 10, mediumWin: 20, largeWin: 50, megaWin: '10,000' },
//     { betAmount: 10, miniWin: 10, smallWin: 20, mediumWin: 40, largeWin: 100, megaWin: '20,000' },
//     { betAmount: 20, miniWin: 20, smallWin: 40, mediumWin: 80, largeWin: 200, megaWin: '30,000' },
//     { betAmount: 50, miniWin: 50, smallWin: 100, mediumWin: 200, largeWin: 500, megaWin: '40,000' },
//     { betAmount: 100, miniWin: 100, smallWin: 200, mediumWin: 400, largeWin: 1000, megaWin: '50,000' },
//     { betAmount: 200, miniWin: 200, smallWin: 400, mediumWin: 800, largeWin: 2000, megaWin: '80,000' },
//     { betAmount: 500, miniWin: 500, smallWin: 1000, mediumWin: 2000, largeWin: 5000, megaWin: '100,000' },
//     { betAmount: 1000, miniWin: 1000, smallWin: 2000, mediumWin: 4000, largeWin: 10000, megaWin: '200,000' },
//     { betAmount: 2000, miniWin: 2000, smallWin: 4000, mediumWin: 8000, largeWin: 20000, megaWin: '500,000' },
//   ];

//   // JSX to render the table
//   return (
//     <div className="table-container">
//       <h2 className="text-center mb-4">Cash Prize Table Estimates</h2>
//       <div className="table-responsive">
//         <table className="table table-bordered table-hover">
//           <thead className="thead-dark">
//             <tr>
//               <th scope="col">Play Amount ({currency})</th>
//               <th scope="col">Mega Win ({currency})</th>
//               <th scope="col">Large Win ({currency})</th>
//               <th scope="col">Medium Win ({currency})</th>
//               <th scope="col">Small Win ({currency})</th>
//               <th scope="col">Mini Win ({currency})</th>
//             </tr>
//           </thead>
//           <tbody>
//             {winnings.map((win, index) => (
//               <tr key={index}>
//                 <td>{win.betAmount}</td>
//                 <td>{win.megaWin}</td>
//                 <td>{win.largeWin}</td>
//                 <td>{win.mediumWin}</td>
//                 <td>{win.smallWin}</td>
//                 <td>{win.miniWin}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// export default PrizeTable;



// import React from "react";
// import './Table.css'; // Import the CSS file

// function PrizeTable() {

//   const  currency="GHS"
//   const winnings = [
//     { betAmount: 5, miniWin: 5, smallWin: 10, mediumWin: 20, largeWin: 50, megaWin: '10000' },
//     { betAmount: 10, miniWin: 10, smallWin: 20, mediumWin: 40, largeWin: 100, megaWin: '20000' },
//     { betAmount: 20, miniWin: 20, smallWin: 40, mediumWin: 80, largeWin: 200, megaWin: '30000' },
//     { betAmount: 50, miniWin: 50, smallWin: 100, mediumWin: 200, largeWin: 500, megaWin: '40000' },
//     { betAmount: 100, miniWin: 100, smallWin: 200, mediumWin: 400, largeWin: 1000, megaWin: '50000' },
//     { betAmount: 200, miniWin: 200, smallWin: 400, mediumWin: 800, largeWin: 2000, megaWin: '80000' },
//     { betAmount: 500, miniWin: 500, smallWin: 1000, mediumWin: 2000, largeWin: 5000, megaWin: '100000' },
//     { betAmount: 1000, miniWin: 1000, smallWin: 2000, mediumWin: 4000, largeWin: 10000, megaWin: 200000 },
//     { betAmount: 2000, miniWin: 2000, smallWin: 4000, mediumWin: 8000, largeWin: 20000, megaWin: 500000 },
//   ];

//   return (
//     <div className="table-container">
//       <h2 className="text-center mb-4">Cash Prize Table Estimates</h2>
//       <div className="table-responsive">
//         <table className="table table-bordered table-hover">
//           <thead className="thead-dark">
//             <tr>
//               <th scope="col">Play Amount (GHS)</th>
//               <th scope="col">Mini Win (GHS)</th>
//               <th scope="col">Small Win (GHS)</th>
//               <th scope="col">Medium Win (GHS)</th>
//               <th scope="col">Large Win (GHS)</th>
//               <th scope="col">Mega Win (GHS)</th>
//             </tr>
//           </thead>
//           <tbody>
//             {winnings.map((win, index) => (
//               <tr key={index}>
//                 <td>{win.betAmount}</td>
//                 <td>{win.miniWin}</td>
//                 <td>{win.smallWin}</td>
//                 <td>{win.mediumWin}</td>
//                 <td>{win.largeWin}</td>
//                 <td>{win.megaWin}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// export default PrizeTable;



// import React from "react";
// import './Table.css'; // Import the CSS file

// function PrizeTable() {
//   const winnings = [
//     { betAmount: 5, miniWin: 5, smallWin: 10, mediumWin: 20, largeWin: 50, megaWin: '-' },
//     { betAmount: 10, miniWin: 10, smallWin: 20, mediumWin: 40, largeWin: 100, megaWin: '-' },
//     { betAmount: 20, miniWin: 20, smallWin: 40, mediumWin: 80, largeWin: 200, megaWin: '-' },
//     { betAmount: 50, miniWin: 50, smallWin: 100, mediumWin: 200, largeWin: 500, megaWin: '-' },
//     { betAmount: 100, miniWin: 100, smallWin: 200, mediumWin: 400, largeWin: 1000, megaWin: '-' },
//     { betAmount: 200, miniWin: 200, smallWin: 400, mediumWin: 800, largeWin: 2000, megaWin: '-' },
//     { betAmount: 500, miniWin: 500, smallWin: 1000, mediumWin: 2000, largeWin: 5000, megaWin: '-' },
//     { betAmount: 1000, miniWin: 1000, smallWin: 2000, mediumWin: 4000, largeWin: 10000, megaWin: 500000 },
//     { betAmount: 2000, miniWin: 2000, smallWin: 4000, mediumWin: 8000, largeWin: 20000, megaWin: 500000 },
//   ];

//   return (
//     <div className="table-responsive">
//       <table className="table table-bordered table-hover">
//         <thead className="thead-dark">
//           <tr>
//             <th scope="col">Bet Amount ($)</th>
//             <th scope="col">Mini Win ($)</th>
//             <th scope="col">Small Win ($)</th>
//             <th scope="col">Medium Win ($)</th>
//             <th scope="col">Large Win ($)</th>
//             <th scope="col">Mega Win ($)</th>
//           </tr>
//         </thead>
//         <tbody>
//           {winnings.map((win, index) => (
//             <tr key={index}>
//               <td>{win.betAmount}</td>
//               <td>{win.miniWin}</td>
//               <td>{win.smallWin}</td>
//               <td>{win.mediumWin}</td>
//               <td>{win.largeWin}</td>
//               <td>{win.megaWin}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }

// export default PrizeTable;

