import React, { useEffect, useState } from "react";
import swal from 'sweetalert2';
import DisplayTable from "../components/PawapayDisplayTable";
import Header from "../components/HeaderGhana";
import PaymentForm from "../components/PawapayPaymentForm";
import Footer from "../components/FooterGhana";
import airteltigoLogo from '../assets/airteltigo_gha.png';
import mtnLogo from '../assets/mtn_momo_gha.png';
import vodafoneLogo from '../assets/vodafone_gha.png';
import PrizeTable from "../components/PrizeTable";
import LoadingSpinner from '../components/LoadingSpinner';

const GHPage = () => {
  const [ageVerified, setAgeVerified] = useState(false);

  useEffect(() => {
    const checkAgeVerification = async () => {
      const ageVerified = localStorage.getItem('ageVerified');
      if (!ageVerified) {
        const { value: age } = await swal.fire({
          title: 'Age Verification',
          text: 'You must be 18 or older to access this site.',
          input: 'number',
          inputAttributes: {
            placeholder: 'Enter your age',
            min: 0,
            max: 99,
            step: 1
          },
          confirmButtonColor: '#e76a20',
          showCancelButton: false,
          confirmButtonText: 'Continue',
          allowOutsideClick: false,
          allowEscapeKey: false,
          preConfirm: (age) => {
            if (age < 18) {
              swal.showValidationMessage('You must be 18 or older to access this site.');
            }
          }
        });

        if (age >= 18) {
          localStorage.setItem('ageVerified', 'true');
          setAgeVerified(true);
        } else {
          swal.fire('Access Denied', 'You must be 18 or older to access this site.', 'error');
        }
      } else {
        setAgeVerified(true);
      }
    };

    checkAgeVerification();
  }, []);

  if (!ageVerified) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>Age Verification Pending...</h2>
        <LoadingSpinner />
      </div>
    );
  }

  const PawapayPaymentformProps = {
    currency: "GHS",
    currencyOperator: "GHS",
    countrycode: 233,
    countryName:"Ghana",
    minAmount: 5,
    maxAmount: 2000,
    platform: "WEB",
    serviceCode: "2000",
    winuptoAmount: "500,000",
    validPrefixes: ["02", "05"],
    operators: [
      { id: 'AirtelTigo', name: 'AirtelTigo', logo: airteltigoLogo },
      { id: 'MTN', name: 'MTN', logo: mtnLogo },
      { id: 'Vodafone', name: 'Vodafone', logo: vodafoneLogo }
    ],
    operatorValues: {
      "AirtelTigo": "AIRTELTIGO_GHA",
      "MTN": "MTN_MOMO_GHA",
      "Vodafone": "VODAFONE_GHA"
    }
  };

  return (
    <div className="App container-fluid d-flex flex-column min-vh-100">
      <Header />
      <main className="flex-grow-1" style={{ marginTop: '50px' }}>
        <section className="hero-section">
          <div className="container">
            <div className="row">
              <PaymentForm {...PawapayPaymentformProps} />
              <DisplayTable
                countryCode={PawapayPaymentformProps.countrycode.toString()}
                currency={PawapayPaymentformProps.currency}
                firstDigits={PawapayPaymentformProps.validPrefixes.map(Number)}
                minBetAmount={PawapayPaymentformProps.minAmount}
                maxBetAmount={PawapayPaymentformProps.maxAmount}
                count={16}
                tableHeading="Top Recent Winners"
              />
            </div>
          </div>
        </section>

        <PrizeTable />
      </main>
      <Footer />
    </div>
  );
};

export default GHPage;



// import React, { useEffect, useState } from "react";
// import swal from 'sweetalert2';
// import DisplayTable from "../components/PawapayDisplayTable";
// import Header from "../components/HeaderGhana";
// import PaymentForm from "../components/PawapayPaymentForm";
// import Footer from "../components/FooterGhana";
// import airteltigoLogo from '../assets/airteltigo_gha.png';
// import mtnLogo from '../assets/mtn_momo_gha.png';
// import vodafoneLogo from '../assets/vodafone_gha.png';
// import PrizeTable from "../components/PrizeTable";
// import LoadingSpinner from '../components/LoadingSpinner';

// const GHPage = () => {
//   const [ageVerified, setAgeVerified] = useState(false);

//   useEffect(() => {
//     const verifyAge = async () => {
//       const { value: age } = await swal.fire({
//         title: 'Age Verification',
//         text: 'You must be 18 or older to access this site.',
//         input: 'number',
//         inputAttributes: {
//           placeholder: 'Enter your age',
//           min: 0,
//           max: 99,
//           step: 1
//         },
//         confirmButtonColor: '#e76a20',
//         showCancelButton: false,
//         confirmButtonText: 'Continue',
//         allowOutsideClick: false,
//         allowEscapeKey: false,
//         preConfirm: (age) => {
//           if (age < 18) {
//             swal.showValidationMessage('You must be 18 or older to access this site.');
//           }
//         }
//       });

//       if (age >= 18) {
//         setAgeVerified(true);
//       } else {
//         swal.fire('Access Denied', 'You must be 18 or older to access this site.', 'error');
//       }
//     };

//     verifyAge();
//   }, []);

//   if (!ageVerified) {
//     return (
//       <div style={{ textAlign: 'center' }}>
//         <h2>Age Verification Pending...</h2>
//         <LoadingSpinner />
//       </div>
//     );
//   }

//   const PawapayPaymentformProps = {
//     currency: "GHS",
//     currencyOperator: "GHS",
//     countrycode: 233,
//     minAmount: 20,
//     maxAmount: 1000,
//     platform: "web",
//     serviceCode: "2000",
//     winuptoAmount: "500,000",
//     validPrefixes: ["02", "05"],
//     operators: [
//       { id: 'AirtelTigo', name: 'AirtelTigo', logo: airteltigoLogo },
//       { id: 'MTN', name: 'MTN', logo: mtnLogo },
//       { id: 'Vodafone', name: 'Vodafone', logo: vodafoneLogo }
//     ],
//     operatorValues: {
//       "AirtelTigo": "AIRTELTIGO_GHA",
//       "MTN": "MTN_MOMO_GHA",
//       "Vodafone": "VODAFONE_GHA"
//     }
//   };

//   return (
//     <div className="App container-fluid d-flex flex-column min-vh-100">
//       <Header />
//       <main className="flex-grow-1" style={{ marginTop: '50px' }}>
//         <section className="hero-section">
//           <div className="container">
//             <div className="row">
//               <PaymentForm {...PawapayPaymentformProps} />
//               <DisplayTable
//                 countryCode={PawapayPaymentformProps.countrycode.toString()}
//                 currency={PawapayPaymentformProps.currency}
//                 firstDigits={PawapayPaymentformProps.validPrefixes.map(Number)}
//                 minBetAmount={PawapayPaymentformProps.minAmount}
//                 maxBetAmount={PawapayPaymentformProps.maxAmount}
//                 count={16}
//                 tableHeading="Top Recent Winners"
//               />
//             </div>
//           </div>
//         </section>

//         <PrizeTable />
//       </main>
//       <Footer />
//     </div>
//   );
// };

// export default GHPage;




// import React from "react";
// import DisplayTable from "../components/PawapayDisplayTable";
// import Header from "../components/HeaderGhana";
// import PaymentForm from "../components/PawapayPaymentForm";
// import Footer from "../components/FooterGhana";
// import airteltigoLogo from '../assets/airteltigo_gha.png';
// import mtnLogo from '../assets/mtn_momo_gha.png';
// import vodafoneLogo from '../assets/vodafone_gha.png';
// import PrizeTable from "../components/PrizeTable";

// const GHPage = () => {
//   const PawapayPaymentformProps = {
//     currency: "GHS",
//     currencyOperator: "GHS",
//     countrycode: 233,
//     minAmount: 20,
//     maxAmount: 1000,
//     platform: "web",
//     serviceCode: "2000",
//     winuptoAmount: "500,000",
//     validPrefixes: ["02", "05"],
//     operators: [
//       { id: 'AirtelTigo', name: 'AirtelTigo', logo: airteltigoLogo },
//       { id: 'MTN', name: 'MTN', logo: mtnLogo },
//       { id: 'Vodafone', name: 'Vodafone', logo: vodafoneLogo }
//     ],
//     operatorValues: {
//       "AirtelTigo": "AIRTELTIGO_GHA",
//       "MTN": "MTN_MOMO_GHA",
//       "Vodafone": "VODAFONE_GHA"
//     }
//   };

//   return (
//     <div className="App container-fluid d-flex flex-column min-vh-100">
//       <Header />
//       <main className="flex-grow-1" style={{ marginTop: '50px' }}>
//         <section className="hero-section">
//           <div className="container">
//             <div className="row">
//               <PaymentForm {...PawapayPaymentformProps} />
//               <DisplayTable
//                 countryCode={PawapayPaymentformProps.countrycode.toString()}
//                 currency={PawapayPaymentformProps.currency}
//                 firstDigits={PawapayPaymentformProps.validPrefixes.map(Number)}
//                 minBetAmount={PawapayPaymentformProps.minAmount}
//                 maxBetAmount={PawapayPaymentformProps.maxAmount}
//                 count={16}
//                 tableHeading="Top Recent Winners"
//               />
//             </div>
//           </div>
//         </section>

//         <PrizeTable/>
//       </main>
//       <Footer />
//     </div>
//   );
// };

// export default GHPage;


// import React from "react";
// import DisplayTable from "../components/PawapayDisplayTable";
// import Header from "../components/HeaderGhana";
// import PaymentForm from "../components/PawapayPaymentForm";
// import Footer from "../components/Footer";
// import airteltigoLogo from '../assets/airteltigo_gha.png';
// import mtnLogo from '../assets/mtn_momo_gha.png';
// import vodafoneLogo from '../assets/vodafone_gha.png';

// const GHPage = () => {
//   const PawapayPaymentformProps = {
//     currency: "GHS",
//     currencyOperator: "GHS",
//     countrycode: 233,
//     minAmount: 20,
//     maxAmount: 100,
//     platform:"web",
//     serviceCode:"2000",
//     winuptoAmount:"500,000",
//     validPrefixes: ["02", "05"],
//     operators: [
//       { id: 'AirtelTigo', name: 'AirtelTigo', logo: airteltigoLogo },
//       { id: 'MTN', name: 'MTN', logo: mtnLogo },
//       { id: 'Vodafone', name: 'Vodafone', logo: vodafoneLogo }
//     ],
//     operatorValues: {
//       "AirtelTigo": "AIRTELTIGO_GHA",
//       "MTN": "MTN_MOMO_GHA",
//       "Vodaf,one": "VODAFONE_GHA"
//     }
//   };

//   return (
//     <div className="App container-fluid d-flex flex-column min-vh-100">
//       <Header />
//       <main className="flex-grow-1">
//         <section className="hero-section">
//           <div className="container">
//             <div className="row">
//               <PaymentForm {...PawapayPaymentformProps} />
//               <DisplayTable
//                 countryCode={PawapayPaymentformProps.countrycode.toString()}
//                 currency={PawapayPaymentformProps.currency}
//                 firstDigits={PawapayPaymentformProps.validPrefixes.map(Number)}
//                 minBetAmount={PawapayPaymentformProps.minAmount}
//                 maxBetAmount={PawapayPaymentformProps.maxAmount}
//                 count={11}
//                 tableHeading="Top Recent Winners"
//               />
//             </div>
//           </div>
//         </section>
//       </main>
//       <Footer />
//     </div>
//   );
// };

// export default GHPage;


// //  GHPage.js
// import DisplayTable from "../components/Ghana/DisplayTable";
// import Header from "../components/Header";
// import PaymentForm from "../components/PawapayPaymentForm";
// import Footer from "../components/Footer";

// import airteltigoLogo from '../assets/airteltigo_gha.png';
// import mtnLogo from '../assets/mtn_momo_gha.png';
// import vodafoneLogo from '../assets/vodafone_gha.png';


// const GHPage = () => {

//   const PawapayPaymentformProps = {
//     currency: "GHS",  // symbol  e.g ksh  
//     currencyOperator: "GHS",// Contry currency  detemined by Pawapay
//     countrycode: 233,
//     minAmount: 20,// min bet amount
//     maxAmount: 100,// max bet amount
//     validPrefixes: ["02", "05"],  // first 2 digit  of the network code 
//     operators: [
//       { id: 'AirtelTigo', name: 'AirtelTigo', logo: airteltigoLogo },
//       { id: 'MTN', name: 'MTN', logo: mtnLogo },
//       { id: 'Vodafone', name: 'Vodafone', logo: vodafoneLogo }
//     ], /// detremined by Pawapay beased on  the country
//     operatorValues: {
//       "AirtelTigo": "AIRTELTIGO_GHA",
//       "MTN": "MTN_MOMO_GHA",
//       "Vodafone": "VODAFONE_GHA"
//     }
//   };//   ui display of country operator 






//   return (
//     <div className="App container-fluid d-flex flex-column min-vh-100">
//       <Header />
//       <main className="flex-grow-1">
//         <section className="hero-section">
//           <div className="container">
//             <div className="row">
//               <PaymentForm {...PawapayPaymentformProps} />
//               <DisplayTable />
//             </div>
//           </div>
//         </section>
//       </main>
//       <Footer />
//     </div>
//   );
// };

// export default GHPage;