import React, { useState, useEffect, useCallback,useRef } from "react";
import ReactDOM from 'react-dom/client';
import LoadingSpinner from "./LoadingSpinner";
import Swal from "sweetalert2";
import RewardsGrid from "./RewardsGrid";
import axios from "axios";
import Operator from "./Operator";
import * as Ably from "ably";
import { debounce } from 'lodash'; // Make sure to install lodash if you haven't already

function PaymentForm({
  currency,
  currencyOperator,
  countrycode,
  minAmount,
  maxAmount,
  validPrefixes,
  operators,
  operatorValues,
  winuptoAmount,
  platform,
  serviceCode,
  countryName,
}) {
  const [details, setDetails] = useState({
    phone: "",
    amount: "",
  });
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedBox, setSelectedBox] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [processedMessageIds, setProcessedMessageIds] = useState(new Set());
  const [otpSent, setOtpSent] = useState(false);

  // const processedDepositIdsRef = useRef(new Set());
  // const isProcessingRef = useRef(false);

  const processedTransactionsRef = useRef(new Set());
  const pendingTransactionRef = useRef(null);

  // const processedMessageIdsRef = useRef(new Set());
  // const lastProcessedTimestampRef = useRef(0);


  // const processedDepositIdsRef = useRef(new Set());
  // const lastProcessedTimestampRef = useRef(0);

  // const playGame = useCallback((amount, boxNumber, phone, depositId) => {
  //   const formattedPhone = `233${phone.substring(phone.length - 9)}`;
  //   axios
  //     .post("/webgame/play-lucky-box-pawapay", {
  //       betAmount: amount,
  //       userBox: boxNumber,
  //       phoneNumber: formattedPhone,
  //       sessionId: depositId,
  //       currencyOperator,
  //       correspondent: selectedOperator,
  //       currency,
  //       countrycode,
  //     })
  //     .then((gameResult) => {
  //       const rewards = gameResult.data.details.LostfakeRewards;
  //       Swal.fire({
  //         title: gameResult.data.result,
  //         html: `
  //           <div>You selected Box ${gameResult.data.userBox}</div>
  //           <div>Your prize: ${gameResult.data.prize}</div>
  //           <div id="rewards-grid"></div>
  //         `,
  //         icon: "success",
  //         didOpen: () => {
  //           const rewardsContainer = Swal.getHtmlContainer().querySelector('#rewards-grid');
  //           if (rewardsContainer) {
  //             ReactDOM.createRoot(rewardsContainer).render(<RewardsGrid rewards={rewards} userBox={gameResult.data.userBox} />);
  //           }
  //         }
  //       });
  //     })
  //     .catch((gameError) => {
  //       console.error("Error playing the game:", gameError);
  //       Swal.fire("Error", "There was an issue playing the game. Please try again.", "error");
  //     });
  // }, [currency, currencyOperator, countrycode, selectedOperator]);


  

  // const handleAblyMessage = useCallback((message) => {
  //   console.log("Received payment status update:", message.data);
  //   const { messageId, timestamp, status, depositedAmount, depositId, phoneNumber } = message.data;

  //    // If we're already processing a message, ignore this one
  //    if (isProcessingRef.current) {
  //     console.log("Already processing a message, ignoring:", depositId);
  //     return;
  //   }

  //   // If this deposit has already been processed, ignore it
  //   if (processedDepositIdsRef.current.has(depositId)) {
  //     console.log("Deposit already processed, ignoring:", depositId);
  //     return;
  //   }

  //   // Set the processing flag
  //   isProcessingRef.current = true;

   

    
  //   console.log("Received payment status update:", message.data);

  //   if (status === "COMPLETED") {
  //     setLoading(false);
  //     Swal.fire({
  //       title: "Payment Completed",
  //       text: `Your payment of ${depositedAmount} ${currency} was successful! Starting the game...`,
  //       icon: "success",
  //       timer: 3000,
  //       showConfirmButton: false,
  //     }).then(() => {
  //       playGame(depositedAmount, selectedBox, phoneNumber, depositId);

  //     });
  //   } else if (status === "FAILED") {
  //     setLoading(false);
  //     Swal.fire("Payment Failed", "Your payment failed. Please try again.", "error");
  //   }
  // }, [currency, selectedBox, playGame]);

  // useEffect(() => {
  //   let ably;
  //   let channel;

  //   const setupAbly = async () => {
  //     try {
  //       ably = new Ably.Realtime({ key: process.env.REACT_APP_ABLY_API });
  //       await ably.connection.once('connected');
  //       console.log('Successfully connected to Ably');
  //       channel = ably.channels.get("stk-updates");
  //       channel.subscribe("deposit-status", handleAblyMessage);
  //     } catch (error) {
  //       console.error('Error connecting to Ably:', error);
  //       Swal.fire("Connection Error", "Failed to establish real-time connection. Please refresh the page.", "error");
  //     }
  //   };

  //   setupAbly();

  //   return () => {
  //     if (channel) channel.unsubscribe();
  //     if (ably) ably.close();
  //   };
  // }, [handleAblyMessage]);



  const playGame = useCallback((amount, boxNumber, phone, depositId) => {
    if (processedTransactionsRef.current.has(depositId)) {
      console.log(`Transaction ${depositId} has already been processed. Skipping game play.`);
      return;
    }

    const formattedPhone = `233${phone.substring(phone.length - 9)}`;
    axios.post("/webgame/play-lucky-box-pawapay", {
      betAmount: amount,
      userBox: boxNumber,
      phoneNumber: formattedPhone,
      sessionId: depositId,
      currencyOperator,
      correspondent: selectedOperator,
      currency,
      countrycode,
    })
    .then((response) => {
      const gameResult = response.data;
      processedTransactionsRef.current.add(depositId);
      const rewards = gameResult.details.LostfakeRewards;
      Swal.fire({
        title: gameResult.result,
        html: `
          <div>You selected Box ${gameResult.userBox}</div>
          <div>Your prize: ${gameResult.prize}</div>
          <div id="rewards-grid"></div>
        `,
        icon: "success",
        didOpen: () => {
          const rewardsContainer = Swal.getHtmlContainer().querySelector('#rewards-grid');
          if (rewardsContainer) {
            ReactDOM.createRoot(rewardsContainer).render(<RewardsGrid rewards={rewards} userBox={gameResult.userBox} />);
          }
        }
      });
    })
    .catch((error) => {
      console.error("Error playing the game:", error);
      Swal.fire("Error", "There was an issue playing the game. Please try again.", "error");
    })
    .finally(() => {
      pendingTransactionRef.current = null;
    });
  }, [currency, currencyOperator, countrycode, selectedOperator]);

  const handleAblyMessage = useCallback((message) => {
    console.log("Received payment status update:", message.data);
    const { status, depositedAmount, depositId, phoneNumber } = message.data;

    if (processedTransactionsRef.current.has(depositId)) {
      console.log(`Transaction ${depositId} has already been processed. Ignoring message.`);
      return;
    }

    if (pendingTransactionRef.current && pendingTransactionRef.current !== depositId) {
      console.log(`Waiting for transaction ${pendingTransactionRef.current} to complete. Ignoring message for ${depositId}.`);
      return;
    }

    pendingTransactionRef.current = depositId;

    if (status === "COMPLETED") {
      setLoading(false);
      Swal.fire({
        title: "Payment Completed",
        text: `Your payment of ${depositedAmount} ${currency} was successful! Starting the game...`,
        icon: "success",
        timer: 3000,
        showConfirmButton: false,
      }).then(() => {
        playGame(depositedAmount, selectedBox, phoneNumber, depositId);
      });
    } else if (status === "FAILED") {
      setLoading(false);
      Swal.fire("Payment Failed", "Your payment failed. Please try again.", "error");
      pendingTransactionRef.current = null;
    }
  }, [currency, selectedBox, playGame]);

  useEffect(() => {
    let ably;
    let channel;

    const setupAbly = async () => {
      try {
        ably = new Ably.Realtime({ key: process.env.REACT_APP_ABLY_API });
        await ably.connection.once('connected');
        console.log('Successfully connected to Ably');
        channel = ably.channels.get("stk-updates");
        channel.subscribe("deposit-status", handleAblyMessage);
      } catch (error) {
        console.error('Error connecting to Ably:', error);
        Swal.fire("Connection Error", "Failed to establish real-time connection. Please refresh the page.", "error");
      }
    };

    setupAbly();

    return () => {
      if (channel) channel.unsubscribe();
      if (ably) ably.close();
    };
  }, [handleAblyMessage]);













  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleOperatorSelect = (operatorName) => {
    setSelectedOperator(operatorValues[operatorName]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const betAmount = parseFloat(details.amount);

    setError(false);
    setErrorMsg("");

    if (!details.phone || isNaN(betAmount) || selectedBox === null || !selectedOperator) {
      setError(true);
      setErrorMsg("Please complete all fields correctly.");
      return;
    }

    const enteredPrefix = details.phone.substring(0, 2);
    if (!validPrefixes.includes(enteredPrefix)) {
      setError(true);
      setErrorMsg(`Phone number should start with one of the valid network codes: ${validPrefixes.join(", ")}`);
      return;
    }

    if (betAmount < minAmount || betAmount > maxAmount) {
      setError(true);
      setErrorMsg(`Bet amount should be between ${minAmount} and ${maxAmount} ${currency}.`);
      return;
    }

    setLoading(true);

    try {
      const paymentResponse = await axios.post("/pawapay/webpawapay", {
        betAmount,
        phone: details.phone,
        boxNumber: selectedBox,
        currency: currencyOperator,
        correspondent: selectedOperator,
        countrycode,
        platform,
        serviceCode,
        countryName,
      });

      if (paymentResponse.status === 200) {
        const sessionId = paymentResponse.data.sessionId;

        Swal.fire(
          "SUCCESS!",
          `Your game request has been received. A payment prompt will be sent to your phone shortly. Ticket number: ${sessionId}`,
          "success"
        );
      } else {
        throw new Error("Payment failed. Please try again later.");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      Swal.fire("Error", "An error occurred while processing the payment. Please try again later.", "error");
      setLoading(false);
    }
  };




  

  const handleBoxClick = (boxNumber) => {
    setSelectedBox(boxNumber === selectedBox ? null : boxNumber);
  };

  const boxColors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700", "#9370DB", "#FF69B4"];
  const gridStyles = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
    gap: "10px",
    marginTop: "20px",
    marginBottom: "20px",
  };

  const boxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    cursor: "pointer",
  };

  const boxNumberStyle = {
    fontSize: "1.5em",
    color: "white",
    fontWeight: "bold",
    textShadow: "1px 1px 2px rgba(0,0,0,0.7)",
  };

  return (
    <div className="col-12 col-md-5 mb-5 mt-5">
      <div className="book-cover-holder">
        <h1 className="headline mb-3">Pick a box</h1>
        <div className="subheadline mb-4">Win up to {winuptoAmount} {currency}</div>
        {error && (
          <div className="alert alert-danger mt-1 mb-1" role="alert" style={{ textAlign: "center" }}>
            {errorMsg}
          </div>
        )}
        {loading ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
            <LoadingSpinner />
            <h3>Processing payment...</h3>
            <h3 className="subheadline mb-4">STK PUSH SENT TO YOUR PHONE</h3>
            <h3 className="subheadline mb-4 text-primary" style={{ fontWeight: "bold" }}>{details.phone}</h3>
            <h3 className="subheadline mb-4">Enter PIN to confirm payment</h3>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="phoneInput" className="form-label">Phone Number</label>
              <input type="tel" className="form-control" id="phoneInput" required autoFocus name="phone" onChange={handleChange} value={details.phone} />
            </div>
            <div className="mb-3">
              <label htmlFor="amountInput" className="form-label">Amount</label>
              <input type="number" className="form-control" id="amountInput" required name="amount" onChange={handleChange} value={details.amount} />
            </div>
            <div className="mb-3" style={{ textAlign: "center" }}>
              <label htmlFor="operatorInput" className="form-label">Choose Your Mobile Money Operator</label>
            </div>
            <div className="mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
              {operators.map((operator) => (
                <Operator key={operator.id} logo={operator.logo} name={operator.name} onClick={() => handleOperatorSelect(operator.id)} selected={selectedOperator === operatorValues[operator.id]} />
              ))}
            </div>
            <label htmlFor="boxnumberInput" className="form-label">Choose Your Lucky Box</label>
            <div style={gridStyles}>
              {[1, 2, 3, 4, 5, 6].map((boxNumber, index) => (
                <div key={boxNumber} className="box" style={{ ...boxStyle, backgroundColor: boxColors[index], boxShadow: selectedBox === boxNumber ? "0px 0px 5px 3px #000" : "none" }} onClick={() => handleBoxClick(boxNumber)}>
                  <span style={boxNumberStyle}>Box {boxNumber}</span>
                </div>
              ))}
            </div>
            {error && (
              <div className="alert alert-danger mt-1 mb-1" role="alert" style={{ textAlign: "center" }}>
                {errorMsg}
              </div>
            )}
            <div className="col-12 col-md-auto mt-3">
              <button type="submit" className="btn btn-primary w-100">PLAY {details.amount} {currency}</button>
            </div>
          </form>
        )}
      </div>
      <div id="rewards-grid"></div>
    </div>
  );
}

export default PaymentForm;


// import React, { useState, useEffect, useCallback } from "react";
// import ReactDOM from 'react-dom/client';
// import LoadingSpinner from "./LoadingSpinner";
// import Swal from "sweetalert2";
// import RewardsGrid from "./RewardsGrid";
// import axios from "axios";
// import Operator from "./Operator";
// import * as Ably from "ably";

// function PaymentForm({
//   currency,
//   currencyOperator,
//   countrycode,
//   minAmount,
//   maxAmount,
//   validPrefixes,
//   operators,
//   operatorValues,
//   winuptoAmount,
//   platform,
//   serviceCode,
//   countryName,
// }) {
//   const [details, setDetails] = useState({
//     phone: "",
//     amount: "",
//   });
//   const [error, setError] = useState(false);
//   const [errorMsg, setErrorMsg] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [selectedBox, setSelectedBox] = useState(null);
//   const [selectedOperator, setSelectedOperator] = useState(null);
//   const [currentSessionId, setCurrentSessionId] = useState(null);

//   const handleAblyMessage = useCallback((message) => {
//     console.log("Received payment status update:", message.data);
//     const { status, depositedAmount, depositId, phoneNumber } = message.data;

//     if (status === "COMPLETED") {
//       setLoading(false);
//       Swal.fire({
//         title: "Payment Completed",
//         text: `Your payment of ${depositedAmount} ${currency} was successful!`,
//         icon: "success",
//         confirmButtonText: "Play Game",
//       }).then((result) => {
//         if (result.isConfirmed) {
//           playGame(depositedAmount, selectedBox, phoneNumber, depositId);
//         }
//       });
//     } else if (status === "FAILED") {
//       setLoading(false);
//       Swal.fire("Payment Failed", "Your payment failed. Please try again.", "error");
//     }
//   }, [currency, selectedBox]);

//   useEffect(() => {
//     let ably;
//     let channel;

//     const setupAbly = async () => {
//       try {
//         ably = new Ably.Realtime({ key: process.env.REACT_APP_ABLY_API_KEY });
//         await ably.connection.once('connected');
//         console.log('Successfully connected to Ably');
//         channel = ably.channels.get("stk-updates");
//         channel.subscribe("deposit-status", handleAblyMessage);
//       } catch (error) {
//         console.error('Error connecting to Ably:', error);
//         Swal.fire("Connection Error", "Failed to establish real-time connection. Please refresh the page.", "error");
//       }
//     };

//     setupAbly();

//     return () => {
//       if (channel) channel.unsubscribe();
//       if (ably) ably.close();
//     };
//   }, [handleAblyMessage]);

//   const handleChange = (e) => {
//     setDetails({ ...details, [e.target.name]: e.target.value });
//   };

//   const handleOperatorSelect = (operatorName) => {
//     setSelectedOperator(operatorValues[operatorName]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const betAmount = parseFloat(details.amount);

//     setError(false);
//     setErrorMsg("");

//     if (!details.phone || isNaN(betAmount) || selectedBox === null || !selectedOperator) {
//       setError(true);
//       setErrorMsg("Please complete all fields correctly.");
//       return;
//     }

//     const enteredPrefix = details.phone.substring(0, 2);
//     if (!validPrefixes.includes(enteredPrefix)) {
//       setError(true);
//       setErrorMsg(`Phone number should start with one of the valid network codes: ${validPrefixes.join(", ")}`);
//       return;
//     }

//     if (betAmount < minAmount || betAmount > maxAmount) {
//       setError(true);
//       setErrorMsg(`Bet amount should be between ${minAmount} and ${maxAmount} ${currency}.`);
//       return;
//     }

//     setLoading(true);

//     try {
//       const paymentResponse = await axios.post("/pawapay/webpawapay", {
//         betAmount,
//         phone: details.phone,
//         boxNumber: selectedBox,
//         currency: currencyOperator,
//         correspondent: selectedOperator,
//         countrycode,
//         platform,
//         serviceCode,
//         countryName,
//       });

//       if (paymentResponse.status === 200) {
//         const sessionId = paymentResponse.data.sessionId;
//         setCurrentSessionId(sessionId);

//         Swal.fire(
//           "SUCCESS!",
//           `Your game request has been received. A payment prompt will be sent to your phone shortly. Ticket number: ${sessionId}`,
//           "success"
//         );
//       } else {
//         throw new Error("Payment failed. Please try again later.");
//       }
//     } catch (error) {
//       console.error("Error processing payment:", error);
//       Swal.fire("Error", "An error occurred while processing the payment. Please try again later.", "error");
//       setLoading(false);
//     }
//   };

//   const playGame = useCallback((amount, boxNumber, phone, sessionId) => {
//     const formattedPhone = `233${phone.substring(phone.length - 9)}`;
//     axios
//       .post("/webgame/play-lucky-box-pawapay", {
//         betAmount: amount,
//         userBox: boxNumber,
//         phoneNumber: formattedPhone,
//         sessionId,
//         currencyOperator,
//         correspondent: selectedOperator,
//         currency,
//         countrycode,
//       })
//       .then((gameResult) => {
//         const rewards = gameResult.data.details.LostfakeRewards;
//         Swal.fire({
//           title: gameResult.data.result,
//           html: `
//             <div>You selected Box ${gameResult.data.userBox}</div>
//             <div>Your prize: ${gameResult.data.prize}</div>
//             <div id="rewards-grid"></div>
//           `,
//           icon: "success",
//           didOpen: () => {
//             const rewardsContainer = Swal.getHtmlContainer().querySelector('#rewards-grid');
//             if (rewardsContainer) {
//               ReactDOM.createRoot(rewardsContainer).render(<RewardsGrid rewards={rewards} userBox={gameResult.data.userBox} />);
//             }
//           }
//         });
//       })
//       .catch((gameError) => {
//         console.error("Error playing the game:", gameError);
//         Swal.fire("Error", "There was an issue playing the game. Please try again.", "error");
//       });
//   }, [currency, currencyOperator, countrycode, selectedOperator]);

//   const handleBoxClick = (boxNumber) => {
//     setSelectedBox(boxNumber === selectedBox ? null : boxNumber);
//   };

//   const boxColors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700", "#9370DB", "#FF69B4"];
//   const gridStyles = {
//     display: "grid",
//     gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
//     gap: "10px",
//     marginTop: "20px",
//     marginBottom: "20px",
//   };

//   const boxStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     height: "100px",
//     border: "1px solid #ccc",
//     borderRadius: "5px",
//     cursor: "pointer",
//   };

//   const boxNumberStyle = {
//     fontSize: "1.5em",
//     color: "white",
//     fontWeight: "bold",
//     textShadow: "1px 1px 2px rgba(0,0,0,0.7)",
//   };

//   return (
//     <div className="col-12 col-md-5 mb-5 mt-5">
//       <div className="book-cover-holder">
//         <h1 className="headline mb-3">Pick a box</h1>
//         <div className="subheadline mb-4">Win up to {winuptoAmount} {currency}</div>
//         {error && (
//           <div className="alert alert-danger mt-1 mb-1" role="alert" style={{ textAlign: "center" }}>
//             {errorMsg}
//           </div>
//         )}
//         {loading ? (
//           <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
//             <LoadingSpinner />
//             <h3>Processing payment...</h3>
//             <h3 className="subheadline mb-4">STK PUSH SENT TO YOUR PHONE</h3>
//             <h3 className="subheadline mb-4 text-primary" style={{ fontWeight: "bold" }}>{details.phone}</h3>
//             <h3 className="subheadline mb-4">Enter PIN to confirm payment</h3>
//           </div>
//         ) : (
//           <form onSubmit={handleSubmit}>
//             <div className="mb-3">
//               <label htmlFor="phoneInput" className="form-label">Phone Number</label>
//               <input type="tel" className="form-control" id="phoneInput" required autoFocus name="phone" onChange={handleChange} value={details.phone} />
//             </div>
//             <div className="mb-3">
//               <label htmlFor="amountInput" className="form-label">Amount</label>
//               <input type="number" className="form-control" id="amountInput" required name="amount" onChange={handleChange} value={details.amount} />
//             </div>
//             <div className="mb-3" style={{ textAlign: "center" }}>
//               <label htmlFor="operatorInput" className="form-label">Choose Your Mobile Money Operator</label>
//             </div>
//             <div className="mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
//               {operators.map((operator) => (
//                 <Operator key={operator.id} logo={operator.logo} name={operator.name} onClick={() => handleOperatorSelect(operator.id)} selected={selectedOperator === operatorValues[operator.id]} />
//               ))}
//             </div>
//             <label htmlFor="boxnumberInput" className="form-label">Choose Your Lucky Box</label>
//             <div style={gridStyles}>
//               {[1, 2, 3, 4, 5, 6].map((boxNumber, index) => (
//                 <div key={boxNumber} className="box" style={{ ...boxStyle, backgroundColor: boxColors[index], boxShadow: selectedBox === boxNumber ? "0px 0px 5px 3px #000" : "none" }} onClick={() => handleBoxClick(boxNumber)}>
//                   <span style={boxNumberStyle}>Box {boxNumber}</span>
//                 </div>
//               ))}
//             </div>
//             {error && (
//               <div className="alert alert-danger mt-1 mb-1" role="alert" style={{ textAlign: "center" }}>
//                 {errorMsg}
//               </div>
//             )}
//             <div className="col-12 col-md-auto mt-3">
//               <button type="submit" className="btn btn-primary w-100">PLAY {details.amount} {currency}</button>
//             </div>
//           </form>
//         )}
//       </div>
//       <div id="rewards-grid"></div>
//     </div>
//   );
// }

// export default PaymentForm;

// import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom/server";
// import LoadingSpinner from "./LoadingSpinner";
// import Swal from "sweetalert2";
// import RewardsGrid from "./RewardsGrid";
// import axios from "axios";
// import Operator from "./Operator";
// import * as Ably from "ably";

// function PaymentForm({
//   currency,
//   currencyOperator,
//   countrycode,
//   minAmount,
//   maxAmount,
//   validPrefixes,
//   operators,
//   operatorValues,
//   winuptoAmount,
//   platform,
//   serviceCode,
//   countryName,
// }) {
//   const [details, setDetails] = useState({
//     phone: "",
//     amount: "",
//   });
//   const [error, setError] = useState(false);
//   const [errorMsg, setErrorMsg] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [selectedBox, setSelectedBox] = useState(null);
//   const [selectedOperator, setSelectedOperator] = useState(null);

//   useEffect(() => {
//     const ably = new Ably.Realtime({ key: process.env.REACT_APP_ABLY_API_KEY });
//     const channel = ably.channels.get("stk-updates");

//     // Subscribe to payment status updates from Ably
//     channel.subscribe("deposit-status", (message) => {
//       console.log("Received payment status update:", message.data);
//       const { status, depositedAmount, sessionId } = message.data;

//       if (status === "COMPLETED") {
//         Swal.fire("Payment Completed", `Your payment of ${depositedAmount} was successful!`, "success");
//         setLoading(false);
//         checkAndPlayGame(sessionId);
//       } else if (status === "FAILED") {
//         Swal.fire("Payment Failed", "Your payment failed. Please try again.", "error");
//         setLoading(false);
//       }
//     });

//     return () => {
//       channel.unsubscribe();
//       ably.close();
//     };
//   }, []);

//   const handleChange = (e) => {
//     setDetails({ ...details, [e.target.name]: e.target.value });
//   };

//   const handleOperatorSelect = (operatorName) => {
//     setSelectedOperator(operatorValues[operatorName]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const betAmount = parseFloat(details.amount);

//     // Reset previous errors
//     setError(false);
//     setErrorMsg("");

//     // Validate input
//     if (!details.phone || isNaN(betAmount) || selectedBox === null || !selectedOperator) {
//       setError(true);
//       setErrorMsg("Please complete all fields correctly.");
//       return;
//     }

//     const enteredPrefix = details.phone.substring(0, 2);
//     if (!validPrefixes.includes(enteredPrefix)) {
//       setError(true);
//       setErrorMsg(`Phone number should start with one of the valid network codes: ${validPrefixes.join(", ")}`);
//       return;
//     }

//     if (betAmount < minAmount) {
//       setError(true);
//       setErrorMsg(`Bet amount should be at least ${minAmount} ${currency}.`);
//       return;
//     }

//     setLoading(true);

//     try {
//       const paymentResponse = await axios.post("/pawapay/webpawapay", {
//         betAmount,
//         phone: details.phone,
//         boxNumber: selectedBox,
//         currency: currencyOperator,
//         correspondent: selectedOperator,
//         countrycode,
//         platform,
//         serviceCode,
//         countryName,
//       });

//       if (paymentResponse.status === 200) {
//         const sessionId = paymentResponse.data.sessionId;

//         Swal.fire(
//           "SUCCESS!",
//           `Your game request has been received. A payment prompt will be sent to your phone shortly. Ticket number: ${sessionId}`,
//           "success"
//         );
//       } else {
//         throw new Error("Payment failed. Please try again later.");
//       }
//     } catch (error) {
//       console.error("Error processing payment:", error);
//       Swal.fire("Error", "An error occurred while processing the payment. Please try again later.", "error");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const checkAndPlayGame = (sessionId) => {
//     const playedInSession = sessionStorage.getItem(sessionId);
//     if (!playedInSession) {
//       playGame(details.amount, selectedBox, details.phone, sessionId);
//       sessionStorage.setItem(sessionId, "true");
//     }
//   };

//   const playGame = (amount, boxNumber, phone, sessionId) => {
//     const formattedPhone = `233${phone.substring(phone.length - 9)}`;
//     axios
//       .post("/webgame/play-lucky-box-pawapay", {
//         betAmount: amount,
//         userBox: boxNumber,
//         phoneNumber: formattedPhone,
//         sessionId,
//         currencyOperator,
//         correspondent: selectedOperator,
//         currency,
//         countrycode,
//       })
//       .then((gameResult) => {
//         const rewards = gameResult.data.details.LostfakeRewards;
//         const rewardsGridHTML = ReactDOM.renderToString(<RewardsGrid rewards={rewards} userBox={gameResult.data.userBox} />);
//         Swal.fire({
//           title: gameResult.data.result,
//           html: `
//             <div>You selected Box ${gameResult.data.userBox}</div>
//             <div>Your prize: ${gameResult.data.prize}</div>
//             ${rewardsGridHTML}
//           `,
//           icon: "success",
//         });
//       })
//       .catch((gameError) => {
//         console.error("Error playing the game:", gameError);
//         Swal.fire("Error", "There was an issue playing the game. Please try again.", "error");
//       });
//   };

//   const handleBoxClick = (boxNumber) => {
//     setSelectedBox(boxNumber === selectedBox ? null : boxNumber);
//   };

//   const boxColors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700", "#9370DB"];
//   const gridStyles = {
//     display: "grid",
//     gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
//     gap: "10px",
//     marginTop: "20px",
//     marginBottom: "20px",
//   };

//   const boxStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     height: "100px",
//     border: "1px solid #ccc",
//     borderRadius: "5px",
//     cursor: "pointer",
//   };

//   const boxNumberStyle = {
//     fontSize: "1.5em",
//     color: "white",
//     fontWeight: "bold",
//   };

//   return (
//     <div className="col-12 col-md-5 mb-5 mt-5">
//       <div className="book-cover-holder">
//         <h1 className="headline mb-3">Pick a box</h1>
//         <div className="subheadline mb-4">Win up to {winuptoAmount} {currency}</div>
//         {error && (
//           <div className="alert alert-danger mt-1 mb-1" role="alert" style={{ textAlign: "center" }}>
//             {errorMsg}
//           </div>
//         )}
//         {loading ? (
//           <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
//             <LoadingSpinner />
//             <h3>Processing payment...</h3>
//             <h3 className="subheadline mb-4">STK PUSH SENT TO YOUR PHONE</h3>
//             <h3 className="subheadline mb-4 text-primary" style={{ fontWeight: "bold" }}>{details.phone}</h3>
//             <h3 className="subheadline mb-4">Enter PIN to confirm payment</h3>
//           </div>
//         ) : (
//           <form onSubmit={handleSubmit}>
//             <div className="mb-3">
//               <label htmlFor="phoneInput" className="form-label">Phone Number</label>
//               <input type="tel" className="form-control" id="phoneInput" required autoFocus name="phone" onChange={handleChange} value={details.phone} />
//             </div>
//             <div className="mb-3">
//               <label htmlFor="amountInput" className="form-label">Amount</label>
//               <input type="number" className="form-control" id="amountInput" required name="amount" onChange={handleChange} value={details.amount} />
//             </div>
//             <div className="mb-3" style={{ textAlign: "center" }}>
//               <label htmlFor="operatorInput" className="form-label">Choose Your Mobile Money Operator</label>
//             </div>
//             <div className="mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
//               {operators.map((operator) => (
//                 <Operator key={operator.id} logo={operator.logo} name={operator.name} onClick={() => handleOperatorSelect(operator.id)} selected={selectedOperator === operatorValues[operator.id]} />
//               ))}
//             </div>
//             <label htmlFor="boxnumberInput" className="form-label">Choose Your Lucky Box</label>
//             <div style={gridStyles}>
//               {[1, 2, 3, 4, 5, 6].map((boxNumber, index) => (
//                 <div key={boxNumber} className="box" style={{ ...boxStyle, backgroundColor: boxColors[index % boxColors.length], boxShadow: selectedBox === boxNumber ? "0px 0px 5px 3px #000" : "none" }} onClick={() => handleBoxClick(boxNumber)}>
//                   <span style={boxNumberStyle}>Box {boxNumber}</span>
//                 </div>
//               ))}
//             </div>
//             {error && (
//               <div className="alert alert-danger mt-1 mb-1" role="alert" style={{ textAlign: "center" }}>
//                 {errorMsg}
//               </div>
//             )}
//             <div className="col-12 col-md-auto mt-3">
//               <button type="submit" className="btn btn-primary w-100">PLAY {details.amount} {currency}</button>
//             </div>
//           </form>
//         )}
//       </div>
//     </div>
//   );
// }

// export default PaymentForm;



// import React, { useState, useEffect, useCallback } from "react";
// import ReactDOM from 'react-dom/server';
// import LoadingSpinner from "./LoadingSpinner";
// import Swal from "sweetalert2";
// import RewardsGrid from "./RewardsGrid";
// import axios from "axios";
// import Operator from "./Operator";
// import * as Ably from 'ably';

// function PaymentForm({
//   currency,
//   currencyOperator,
//   countrycode,
//   minAmount,
//   maxAmount,
//   validPrefixes,
//   operators,
//   operatorValues,
//   winuptoAmount,
//   platform,
//   serviceCode,
//   countryName
// }) {
//   const [details, setDetails] = useState({ phone: "", amount: "" });
//   const [error, setError] = useState(false);
//   const [errorMsg, setErrorMsg] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [selectedBox, setSelectedBox] = useState(null);
//   const [selectedOperator, setSelectedOperator] = useState(null);

//   // Memoize checkAndPlayGame with useCallback
//   const checkAndPlayGame = useCallback((sessionId) => {
//     console.log(`Initiating game logic for sessionId: ${sessionId}`);
//     playGame(details.amount, selectedBox, details.phone, sessionId);
//   }, [details.amount, selectedBox, details.phone]);

//   // Ably Realtime subscription
//   useEffect(() => {
//     const ably = new Ably.Realtime({ key: process.env.REACT_APP_ABLY_API_KEY });
//     const channel = ably.channels.get('stk-updates');

//     channel.subscribe('deposit-status', (message) => {
//       console.log('Received deposit status update:', message.data);
//       const { status, depositedAmount, sessionId } = message.data;

//       // Log for debugging
//       console.log(`Payment Status: ${status} for sessionId: ${sessionId}`);

//       if (status === 'COMPLETED') {
//         console.log("Payment completed, triggering game logic...");

//         // Trigger game logic directly, bypassing Swal
//         checkAndPlayGame(sessionId);

//         // Optionally, you can still show Swal after calling checkAndPlayGame
//         Swal.fire(
//           'Payment Completed',
//           `Your payment of ${depositedAmount} was successful!`,
//           'success'
//         );
//       } else if (status === 'FAILED') {
//         Swal.fire(
//           'Payment Failed',
//           `Your payment failed.`,
//           'error'
//         );
//         setLoading(false);
//       }
//     });

//     return () => {
//       channel.unsubscribe();
//       ably.close();
//     };
//   }, [checkAndPlayGame]);

//   const handleChange = (e) => {
//     setDetails({ ...details, [e.target.name]: e.target.value });
//   };

//   const handleOperatorSelect = (operatorName) => {
//     setSelectedOperator(operatorValues[operatorName]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const betAmount = parseFloat(details.amount);
//     setError(false);
//     setErrorMsg("");

//     if (!details.phone || isNaN(betAmount) || selectedBox === null || !selectedOperator) {
//       setError(true);
//       setErrorMsg("Please complete all fields correctly.");
//       return;
//     }

//     const enteredPrefix = details.phone.substring(0, 2);
//     if (!validPrefixes.includes(enteredPrefix)) {
//       setError(true);
//       setErrorMsg(`Phone number should start with one of the valid network codes: ${validPrefixes.join(", ")}`);
//       return;
//     }

//     if (betAmount < minAmount) {
//       setError(true);
//       setErrorMsg(`Bet amount should be at least ${minAmount} ${currency}.`);
//       return;
//     }

//     setLoading(true);

//     try {
//       const paymentResponse = await axios.post("/pawapay/webpawapay", {
//         betAmount,
//         phone: details.phone,
//         boxNumber: selectedBox,
//         currency: currencyOperator,
//         correspondent: selectedOperator,
//         countrycode,
//         platform,
//         serviceCode,
//         countryName
//       });

//       if (paymentResponse.status === 200) {
//         const sessionId = paymentResponse.data.sessionId;

//         Swal.fire(
//           "SUCCESS!",
//           `Your game request has been received. A payment prompt will be sent to your phone shortly. Ticket number: ${sessionId}`,
//           "success"
//         );
//       } else {
//         setLoading(false);
//         setError(true);
//         setErrorMsg("Payment failed. Please try again later.");
//       }
//     } catch (error) {
//       console.error("Error processing payment:", error);
//       setLoading(false);
//       setError(true);
//       setErrorMsg("An error occurred while processing the payment. Please try again later.");
//     }
//   };

//   const playGame = (amount, boxNumber, phone, sessionId) => {
//     console.log(`Playing game for sessionId: ${sessionId}`);
//     const formattedPhone = `233${phone.substring(phone.length - 9)}`;
//     axios
//       .post("/webgame/play-lucky-box-pawapay", {
//         betAmount: amount,
//         userBox: boxNumber,
//         phoneNumber: formattedPhone,
//         sessionId: sessionId,
//         currencyOperator: currencyOperator,
//         correspondent: selectedOperator,
//         currency: currency,
//         countrycode: countrycode
//       })
//       .then((gameResult) => {
//         console.log("Game result received:", gameResult.data);
//         const rewards = gameResult.data.details.LostfakeRewards;
//         const userBox = gameResult.data.userBox;
//         const selectedBoxNumber = gameResult.data.userBox;

//         const rewardsGridHTML = ReactDOM.renderToString(
//           <RewardsGrid rewards={rewards} userBox={userBox} />
//         );

//         Swal.fire({
//           title: gameResult.data.result,
//           html: `
//             <div>You selected Box ${selectedBoxNumber}</div>
//             <div>Your prize: ${gameResult.data.prize}</div>
//             ${rewardsGridHTML}
//           `,
//           icon: "success",
//           confirmButtonText: "Play Again",
//         });

//         sessionStorage.setItem(`game_${sessionId}`, 'true');
//       })
//       .catch((gameError) => {
//         console.error("Error playing the game:", gameError);
//       });
//   };

//   const handleBoxClick = (boxNumber) => {
//     setSelectedBox(boxNumber === selectedBox ? null : boxNumber);
//   };

//   const boxColors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700", "#9370DB"];
//   const gridStyles = {
//     display: "grid",
//     gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
//     gap: "10px",
//     marginTop: "20px",
//     marginBottom: "20px",
//   };

//   const boxStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     height: "100px",
//     border: "1px solid #ccc",
//     borderRadius: "5px",
//     cursor: "pointer",
//   };

//   const boxNumberStyle = {
//     fontSize: "1.5em",
//     color: "white",
//     fontWeight: "bold",
//   };

//   return (
//     <div className="col-12 col-md-5 mb-5 mt-5">
//       <div className="book-cover-holder">
//         <h1 className="headline mb-3">Pick a box</h1>
//         <div className="subheadline mb-4">Win up to {winuptoAmount} {currency}</div>
//         {error && (
//           <div
//             className="alert alert-danger mt-1 mb-1"
//             role="alert"
//             style={{ textAlign: "center" }}
//           >
//             {errorMsg}
//           </div>
//         )}
//         {loading ? (
//           <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
//             <LoadingSpinner />
//             <h3>Processing payment...</h3>
//             <h3 className="subheadline mb-4">STK PUSH SENT TO YOUR PHONE</h3>
//             <h3 className="subheadline mb-4 text-primary" style={{ fontWeight: "bold" }}>
//               {details.phone}
//             </h3>
//             <h3 className="subheadline mb-4">Enter PIN to confirm payment</h3>
//           </div>
//         ) : (
//           <form onSubmit={handleSubmit}>
//             <div className="mb-3">
//               <label htmlFor="phoneInput" className="form-label">
//                 Phone Number
//               </label>
//               <input
//                 type="tel"
//                 className="form-control"
//                 id="phoneInput"
//                 required
//                 name="phone"
//                 onChange={handleChange}
//                 placeholder={`e.g., ${validPrefixes
//                   .map((prefix) => prefix + "xx")
//                   .join(" or ")}`}
//                 value={details.phone}
//               />
//             </div>
//             <div className="mb-3">
//               <label htmlFor="amountInput" className="form-label">
//                 Amount
//               </label>
//               <input
//                 type="number"
//                 className="form-control"
//                 id="amountInput"
//                 required
//                 name="amount"
//                 placeholder={`e.g., ${currency} ${minAmount} - ${currency} ${maxAmount}`}
//                 onChange={handleChange}
//                 value={details.amount}
//               />
//             </div>

//             <div style={{ textAlign: "center", marginTop: "20px" }}>
//               <label htmlFor="operatorInput" className="form-label">
//                 Choose Your Mobile Money Operator
//               </label>
//             </div>
//             <div
//               className="mb-3"
//               style={{ display: "flex", justifyContent: "space-between" }}
//             >
//               {operators.map((operator) => (
//                 <Operator
//                   key={operator.id}
//                   logo={operator.logo}
//                   name={operator.name}
//                   onClick={() => handleOperatorSelect(operator.id)}
//                   selected={selectedOperator === operatorValues[operator.id]}
//                 />
//               ))}
//             </div>

//             <label htmlFor="boxnumberInput" className="form-label">
//               Choose Your Lucky Box
//             </label>
//             <div style={gridStyles}>
//               {[1, 2, 3, 4, 5, 6].map((boxNumber, index) => (
//                 <div
//                   key={boxNumber}
//                   className="box"
//                   style={{
//                     ...boxStyle,
//                     backgroundColor: boxColors[index % boxColors.length],
//                     boxShadow: selectedBox === boxNumber
//                       ? "0px 0px 5px 3px #000"
//                       : "none",
//                   }}
//                   onClick={() => handleBoxClick(boxNumber)}
//                 >
//                   <span style={boxNumberStyle}>Box {boxNumber}</span>
//                 </div>
//               ))}
//             </div>

//             {error && (
//               <div
//                 className="alert alert-danger mt-1 mb-1"
//                 role="alert"
//                 style={{ textAlign: "center" }}
//               >
//                 {errorMsg}
//               </div>
//             )}
//             <div className="col-12 col-md-auto mt-3">
//               <button type="submit" className="btn btn-primary w-100">
//                 PLAY {details.amount} {currency}
//               </button>
//             </div>
//           </form>
//         )}
//       </div>
//     </div>
//   );
// }

// export default PaymentForm;


// import React, { useState, useEffect, useCallback } from "react";
// import ReactDOM from 'react-dom/server';
// import LoadingSpinner from "./LoadingSpinner";
// import Swal from "sweetalert2";
// import RewardsGrid from "./RewardsGrid";
// import axios from "axios";
// import Operator from "./Operator";
// import * as Ably from 'ably';

// function PaymentForm({
//   currency,
//   currencyOperator,
//   countrycode,
//   minAmount,
//   maxAmount,
//   validPrefixes,
//   operators,
//   operatorValues,
//   winuptoAmount,
//   platform,
//   serviceCode,
//   countryName
// }) {
//   const [details, setDetails] = useState({ phone: "", amount: "" });
//   const [error, setError] = useState(false);
//   const [errorMsg, setErrorMsg] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [selectedBox, setSelectedBox] = useState(null);
//   const [selectedOperator, setSelectedOperator] = useState(null);

//   // Memoize checkAndPlayGame with useCallback
//   const checkAndPlayGame = useCallback((sessionId) => {
//     console.log(`Checking if the game was already played for sessionId: ${sessionId}`);
//     const playedInSession = sessionStorage.getItem(`game_${sessionId}`);
//     if (!playedInSession) {
//       console.log("Game not yet played. Initiating playGame...");
//       playGame(details.amount, selectedBox, details.phone, sessionId);
//       sessionStorage.setItem(`game_${sessionId}`, 'true');
//     } else {
//       console.log("Game already played for this session.");
//     }
//   }, [details.amount, selectedBox, details.phone]);

//   // Ably Realtime subscription
//   useEffect(() => {
//     const ably = new Ably.Realtime({ key: process.env.REACT_APP_ABLY_API_KEY });
//     const channel = ably.channels.get('stk-updates');

//     channel.subscribe('deposit-status', (message) => {
//       console.log('Received deposit status update:', message.data);
//       const { status, depositedAmount, failureReason, sessionId } = message.data;

//       const paymentProcessed = sessionStorage.getItem(`payment_${sessionId}`);

//       if (status === 'COMPLETED' && !paymentProcessed) {
//         Swal.fire(
//           'Payment Completed',
//           `Your payment of ${depositedAmount} was successful!`,
//           'success'
//         ).then(() => {
//           console.log("Payment success modal closed. Proceeding to check and play the game...");
//           sessionStorage.setItem(`payment_${sessionId}`, 'true');
//           checkAndPlayGame(sessionId);  // Call the game check after modal confirmation
//         });
//       } else if (status === 'FAILED' && !paymentProcessed) {
//         Swal.fire(
//           'Payment Failed',
//           `Your payment failed. Reason: ${failureReason}`,
//           'error'
//         );
//         setLoading(false);
//         sessionStorage.setItem(`payment_${sessionId}`, 'true');
//       }
//     });

//     return () => {
//       channel.unsubscribe();
//       ably.close();
//     };
//   }, [details.phone, setLoading, checkAndPlayGame]);

//   const handleChange = (e) => {
//     setDetails({ ...details, [e.target.name]: e.target.value });
//   };

//   const handleOperatorSelect = (operatorName) => {
//     setSelectedOperator(operatorValues[operatorName]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const betAmount = parseFloat(details.amount);
//     setError(false);
//     setErrorMsg("");

//     if (!details.phone || isNaN(betAmount) || selectedBox === null || !selectedOperator) {
//       setError(true);
//       setErrorMsg("Please complete all fields correctly.");
//       return;
//     }

//     const enteredPrefix = details.phone.substring(0, 2);
//     if (!validPrefixes.includes(enteredPrefix)) {
//       setError(true);
//       setErrorMsg(`Phone number should start with one of the valid network codes: ${validPrefixes.join(", ")}`);
//       return;
//     }

//     if (betAmount < minAmount) {
//       setError(true);
//       setErrorMsg(`Bet amount should be at least ${minAmount} ${currency}.`);
//       return;
//     }

//     setLoading(true);

//     try {
//       const paymentResponse = await axios.post("/pawapay/webpawapay", {
//         betAmount,
//         phone: details.phone,
//         boxNumber: selectedBox,
//         currency: currencyOperator,
//         correspondent: selectedOperator,
//         countrycode,
//         platform,
//         serviceCode,
//         countryName
//       });

//       if (paymentResponse.status === 200) {
//         const sessionId = paymentResponse.data.sessionId;

//         Swal.fire(
//           "SUCCESS!",
//           `Your game request has been received. A payment prompt will be sent to your phone shortly. Ticket number: ${sessionId}`,
//           "success"
//         );
//       } else {
//         setLoading(false);
//         setError(true);
//         setErrorMsg("Payment failed. Please try again later.");
//       }
//     } catch (error) {
//       console.error("Error processing payment:", error);
//       setLoading(false);
//       setError(true);
//       setErrorMsg("An error occurred while processing the payment. Please try again later.");
//     }
//   };

//   const playGame = (amount, boxNumber, phone, sessionId) => {
//     console.log(`Playing game for sessionId: ${sessionId}`);
//     const formattedPhone = `233${phone.substring(phone.length - 9)}`;
//     axios
//       .post("/webgame/play-lucky-box-pawapay", {
//         betAmount: amount,
//         userBox: boxNumber,
//         phoneNumber: formattedPhone,
//         sessionId: sessionId,
//         currencyOperator: currencyOperator,
//         correspondent: selectedOperator,
//         currency: currency,
//         countrycode: countrycode
//       })
//       .then((gameResult) => {
//         console.log("Game result received:", gameResult.data);
//         const rewards = gameResult.data.details.LostfakeRewards;
//         const userBox = gameResult.data.userBox;
//         const selectedBoxNumber = gameResult.data.userBox;

//         const rewardsGridHTML = ReactDOM.renderToString(
//           <RewardsGrid rewards={rewards} userBox={userBox} />
//         );

//         Swal.fire({
//           title: gameResult.data.result,
//           html: `
//             <div>You selected Box ${selectedBoxNumber}</div>
//             <div>Your prize: ${gameResult.data.prize}</div>
//             ${rewardsGridHTML}
//           `,
//           icon: "success",
//           confirmButtonText: "Play Again",
//         });

//         sessionStorage.setItem(`game_${sessionId}`, 'true');
//       })
//       .catch((gameError) => {
//         console.error("Error playing the game:", gameError);
//       });
//   };

//   const handleBoxClick = (boxNumber) => {
//     setSelectedBox(boxNumber === selectedBox ? null : boxNumber);
//   };

//   const boxColors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700", "#9370DB"];
//   const gridStyles = {
//     display: "grid",
//     gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
//     gap: "10px",
//     marginTop: "20px",
//     marginBottom: "20px",
//   };

//   const boxStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     height: "100px",
//     border: "1px solid #ccc",
//     borderRadius: "5px",
//     cursor: "pointer",
//   };

//   const boxNumberStyle = {
//     fontSize: "1.5em",
//     color: "white",
//     fontWeight: "bold",
//   };

//   return (
//     <div className="col-12 col-md-5 mb-5 mt-5">
//       <div className="book-cover-holder">
//         <h1 className="headline mb-3">Pick a box</h1>
//         <div className="subheadline mb-4">Win up to {winuptoAmount} {currency}</div>
//         {error && (
//           <div
//             className="alert alert-danger mt-1 mb-1"
//             role="alert"
//             style={{ textAlign: "center" }}
//           >
//             {errorMsg}
//           </div>
//         )}
//         {loading ? (
//           <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
//             <LoadingSpinner />
//             <h3>Processing payment...</h3>
//             <h3 className="subheadline mb-4">STK PUSH SENT TO YOUR PHONE</h3>
//             <h3 className="subheadline mb-4 text-primary" style={{ fontWeight: "bold" }}>
//               {details.phone}
//             </h3>
//             <h3 className="subheadline mb-4">Enter PIN to confirm payment</h3>
//           </div>
//         ) : (
//           <form onSubmit={handleSubmit}>
//             <div className="mb-3">
//               <label htmlFor="phoneInput" className="form-label">
//                 Phone Number
//               </label>
//               <input
//                 type="tel"
//                 className="form-control"
//                 id="phoneInput"
//                 required
//                 name="phone"
//                 onChange={handleChange}
//                 placeholder={`e.g., ${validPrefixes
//                   .map((prefix) => prefix + "xx")
//                   .join(" or ")}`}
//                 value={details.phone}
//               />
//             </div>
//             <div className="mb-3">
//               <label htmlFor="amountInput" className="form-label">
//                 Amount
//               </label>
//               <input
//                 type="number"
//                 className="form-control"
//                 id="amountInput"
//                 required
//                 name="amount"
//                 placeholder={`e.g., ${currency} ${minAmount} - ${currency} ${maxAmount}`}
//                 onChange={handleChange}
//                 value={details.amount}
//               />
//             </div>

//             <div style={{ textAlign: "center", marginTop: "20px" }}>
//               <label htmlFor="operatorInput" className="form-label">
//                 Choose Your Mobile Money Operator
//               </label>
//             </div>
//             <div
//               className="mb-3"
//               style={{ display: "flex", justifyContent: "space-between" }}
//             >
//               {operators.map((operator) => (
//                 <Operator
//                   key={operator.id}
//                   logo={operator.logo}
//                   name={operator.name}
//                   onClick={() => handleOperatorSelect(operator.id)}
//                   selected={selectedOperator === operatorValues[operator.id]}
//                 />
//               ))}
//             </div>

//             <label htmlFor="boxnumberInput" className="form-label">
//               Choose Your Lucky Box
//             </label>
//             <div style={gridStyles}>
//               {[1, 2, 3, 4, 5, 6].map((boxNumber, index) => (
//                 <div
//                   key={boxNumber}
//                   className="box"
//                   style={{
//                     ...boxStyle,
//                     backgroundColor: boxColors[index % boxColors.length],
//                     boxShadow: selectedBox === boxNumber
//                       ? "0px 0px 5px 3px #000"
//                       : "none",
//                   }}
//                   onClick={() => handleBoxClick(boxNumber)}
//                 >
//                   <span style={boxNumberStyle}>Box {boxNumber}</span>
//                 </div>
//               ))}
//             </div>

//             {error && (
//               <div
//                 className="alert alert-danger mt-1 mb-1"
//                 role="alert"
//                 style={{ textAlign: "center" }}
//               >
//                 {errorMsg}
//               </div>
//             )}
//             <div className="col-12 col-md-auto mt-3">
//               <button type="submit" className="btn btn-primary w-100">
//                 PLAY {details.amount} {currency}
//               </button>
//             </div>
//           </form>
//         )}
//       </div>
//     </div>
//   );
// }

// export default PaymentForm;



// import React, { useState, useEffect, useCallback } from "react";
// import ReactDOM from 'react-dom/server';
// import LoadingSpinner from "./LoadingSpinner";
// import Swal from "sweetalert2";
// import RewardsGrid from "./RewardsGrid";
// import axios from "axios";
// import Operator from "./Operator";
// import * as Ably from 'ably';

// function PaymentForm({
//   currency,
//   currencyOperator,
//   countrycode,
//   minAmount,
//   maxAmount,
//   validPrefixes,
//   operators,
//   operatorValues,
//   winuptoAmount,
//   platform,
//   serviceCode,
//   countryName
// }) {
//   const [details, setDetails] = useState({ phone: "", amount: "" });
//   const [error, setError] = useState(false);
//   const [errorMsg, setErrorMsg] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [selectedBox, setSelectedBox] = useState(null);
//   const [selectedOperator, setSelectedOperator] = useState(null);

//   // Memoize checkAndPlayGame with useCallback to avoid triggering unnecessary re-renders
//   const checkAndPlayGame = useCallback((sessionId) => {
//     const playedInSession = sessionStorage.getItem(sessionId);
//     if (!playedInSession) {
//       playGame(details.amount, selectedBox, details.phone, sessionId);
//       sessionStorage.setItem(sessionId, 'true');
//     }
//   }, [details.amount, selectedBox, details.phone]);

//   // Ably Realtime subscription
//   useEffect(() => {
//     const ably = new Ably.Realtime({ key: process.env.REACT_APP_ABLY_API_KEY });
//     const channel = ably.channels.get('stk-updates');

//     channel.subscribe('deposit-status', (message) => {
//       console.log('Received deposit status update:', message.data);
//       const { status, depositedAmount, failureReason, sessionId } = message.data;

//       if (status === 'COMPLETED') {
//         Swal.fire(
//           'Payment Completed',
//           `Your payment of ${depositedAmount} was successful!`,
//           'success'
//         );
//         setLoading(false);
//         setDetails({ phone: details.phone, amount: "" });
//         setSelectedBox(null);

//         // Trigger game logic after payment is successful
//         checkAndPlayGame(sessionId);
//       } else if (status === 'FAILED') {
//         Swal.fire(
//           'Payment Failed',
//           `Your payment failed. Reason: ${failureReason}`,
//           'error'
//         );
//         setLoading(false);
//       }
//     });

//     return () => {
//       channel.unsubscribe();
//       ably.close();
//     };
//   }, [details.phone, setLoading, checkAndPlayGame]);

//   const handleChange = (e) => {
//     setDetails({ ...details, [e.target.name]: e.target.value });
//   };

//   const handleOperatorSelect = (operatorName) => {
//     setSelectedOperator(operatorValues[operatorName]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const betAmount = parseFloat(details.amount);
//     setError(false);
//     setErrorMsg("");

//     if (!details.phone || isNaN(betAmount) || selectedBox === null || !selectedOperator) {
//       setError(true);
//       setErrorMsg("Please complete all fields correctly.");
//       return;
//     }

//     const enteredPrefix = details.phone.substring(0, 2);
//     if (!validPrefixes.includes(enteredPrefix)) {
//       setError(true);
//       setErrorMsg(`Phone number should start with one of the valid network codes: ${validPrefixes.join(", ")}`);
//       return;
//     }

//     if (betAmount < minAmount) {
//       setError(true);
//       setErrorMsg(`Bet amount should be at least ${minAmount} ${currency}.`);
//       return;
//     }

//     setLoading(true);

//     try {
//       const paymentResponse = await axios.post("/pawapay/webpawapay", {
//         betAmount,
//         phone: details.phone,
//         boxNumber: selectedBox,
//         currency: currencyOperator,
//         correspondent: selectedOperator,
//         countrycode,
//         platform,
//         serviceCode,
//         countryName
//       });

//       if (paymentResponse.status === 200) {
//         const sessionId = paymentResponse.data.sessionId;

//         Swal.fire(
//           "SUCCESS!",
//           `Your game request has been received. A payment prompt will be sent to your phone shortly. Ticket number: ${sessionId}`,
//           "success"
//         );
//       } else {
//         setLoading(false);
//         setError(true);
//         setErrorMsg("Payment failed. Please try again later.");
//       }
//     } catch (error) {
//       console.error("Error processing payment:", error);
//       setLoading(false);
//       setError(true);
//       setErrorMsg("An error occurred while processing the payment. Please try again later.");
//     }
//   };

//   const playGame = (amount, boxNumber, phone, sessionId) => {
//     const formattedPhone = `233${phone.substring(phone.length - 9)}`;
//     axios
//       .post("/webgame/play-lucky-box-pawapay", {
//         betAmount: amount,
//         userBox: boxNumber,
//         phoneNumber: formattedPhone,
//         sessionId: sessionId,
//         currencyOperator: currencyOperator,
//         correspondent: selectedOperator,
//         currency: currency,
//         countrycode: countrycode
//       })
//       .then((gameResult) => {
//         const rewards = gameResult.data.details.LostfakeRewards;
//         const userBox = gameResult.data.userBox;
//         const selectedBoxNumber = gameResult.data.userBox;

//         const rewardsGridHTML = ReactDOM.renderToString(
//           <RewardsGrid rewards={rewards} userBox={userBox} />
//         );

//         Swal.fire({
//           title: gameResult.data.result,
//           html: `
//             <div>You selected Box ${selectedBoxNumber}</div>
//             <div>Your prize: ${gameResult.data.prize}</div>
//             ${rewardsGridHTML}
//           `,
//           icon: "success",
//           confirmButtonText: "Play Again",
//         });

//         sessionStorage.setItem(`game_${sessionId}`, 'true');
//       })
//       .catch((gameError) => {
//         console.error("Error playing the game:", gameError);
//       });
//   };

//   const handleBoxClick = (boxNumber) => {
//     setSelectedBox(boxNumber === selectedBox ? null : boxNumber);
//   };

//   const boxColors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700", "#9370DB"];
//   const gridStyles = {
//     display: "grid",
//     gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
//     gap: "10px",
//     marginTop: "20px",
//     marginBottom: "20px",
//   };

//   const boxStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     height: "100px",
//     border: "1px solid #ccc",
//     borderRadius: "5px",
//     cursor: "pointer",
//   };

//   const boxNumberStyle = {
//     fontSize: "1.5em",
//     color: "white",
//     fontWeight: "bold",
//   };

//   return (
//     <div className="col-12 col-md-5 mb-5 mt-5">
//       <div className="book-cover-holder">
//         <h1 className="headline mb-3">Pick a box</h1>
//         <div className="subheadline mb-4">Win up to {winuptoAmount} {currency}</div>
//         {error && (
//           <div
//             className="alert alert-danger mt-1 mb-1"
//             role="alert"
//             style={{ textAlign: "center" }}
//           >
//             {errorMsg}
//           </div>
//         )}
//         {loading ? (
//           <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
//             <LoadingSpinner />
//             <h3>Processing payment...</h3>
//             <h3 className="subheadline mb-4">STK PUSH SENT TO YOUR PHONE</h3>
//             <h3 className="subheadline mb-4 text-primary" style={{ fontWeight: "bold" }}>
//               {details.phone}
//             </h3>
//             <h3 className="subheadline mb-4">Enter PIN to confirm payment</h3>
//           </div>
//         ) : (
//           <form onSubmit={handleSubmit}>
//             <div className="mb-3">
//               <label htmlFor="phoneInput" className="form-label">
//                 Phone Number
//               </label>
//               <input
//                 type="tel"
//                 className="form-control"
//                 id="phoneInput"
//                 required
//                 name="phone"
//                 onChange={handleChange}
//                 placeholder={`e.g., ${validPrefixes
//                   .map((prefix) => prefix + "xx")
//                   .join(" or ")}`}
//                 value={details.phone}
//               />
//             </div>
//             <div className="mb-3">
//               <label htmlFor="amountInput" className="form-label">
//                 Amount
//               </label>
//               <input
//                 type="number"
//                 className="form-control"
//                 id="amountInput"
//                 required
//                 name="amount"
//                 placeholder={`e.g., ${currency} ${minAmount} - ${currency} ${maxAmount}`}
//                 onChange={handleChange}
//                 value={details.amount}
//               />
//             </div>

//             <div style={{ textAlign: "center", marginTop: "20px" }}>
//               <label htmlFor="operatorInput" className="form-label">
//                 Choose Your Mobile Money Operator
//               </label>
//             </div>
//             <div
//               className="mb-3"
//               style={{ display: "flex", justifyContent: "space-between" }}
//             >
//               {operators.map((operator) => (
//                 <Operator
//                   key={operator.id}
//                   logo={operator.logo}
//                   name={operator.name}
//                   onClick={() => handleOperatorSelect(operator.id)}
//                   selected={selectedOperator === operatorValues[operator.id]}
//                 />
//               ))}
//             </div>

//             <label htmlFor="boxnumberInput" className="form-label">
//               Choose Your Lucky Box
//             </label>
//             <div style={gridStyles}>
//               {[1, 2, 3, 4, 5, 6].map((boxNumber, index) => (
//                 <div
//                   key={boxNumber}
//                   className="box"
//                   style={{
//                     ...boxStyle,
//                     backgroundColor: boxColors[index % boxColors.length],
//                     boxShadow: selectedBox === boxNumber
//                       ? "0px 0px 5px 3px #000"
//                       : "none",
//                   }}
//                   onClick={() => handleBoxClick(boxNumber)}
//                 >
//                   <span style={boxNumberStyle}>Box {boxNumber}</span>
//                 </div>
//               ))}
//             </div>

//             {error && (
//               <div
//                 className="alert alert-danger mt-1 mb-1"
//                 role="alert"
//                 style={{ textAlign: "center" }}
//               >
//                 {errorMsg}
//               </div>
//             )}
//             <div className="col-12 col-md-auto mt-3">
//               <button type="submit" className="btn btn-primary w-100">
//                 PLAY {details.amount} {currency}
//               </button>
//             </div>
//           </form>
//         )}
//       </div>
//     </div>
//   );
// }

// export default PaymentForm;


/////// 3 ##################################################
// import React, { useState, useEffect } from "react";
// import ReactDOM from 'react-dom/server';
// import LoadingSpinner from "./LoadingSpinner";
// import Swal from "sweetalert2";
// import RewardsGrid from "./RewardsGrid";
// import axios from "axios";
// import Operator from "./Operator";
// import * as Ably from 'ably';

// function PaymentForm({
//   currency,
//   currencyOperator,
//   countrycode,
//   minAmount,
//   maxAmount,
//   validPrefixes,
//   operators,
//   operatorValues,
//   winuptoAmount,
//   platform,
//   serviceCode,
//   countryName
// }) {
//   const [details, setDetails] = useState({ phone: "", amount: "" });
//   const [error, setError] = useState(false);
//   const [errorMsg, setErrorMsg] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [selectedBox, setSelectedBox] = useState(null);
//   const [selectedOperator, setSelectedOperator] = useState(null);

//   // Ably Realtime subscription
//   useEffect(() => {
//     const ably = new Ably.Realtime({ key: process.env.REACT_APP_ABLY_API_KEY });
//     const channel = ably.channels.get('stk-updates');

//     channel.subscribe('deposit-status', (message) => {
//       console.log('Received deposit status update:', message.data);
//       const { status, depositedAmount, failureReason, sessionId } = message.data;

//       if (status === 'COMPLETED') {
//         Swal.fire(
//           'Payment Completed',
//           `Your payment of ${depositedAmount} was successful!`,
//           'success'
//         );
//         setLoading(false);
//         setDetails({ phone: details.phone, amount: "" });
//         setSelectedBox(null);

//         // Trigger the game logic after successful payment
//         checkAndPlayGame(sessionId);
//       } else if (status === 'FAILED') {
//         Swal.fire(
//           'Payment Failed',
//           `Your payment failed. Reason: ${failureReason}`,
//           'error'
//         );
//         setLoading(false);
//       }
//     });

//     return () => {
//       channel.unsubscribe();
//       ably.close();
//     };
//   }, [details.phone, setLoading]);

//   const handleChange = (e) => {
//     setDetails({ ...details, [e.target.name]: e.target.value });
//   };

//   const handleOperatorSelect = (operatorName) => {
//     setSelectedOperator(operatorValues[operatorName]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const betAmount = parseFloat(details.amount);
//     setError(false);
//     setErrorMsg("");

//     if (!details.phone || isNaN(betAmount) || selectedBox === null || !selectedOperator) {
//       setError(true);
//       setErrorMsg("Please complete all fields correctly.");
//       return;
//     }

//     const enteredPrefix = details.phone.substring(0, 2);
//     if (!validPrefixes.includes(enteredPrefix)) {
//       setError(true);
//       setErrorMsg(`Phone number should start with one of the valid network codes: ${validPrefixes.join(", ")}`);
//       return;
//     }

//     if (betAmount < minAmount) {
//       setError(true);
//       setErrorMsg(`Bet amount should be at least ${minAmount} ${currency}.`);
//       return;
//     }

//     setLoading(true);

//     try {
//       const paymentResponse = await axios.post("/pawapay/webpawapay", {
//         betAmount,
//         phone: details.phone,
//         boxNumber: selectedBox,
//         currency: currencyOperator,
//         correspondent: selectedOperator,   
//         countrycode,
//         platform,
//         serviceCode,
//         countryName
//       });

//       if (paymentResponse.status === 200) {
//         const sessionId = paymentResponse.data.sessionId;

//         Swal.fire(
//           "SUCCESS!",
//           `Your game request has been received. A payment prompt will be sent to your phone shortly. Ticket number: ${sessionId}`,
//           "success"
//         );
//       } else {
//         setLoading(false);
//         setError(true);
//         setErrorMsg("Payment failed. Please try again later.");
//       }
//     } catch (error) {
//       console.error("Error processing payment:", error);
//       setLoading(false);
//       setError(true);
//       setErrorMsg("An error occurred while processing the payment. Please try again later.");
//     }
//   };

//   const checkAndPlayGame = (sessionId) => {
//     const playedInSession = sessionStorage.getItem(sessionId);
//     if (!playedInSession) {
//       playGame(details.amount, selectedBox, details.phone, sessionId);
//       sessionStorage.setItem(sessionId, 'true');
//     }
//   };

//   const playGame = (amount, boxNumber, phone, sessionId) => {
//     const formattedPhone = `233${phone.substring(phone.length - 9)}`;
//     axios
//       .post("/webgame/play-lucky-box-pawapay", {
//         betAmount: amount,
//         userBox: boxNumber,
//         phoneNumber: formattedPhone,
//         sessionId: sessionId,
//         currencyOperator: currencyOperator,
//         correspondent: selectedOperator,   
//         currency: currency,
//         countrycode: countrycode
//       })
//       .then((gameResult) => {
//         const rewards = gameResult.data.details.LostfakeRewards;
//         const userBox = gameResult.data.userBox;
//         const selectedBoxNumber = gameResult.data.userBox;

//         const rewardsGridHTML = ReactDOM.renderToString(
//           <RewardsGrid rewards={rewards} userBox={userBox} />
//         );

//         Swal.fire({
//           title: gameResult.data.result,
//           html: `
//             <div>You selected Box ${selectedBoxNumber}</div>
//             <div>Your prize: ${gameResult.data.prize}</div>
//             ${rewardsGridHTML}
//           `,
//           icon: "success",
//           confirmButtonText: "Play Again",
//         });

//         sessionStorage.setItem(`game_${sessionId}`, 'true');
//       })
//       .catch((gameError) => {
//         console.error("Error playing the game:", gameError);
//       });
//   };

//   const handleBoxClick = (boxNumber) => {
//     setSelectedBox(boxNumber === selectedBox ? null : boxNumber);
//   };

//   const boxColors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700", "#9370DB"];
//   const gridStyles = {
//     display: "grid",
//     gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
//     gap: "10px",
//     marginTop: "20px",
//     marginBottom: "20px",
//   };

//   const boxStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     height: "100px",
//     border: "1px solid #ccc",
//     borderRadius: "5px",
//     cursor: "pointer",
//   };

//   const boxNumberStyle = {
//     fontSize: "1.5em",
//     color: "white",
//     fontWeight: "bold",
//   };

//   return (
//     <div className="col-12 col-md-5 mb-5 mt-5">
//       <div className="book-cover-holder">
//         <h1 className="headline mb-3">Pick a box</h1>
//         <div className="subheadline mb-4">Win up to {winuptoAmount} {currency}</div>
//         {error && (
//           <div
//             className="alert alert-danger mt-1 mb-1"
//             role="alert"
//             style={{ textAlign: "center" }}
//           >
//             {errorMsg}
//           </div>
//         )}
//         {loading ? (
//           <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
//             <LoadingSpinner />
//             <h3>Processing payment...</h3>
//             <h3 className="subheadline mb-4">STK PUSH SENT TO YOUR PHONE</h3>
//             <h3 className="subheadline mb-4 text-primary" style={{ fontWeight: "bold" }}>
//               {details.phone}
//             </h3>
//             <h3 className="subheadline mb-4">Enter PIN to confirm payment</h3>
//           </div>
//         ) : (
//           <form onSubmit={handleSubmit}>
//             <div className="mb-3">
//               <label htmlFor="phoneInput" className="form-label">
//                 Phone Number
//               </label>
//               <input
//                 type="tel"
//                 className="form-control"
//                 id="phoneInput"
//                 required
//                 name="phone"
//                 onChange={handleChange}
//                 placeholder={`e.g., ${validPrefixes
//                   .map((prefix) => prefix + "xx")
//                   .join(" or ")}`}
//                 value={details.phone}
//               />
//             </div>
//             <div className="mb-3">
//               <label htmlFor="amountInput" className="form-label">
//                 Amount
//               </label>
//               <input
//                 type="number"
//                 className="form-control"
//                 id="amountInput"
//                 required
//                 name="amount"
//                 placeholder={`e.g., ${currency} ${minAmount} - ${currency} ${maxAmount}`}
//                 onChange={handleChange}
//                 value={details.amount}
//               />
//             </div>

//             <div style={{ textAlign: "center", marginTop: "20px" }}>
//               <label htmlFor="operatorInput" className="form-label">
//                 Choose Your Mobile Money Operator
//               </label>
//             </div>
//             <div
//               className="mb-3"
//               style={{ display: "flex", justifyContent: "space-between" }}
//             >
//               {operators.map((operator) => (
//                 <Operator
//                   key={operator.id}
//                   logo={operator.logo}
//                   name={operator.name}
//                   onClick={() => handleOperatorSelect(operator.id)}
//                   selected={selectedOperator === operatorValues[operator.id]}
//                 />
//               ))}
//             </div>

//             <label htmlFor="boxnumberInput" className="form-label">
//               Choose Your Lucky Box
//             </label>
//             <div style={gridStyles}>
//               {[1, 2, 3, 4, 5, 6].map((boxNumber, index) => (
//                 <div
//                   key={boxNumber}
//                   className="box"
//                   style={{
//                     ...boxStyle,
//                     backgroundColor: boxColors[index % boxColors.length],
//                     boxShadow: selectedBox === boxNumber
//                       ? "0px 0px 5px 3px #000"
//                       : "none",
//                   }}
//                   onClick={() => handleBoxClick(boxNumber)}
//                 >
//                   <span style={boxNumberStyle}>Box {boxNumber}</span>
//                 </div>
//               ))}
//             </div>

//             {error && (
//               <div
//                 className="alert alert-danger mt-1 mb-1"
//                 role="alert"
//                 style={{ textAlign: "center" }}
//               >
//                 {errorMsg}
//               </div>
//             )}
//             <div className="col-12 col-md-auto mt-3">
//               <button type="submit" className="btn btn-primary w-100">
//                 PLAY {details.amount} {currency}
//               </button>
//             </div>
//           </form>
//         )}
//       </div>
//     </div>
//   );
// }

// export default PaymentForm;




// import React, { useState, useEffect } from "react";
// import ReactDOM from 'react-dom/server';
// import LoadingSpinner from "./LoadingSpinner";
// import Swal from "sweetalert2";
// import RewardsGrid from "./RewardsGrid";
// import axios from "axios";
// import Operator from "./Operator";
// import * as Ably from 'ably';

// function PaymentForm( {
//   currency,
//   currencyOperator,
//   countrycode,
//   minAmount,
//   maxAmount,
//   validPrefixes,
//   operators,
//   operatorValues,
//   winuptoAmount,
//   platform,
//   serviceCode,
//   countryName
// } ) {
 
//   const [details, setDetails] = useState({
//     phone: "",
//     amount: "",
//   });
//   const [error, setError] = useState(false);
//   const [errorMsg, setErrorMsg] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [selectedBox, setSelectedBox] = useState(null);
//   const [selectedOperator, setSelectedOperator] = useState(null);

//   // Ably Realtime subscription
//   useEffect(() => {
//     const ably = new Ably.Realtime({ key: process.env.REACT_APP_ABLY_API_KEY });
//     const channel = ably.channels.get('stk-updates');

//     channel.subscribe('deposit-status', (message) => {
//       console.log('Received deposit status update:', message.data);
//       const { status, depositedAmount, failureReason } = message.data;

//       if (status === 'COMPLETED') {
//         Swal.fire(
//           'Payment Completed',
//           `Your payment of ${depositedAmount} was successful!`,
//           'success'
//         );
//         setLoading(false);
//         // Additional game logic can be triggered here
//         setDetails({ phone: details.phone, amount: "" });
//         setSelectedBox(null);
//       } else if (status === 'FAILED') {
//         Swal.fire(
//           'Payment Failed',
//           `Your payment failed. Reason: ${failureReason}`,
//           'error'
//         );
//         setLoading(false);
//       }
//     });

//     return () => {
//       channel.unsubscribe();
//       ably.close();
//     };
//   }, [details.phone, setLoading]);

//   const handleChange = (e) => {
//     setDetails({ ...details, [e.target.name]: e.target.value });
//   };

//   const handleOperatorSelect = (operatorName) => {
//     setSelectedOperator(operatorValues[operatorName]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const betAmount = parseFloat(details.amount);
//     setError(false);
//     setErrorMsg("");

//     if (!details.phone || isNaN(betAmount) || selectedBox === null || !selectedOperator) {
//       setError(true);
//       setErrorMsg("Please complete all fields correctly.");
//       return;
//     }

//     const enteredPrefix = details.phone.substring(0, 2);
//     if (!validPrefixes.includes(enteredPrefix)) {
//       setError(true);
//       setErrorMsg(`Phone number should start with one of the valid network codes: ${validPrefixes.join(", ")}`);
//       return;
//     }

//     if (betAmount < minAmount) {
//       setError(true);
//       setErrorMsg(`Bet amount should be at least ${minAmount} ${currency}.`);
//       return;
//     }

//     setLoading(true);

//     try {
//       const paymentResponse = await axios.post("/pawapay/webpawapay", {
//         betAmount,
//         phone: details.phone,
//         boxNumber: selectedBox,
//         currency: currencyOperator,
//         correspondent: selectedOperator,   
//         countrycode,
//         platform,
//         serviceCode,
//         countryName
//       });

//       if (paymentResponse.status === 200) {
//         const sessionId = paymentResponse.data.sessionId;

//         // Set the query check
//         const stkQueryExecuted = sessionStorage.getItem(`stkQuery_${sessionId}`);
//         if (!stkQueryExecuted) {
//           stkPushQuery(paymentResponse.data.paymentData.depositId, sessionId);
//           sessionStorage.setItem(`stkQuery_${sessionId}`, 'true');
//         }

//         Swal.fire(
//           "SUCCESS!",
//           `Your game request has been received. A payment prompt will be sent to your phone shortly. Ticket number: ${sessionId}`,
//           "success"
//         );
//       } else {
//         setLoading(false);
//         setError(true);
//         setErrorMsg("Payment failed. Please try again later.");
//       }
//     } catch (error) {
//       console.error("Error processing payment:", error);
//       setLoading(false);
//       setError(true);
//       setErrorMsg("An error occurred while processing the payment. Please try again later.");
//     }
//   };

//   const stkPushQuery = (depositId, sessionId) => {
//     let retries = 0;
//     const maxRetries = 20;
//     const retryDelay = 5000;

//     const retryRequest = () => {
//       axios.get(`/pawapay/depositDetails/${depositId}`)
//         .then((response) => {
//           const result = response.data[0];
//           switch (result.status) {
//             case "COMPLETED":
//               Swal.fire(
//                 "SUCCESS!",
//                 "We've received your payment. The game is currently in progress",
//                 "success"
//               );
//               setDetails({ phone: details.phone, amount: "" });
//               setSelectedBox(null);
//               checkAndPlayGame(sessionId);
//               break;
//             case "FAILED":
//               Swal.fire({
//                 icon: "error",
//                 title: result.status,
//                 text: result.failureReason.failureMessage,
//               });
//               break;
//             default:
//               break;
//           }
//         })
//         .catch((err) => {
//           console.log("Error during request:", err.message);
//         });
//     };

//     const timer = setInterval(() => {
//       if (retries >= maxRetries) {
//         clearInterval(timer);
//         setLoading(false);
//         setErrorMsg("You took too long to respond, no final status received.");
//         setError(true);
//         return;
//       }
//       retries++;
//       retryRequest();
//     }, retryDelay);
//   };

//   const checkAndPlayGame = (sessionId) => {
//     const playedInSession = sessionStorage.getItem(sessionId);
//     if (!playedInSession) {
//       playGame(details.amount, selectedBox, details.phone, sessionId);
//       sessionStorage.setItem(sessionId, 'true');
//     }
//   };

//   const playGame = (amount, boxNumber, phone, sessionId) => {
//     const formattedPhone = `233${phone.substring(phone.length - 9)}`;
//     axios
//       .post("/webgame/play-lucky-box-pawapay", {
//         betAmount: amount,
//         userBox: boxNumber,
//         phoneNumber: formattedPhone,
//         sessionId: sessionId,
//         currencyOperator: currencyOperator,
//         correspondent: selectedOperator,   
//         currency: currency,
//         countrycode: countrycode
//       })
//       .then((gameResult) => {
//         const rewards = gameResult.data.details.LostfakeRewards;
//         const userBox = gameResult.data.userBox;
//         const selectedBoxNumber = gameResult.data.userBox;

//         const rewardsGridHTML = ReactDOM.renderToString(
//           <RewardsGrid rewards={rewards} userBox={userBox} />
//         );

//         Swal.fire({
//           title: gameResult.data.result,
//           html: `
//             <div>You selected Box ${selectedBoxNumber}</div>
//             <div>Your prize: ${gameResult.data.prize}</div>
//             ${rewardsGridHTML}
//           `,
//           icon: "success",
//           confirmButtonText: "Play Again",
//         });

//         sessionStorage.setItem(`game_${sessionId}`, 'true');
//       })
//       .catch((gameError) => {
//         console.error("Error playing the game:", gameError);
//       });
//   };

//   const handleBoxClick = (boxNumber) => {
//     setSelectedBox(boxNumber === selectedBox ? null : boxNumber);
//   };

//   const boxColors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700", "#9370DB"];
//   const gridStyles = {
//     display: "grid",
//     gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
//     gap: "10px",
//     marginTop: "20px",
//     marginBottom: "20px",
//   };

//   const boxStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     height: "100px",
//     border: "1px solid #ccc",
//     borderRadius: "5px",
//     cursor: "pointer",
//   };

//   const boxNumberStyle = {
//     fontSize: "1.5em",
//     color: "white",
//     fontWeight: "bold",
//   };

//   return (
//     <div className="col-12 col-md-5 mb-5 mt-5">
//       <div className="book-cover-holder">
//         <h1 className="headline mb-3">Pick a box</h1>
//         <div className="subheadline mb-4">Win up to {winuptoAmount} {currency}</div>
//         {error && (
//           <div
//             className="alert alert-danger mt-1 mb-1"
//             role="alert"
//             style={{ textAlign: "center" }}
//           >
//             {errorMsg}
//           </div>
//         )}
//         {loading ? (
//           <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
//             <LoadingSpinner />
//             <h3>Processing payment...</h3>
//             <h3 className="subheadline mb-4">STK PUSH SENT TO YOUR PHONE</h3>
//             <h3 className="subheadline mb-4 text-primary" style={{ fontWeight: "bold" }}>
//               {details.phone}
//             </h3>
//             <h3 className="subheadline mb-4">Enter PIN to confirm payment</h3>
//           </div>
//         ) : (
//           <form onSubmit={handleSubmit}>
//             <div className="mb-3">
//               <label htmlFor="phoneInput" className="form-label">
//                 Phone Number
//               </label>
//               <input
//                 type="tel"
//                 className="form-control"
//                 id="phoneInput"
//                 required
//                 name="phone"
//                 onChange={handleChange}
//                 placeholder={`e.g., ${validPrefixes
//                   .map((prefix) => prefix + "xx")
//                   .join(" or ")}`}
//                 value={details.phone}
//               />
//             </div>
//             <div className="mb-3">
//               <label htmlFor="amountInput" className="form-label">
//                 Amount
//               </label>
//               <input
//                 type="number"
//                 className="form-control"
//                 id="amountInput"
//                 required
//                 name="amount"
//                 placeholder={`e.g., ${currency} ${minAmount} - ${currency} ${maxAmount}`}
//                 onChange={handleChange}
//                 value={details.amount}
//               />
//             </div>

//             <div style={{ textAlign: "center", marginTop: "20px" }}>
//               <label htmlFor="operatorInput" className="form-label">
//                 Choose Your Mobile Money Operator
//               </label>
//             </div>
//             <div
//               className="mb-3"
//               style={{ display: "flex", justifyContent: "space-between" }}
//             >
//               {operators.map((operator) => (
//                 <Operator
//                   key={operator.id}
//                   logo={operator.logo}
//                   name={operator.name}
//                   onClick={() => handleOperatorSelect(operator.id)}
//                   selected={selectedOperator === operatorValues[operator.id]}
//                 />
//               ))}
//             </div>

//             <label htmlFor="boxnumberInput" className="form-label">
//               Choose Your Lucky Box
//             </label>
//             <div style={gridStyles}>
//               {[1, 2, 3, 4, 5, 6].map((boxNumber, index) => (
//                 <div
//                   key={boxNumber}
//                   className="box"
//                   style={{
//                     ...boxStyle,
//                     backgroundColor: boxColors[index % boxColors.length],
//                     boxShadow: selectedBox === boxNumber
//                       ? "0px 0px 5px 3px #000"
//                       : "none",
//                   }}
//                   onClick={() => handleBoxClick(boxNumber)}
//                 >
//                   <span style={boxNumberStyle}>Box {boxNumber}</span>
//                 </div>
//               ))}
//             </div>

//             {error && (
//               <div
//                 className="alert alert-danger mt-1 mb-1"
//                 role="alert"
//                 style={{ textAlign: "center" }}
//               >
//                 {errorMsg}
//               </div>
//             )}
//             <div className="col-12 col-md-auto mt-3">
//               <button type="submit" className="btn btn-primary w-100">
//                 PLAY {details.amount} {currency}
//               </button>
//             </div>
//           </form>
//         )}
//       </div>
//     </div>
//   );
// }

// export default PaymentForm;


// import React, { useState } from "react";
// import ReactDOM from 'react-dom/server';
// import LoadingSpinner from "./LoadingSpinner";
// import Swal from "sweetalert2";
// import RewardsGrid from "./RewardsGrid";
// import axios from "axios";
// import Operator from "./Operator";


// function PaymentForm( {
//   currency,
//   currencyOperator,
//   countrycode,
//   minAmount,
//   maxAmount,
//   validPrefixes,
//   operators,
//   operatorValues,
//   winuptoAmount,
//   platform,
//   serviceCode,
//   countryName
// } ) {
 
//   const [details, setDetails] = useState({
//     phone: "",
//     amount: "",
//   });
//   const [error, setError] = useState(false);
//   const [errorMsg, setErrorMsg] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [selectedBox, setSelectedBox] = useState(null);
//   const [selectedOperator, setSelectedOperator] = useState(null);



//   const handleChange = (e) => {
//     setDetails({ ...details, [e.target.name]: e.target.value });
//   };

  

// const handleOperatorSelect = (operatorName) => {
//     setSelectedOperator(operatorValues[operatorName]);
//   };



// const handleSubmit = async (e) => {
//   e.preventDefault();
//   //console.log('Form submitted with details:', details);

//   const betAmount = parseFloat(details.amount);

//   // Reset previous errors
//   setError(false);
//   setErrorMsg("");

//   // Validate each input
//   if (!details.phone) {
//     setError(true);
//     setErrorMsg("Please enter a phone number.");
//     return;
//   }
//   if (isNaN(betAmount)) {
//     setError(true);
//     setErrorMsg("Please enter a valid amount.");
//     return;
//   }
//   if (selectedBox === null) {
//     setError(true);
//     setErrorMsg("Please select a box.");
//     return;
//   }
//   if (!selectedOperator) {
//     setError(true);
//     setErrorMsg("Please choose your mobile operator.");
//     return;
//   }
  
//   const enteredPrefix = details.phone.substring(0, 2); // number prefix after 0
//   if (!validPrefixes.includes(enteredPrefix)) {
//     setError(true);
//     setErrorMsg(
//       `Phone number should start with one of the valid network codes: ${validPrefixes.join(
//         ", "
//       )}.`
//     );
//     return;
//   }

//   if (betAmount < minAmount) { // Check if bet amount is less than the minimum amount
//     setError(true);
//     setErrorMsg(`Bet amount should be at least ${minAmount} ${currency}.`);
//     return;
// }


//   setLoading(true);

//   try {
//     const paymentResponse = await axios.post("/pawapay/webpawapay", {
//       betAmount: betAmount,
//       phone: details.phone,
//       boxNumber: selectedBox,
//       currency: currencyOperator,
//       correspondent: selectedOperator,   
//       countrycode: countrycode, // Static country code, adjust as necessary
//       platform:platform,
//       serviceCode:serviceCode,
//       countryName:countryName

//     });

//     if (paymentResponse.status === 200) {
//       //console.log("paymentResponse", paymentResponse)
//       const sessionId = paymentResponse.data.sessionId;

//       const stkQueryExecuted = sessionStorage.getItem(`stkQuery_${sessionId}`);
//       if (!stkQueryExecuted) {
//         stkPushQuery(paymentResponse.data.paymentData.depositId, sessionId);
//         sessionStorage.setItem(`stkQuery_${sessionId}`, 'true');
//       }

//       setLoading(false);
//       Swal.fire(
//         "SUCCESS!",
//         `Your game request has been received. A payment prompt will be sent to your phone shortly. Ticket number: ${sessionId}`,
//         "success "
//       );
//     } else {
//       setLoading(false);
//       setError(true);
//       setErrorMsg("Payment failed. Please try again later.");
//     }
//   } catch (error) {
//     console.error("Error processing payment:", error);
//     setLoading(false);
//     setError(true);
//     setErrorMsg(
//       "An error occurred while processing the payment. Please try again later."
//     );
//   }
// };




// const stkPushQuery = (depositId, sessionId) => {
//   let retries = 0;
//   const maxRetries = 20; // Adjusted to allow for more retries
//   const retryDelay = 5000; // Delay in milliseconds between retries
//   //console.log("Initiating stkPushQuery with depositId:", depositId);

//   const retryRequest = () => {
//     console.log("Retrying request... Attempt:", retries + 1);
//     axios.get(`/pawapay/depositDetails/${depositId}`)
//       .then((response) => {
//         const result = response.data[0]; // Access the first element of the array
//         //console.log("Response data:", result);
//         switch (result.status) {
//           case "COMPLETED":
//             console.log("Payment status: COMPLETED");
//             clearInterval(timer); // Clear the interval to stop further retries
//             setLoading(false);
//             Swal.fire(
//               "SUCCESS!",
//               "We've received your payment. The game is currently in progress",
//               "success"
//             );
//             setDetails({ phone: details.phone, amount: "" }); // Reset the amount field
//             setSelectedBox(null); // Reset the selected box
//             checkAndPlayGame(sessionId);
//             break;
//           case "ACCEPTED":
//           case "SUBMITTED":
//             //console.log(`Payment status: ${result.status}. Still processing.`);
//             break;
//           case "FAILED":
//             //console.log("Payment status: FAILED");
//             Swal.fire({
//               icon: "error",
//               title: result.status,
//               text: result.failureReason.failureMessage,
//               ///footer: '<a href="#">Please try again</a>'
//             });
//             clearInterval(timer); // Clear the interval to stop further retries
//             setLoading(false);
//             setError(true);
//             setErrorMsg("The payment process has failed. Please try again later.");
//             break;
//           default:
//             //console.log("Unexpected status received:", result.status);
//             clearInterval(timer);
//             setLoading(false);
//             setError(true);
//             setErrorMsg(`Unhandled status: ${result.status}. Please contact support.`);
//             break;
//         }
//       })
//       .catch((err) => {
//         console.log("Error during request:", err.message);
//         clearInterval(timer);
//         setLoading(false);
//         setError(true);
//         setErrorMsg("Network error, please try again.");
//       });
//   };

//   const timer = setInterval(() => {
//    // console.log('Checking payment status...');
//     if (retries >= maxRetries) {
//       //console.log("Maximum retries reached. Stopping loop.");
//       clearInterval(timer);
//       setLoading(false);
//       setErrorMsg("You took too long to respond, no final status received.");
//       setError(true);
//       return;
//     }
//     retries++;
//     retryRequest();
//   }, retryDelay);
// };

// function checkAndPlayGame(sessionId) {
//   const playedInSession = sessionStorage.getItem(sessionId);
//   if (!playedInSession) {
//     //console.log("Game has not been played in this session. Calling playGame...");
//     playGame(details.amount, selectedBox, details.phone, sessionId);
//     sessionStorage.setItem(sessionId, 'true');
//   } else {
//     //console.log("Game has already been played in this session.");
//   }
// }


//   const playGame = (amount, boxNumber, phone, sessionId) => {
//     // Check if the game has already been played in this session
//     const playedInSession = sessionStorage.getItem(`game_${sessionId}`);
//     if (playedInSession) {
//       // If the game has been played, return early
//       return;
//     }

//     //console.log('Playing game with details:', { amount, boxNumber, phone, sessionId });
//     const formattedPhone = `233${phone.substring(phone.length - 9)}`;
//     axios
//       .post("/webgame/play-lucky-box-pawapay", {
//         betAmount: amount,
//         userBox: boxNumber,
//         phoneNumber: formattedPhone,
//         sessionId: sessionId,
//         currencyOperator: currencyOperator,
//         correspondent: selectedOperator,   
//         currency:currency,
//         countrycode:countrycode
        
//       })
//       .then((gameResult) => {
//         //console.log("Game response:", gameResult.data);

//         //const rewards = gameResult.data.details.Rewards;
//         const rewards = gameResult.data.details.LostfakeRewards;
//         const userBox = gameResult.data.userBox;
//         const selectedBoxNumber = gameResult.data.userBox;

//         const rewardsGridHTML = ReactDOM.renderToString(
//           <RewardsGrid rewards={rewards} userBox={userBox} />
//         );

//         Swal.fire({
//           title: gameResult.data.result,
//           html: `
//             <div>You selected Box ${selectedBoxNumber}</div>
//             <div>Your prize: ${gameResult.data.prize}</div>
//             ${rewardsGridHTML}
//           `,
//           icon: "success",
//           backdrop: `
//             rgba(0,0,123,0.4)
//             left top
//             no-repeat
//           `,
//           confirmButtonText: "Play Again",
//         });

//         // Set the flag in the session storage
//         sessionStorage.setItem(`game_${sessionId}`, 'true');
//       })
//       .catch((gameError) => {
//         console.error("Error playing the game:", gameError);
//       });
//   };


//   const handleBoxClick = (boxNumber) => {
//     setSelectedBox(boxNumber === selectedBox ? null : boxNumber);
//   };



//   const boxColors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700", "#9370DB"]; // Different box colors
//   const gridStyles = {
//     display: "grid",
//     gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
//     gap: "10px",
//     marginTop: "20px",
//     marginBottom: "20px",
//   };

//   const boxStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     height: "100px",
//     border: "1px solid #ccc",
//     borderRadius: "5px",
//     cursor: "pointer",
//   };

//   const boxNumberStyle = {
//     fontSize: "1.5em",
//     color: "white",
//     fontWeight: "bold",
//   };


  
//   return (
//     <div className="col-12 col-md-5 mb-5 mt-5">
//       <div className="book-cover-holder">
//         <h1 className="headline mb-3">Pick a box</h1>
//         <div className="subheadline mb-4">Win up to {winuptoAmount} {currency}</div>
//         {error && (
//           <div
//             className="alert alert-danger mt-1 mb-1"
//             role="alert"
//             style={{ textAlign: "center" }}
//           >
//             {errorMsg}
//           </div>
//         )}
//         {loading ? (
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               textAlign: "center",
//             }}
//           >
//             <div>
//               <LoadingSpinner />
//               <h3>Processing payment...</h3>
//               <h3 className="subheadline mb-4">STK PUSH SENT TO YOUR PHONE</h3>
//               <h3
//                 className="subheadline mb-4 text-primary"
//                 style={{ fontWeight: "bold" }}
//               >
//                 {details.phone}
//               </h3>
//               <h3 className="subheadline mb-4">Enter PIN to confirm payment</h3>
//             </div>
//           </div>
//         ) : (
//           <form onSubmit={handleSubmit}>
//             <div className="mb-3">
//               <label htmlFor="phoneInput" className="form-label">
//                 Phone Number
//               </label>
//               <input
//                 type="tel"
//                 className="form-control"
//                 id="phoneInput"
//                 required
//                 autoFocus
//                 name="phone"
//                 onChange={handleChange}
//                 placeholder={`e.g., ${validPrefixes
//                   .map((prefix) => prefix + "xx")
//                   .join(" or ")}`}
//                 value={details.phone}
//               />
//             </div>
//             <div className="mb-3">
//               <label htmlFor="amountInput" className="form-label">
//                 Amount
//               </label>
//               <input
//                 type="number"
//                 className="form-control"
//                 id="amountInput"
//                 required
//                 name="amount"
//                 //placeholder={`${currency} 20 - ${currency} 1000`}
//                 placeholder={`e.g., ${currency} ${minAmount} - ${currency} ${maxAmount}`}
//                 onChange={handleChange}
//                 value={details.amount}
//               />
//             </div>

//             {/* Operators Display Section */}
//             <div style={{ textAlign: "center", marginTop: "20px" }}>
//               <label htmlFor="operatorInput" className="form-label">
//                 Choose Your Mobile Money Operator
//               </label>
//             </div>
//             <div
//               className="mb-3"
//               style={{ display: "flex", justifyContent: "space-between" }}
//             >
//               {operators.map((operator) => (
//                 <Operator
//                   key={operator.id}
//                   logo={operator.logo}
//                   name={operator.name}
//                   onClick={() => handleOperatorSelect(operator.id)}
//                   selected={selectedOperator === operatorValues[operator.id]}
//                 />
//               ))}
//             </div>

//             <label htmlFor="boxnumberInput" className="form-label">
//               Choose Your Lucky Box
//             </label>
//             <div style={gridStyles}>
//               {[1, 2, 3, 4, 5, 6].map((boxNumber, index) => (
//                 <div
//                   key={boxNumber}
//                   className="box"
//                   style={{
//                     ...boxStyle,
//                     backgroundColor: boxColors[index % boxColors.length], // Cycle through colors
//                     boxShadow:
//                       selectedBox === boxNumber
//                         ? "0px 0px 5px 3px #000"
//                         : "none", // Highlight on click
//                   }}
//                   onClick={() => handleBoxClick(boxNumber)}
//                 >
//                   <span style={boxNumberStyle}>Box {boxNumber}</span>
//                 </div>
//               ))}
//             </div>
//             {error && (
//               <div
//                 className="alert alert-danger mt-1 mb-1"
//                 role="alert"
//                 style={{ textAlign: "center" }}
//               >
//                 {errorMsg}
//               </div>
//             )}
//             <div className="col-12 col-md-auto mt-3">
//               <button type="submit" className="btn btn-primary w-100">
//                 PLAY {details.amount} {currency}
//               </button>
//             </div>
//           </form>
//         )}
//       </div>
//     </div>
//   );
  
// }



// export default PaymentForm;




















// import React, { useState } from "react";
// import ReactDOM from 'react-dom/server';
// import LoadingSpinner from "./LoadingSpinner";
// import Swal from "sweetalert2";
// import RewardsGrid from "./RewardsGrid";
// import axios from "axios";
// import Operator from "./Operator";


// function PaymentForm( {
//   currency,
//   currencyOperator,
//   countrycode,
//   minAmount,
//   maxAmount,
//   validPrefixes,
//   operators,
//   operatorValues,
//   winuptoAmount,
//   platform,
//   serviceCode,
//   countryName
// } ) {
 
//   const [details, setDetails] = useState({
//     phone: "",
//     amount: "",
//   });
//   const [error, setError] = useState(false);
//   const [errorMsg, setErrorMsg] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [selectedBox, setSelectedBox] = useState(null);
//   const [selectedOperator, setSelectedOperator] = useState(null);



//   const handleChange = (e) => {
//     setDetails({ ...details, [e.target.name]: e.target.value });
//   };

  

// const handleOperatorSelect = (operatorName) => {
//     setSelectedOperator(operatorValues[operatorName]);
//   };



// const handleSubmit = async (e) => {
//   e.preventDefault();
//   console.log('Form submitted with details:', details);

//   const betAmount = parseFloat(details.amount);

//   // Reset previous errors
//   setError(false);
//   setErrorMsg("");

//   // Validate each input
//   if (!details.phone) {
//     setError(true);
//     setErrorMsg("Please enter a phone number.");
//     return;
//   }
//   if (isNaN(betAmount)) {
//     setError(true);
//     setErrorMsg("Please enter a valid amount.");
//     return;
//   }
//   if (selectedBox === null) {
//     setError(true);
//     setErrorMsg("Please select a box.");
//     return;
//   }
//   if (!selectedOperator) {
//     setError(true);
//     setErrorMsg("Please choose your mobile operator.");
//     return;
//   }
  
//   const enteredPrefix = details.phone.substring(0, 2); // number prefix after 0
//   if (!validPrefixes.includes(enteredPrefix)) {
//     setError(true);
//     setErrorMsg(
//       `Phone number should start with one of the valid network codes: ${validPrefixes.join(
//         ", "
//       )}.`
//     );
//     return;
//   }

//   if (betAmount < minAmount) { // Check if bet amount is less than the minimum amount
//     setError(true);
//     setErrorMsg(`Bet amount should be at least ${minAmount} ${currency}.`);
//     return;
// }


//   setLoading(true);

//   try {
//     const paymentResponse = await axios.post("/pawapay/webpawapay", {
//       betAmount: betAmount,
//       phone: details.phone,
//       boxNumber: selectedBox,
//       currency: currencyOperator,
//       correspondent: selectedOperator,   
//       countrycode: countrycode, // Static country code, adjust as necessary
//       platform:platform,
//       serviceCode:serviceCode,
//       countryName:countryName

//     });

//     if (paymentResponse.status === 200) {
//       console.log("paymentResponse", paymentResponse)
//       const sessionId = paymentResponse.data.sessionId;

//       const stkQueryExecuted = sessionStorage.getItem(`stkQuery_${sessionId}`);
//       if (!stkQueryExecuted) {
//         stkPushQuery(paymentResponse.data.paymentData.depositId, sessionId);
//         sessionStorage.setItem(`stkQuery_${sessionId}`, 'true');
//       }

//       setLoading(false);
//       Swal.fire(
//         "SUCCESS!",
//         `Your game request has been received. A payment prompt will be sent to your phone shortly. Ticket number: ${sessionId}`,
//         "success "
//       );
//     } else {
//       setLoading(false);
//       setError(true);
//       setErrorMsg("Payment failed. Please try again later.");
//     }
//   } catch (error) {
//     console.error("Error processing payment:", error);
//     setLoading(false);
//     setError(true);
//     setErrorMsg(
//       "An error occurred while processing the payment. Please try again later."
//     );
//   }
// };






// const stkPushQuery = (depositId, sessionId) => {
//   let retries = 0;
//   const maxRetries = 20; // Adjusted to allow for more retries
//   const retryDelay = 5000; // Delay in milliseconds between retries
//   console.log("Initiating stkPushQuery with depositId:", depositId);

//   const retryRequest = () => {
//     console.log("Retrying request... Attempt:", retries + 1);
//     axios.get(`/pawapay/depositDetails/${depositId}`)
//       .then((response) => {
//         const result = response.data[0]; // Access the first element of the array
//         console.log("Response data:", result);
//         switch (result.status) {
//           case "COMPLETED":
//             console.log("Payment status: COMPLETED");
//             clearInterval(timer); // Clear the interval to stop further retries
//             setLoading(false);
//             Swal.fire(
//               "SUCCESS!",
//               "We've received your payment. The game is currently in progress",
//               "success"
//             );
//             setDetails({ phone: details.phone, amount: "" }); // Reset the amount field
//             setSelectedBox(null); // Reset the selected box
//             checkAndPlayGame(sessionId);
//             break;
//           case "ACCEPTED":
//           case "SUBMITTED":
//             console.log(`Payment status: ${result.status}. Still processing.`);
//             break;
//           case "FAILED":
//             console.log("Payment status: FAILED");
//             Swal.fire({
//               icon: "error",
//               title: result.status,
//               text: result.failureReason.failureMessage,
//               ///footer: '<a href="#">Please try again</a>'
//             });
//             clearInterval(timer); // Clear the interval to stop further retries
//             setLoading(false);
//             setError(true);
//             setErrorMsg("The payment process has failed. Please try again later.");
//             break;
//           default:
//             console.log("Unexpected status received:", result.status);
//             clearInterval(timer);
//             setLoading(false);
//             setError(true);
//             setErrorMsg(`Unhandled status: ${result.status}. Please contact support.`);
//             break;
//         }
//       })
//       .catch((err) => {
//         console.log("Error during request:", err.message);
//         clearInterval(timer);
//         setLoading(false);
//         setError(true);
//         setErrorMsg("Network error, please try again.");
//       });
//   };

//   const timer = setInterval(() => {
//     console.log('Checking payment status...');
//     if (retries >= maxRetries) {
//       console.log("Maximum retries reached. Stopping loop.");
//       clearInterval(timer);
//       setLoading(false);
//       setErrorMsg("You took too long to respond, no final status received.");
//       setError(true);
//       return;
//     }
//     retries++;
//     retryRequest();
//   }, retryDelay);
// };

// function checkAndPlayGame(sessionId) {
//   const playedInSession = sessionStorage.getItem(sessionId);
//   if (!playedInSession) {
//     console.log("Game has not been played in this session. Calling playGame...");
//     playGame(details.amount, selectedBox, details.phone, sessionId);
//     sessionStorage.setItem(sessionId, 'true');
//   } else {
//     console.log("Game has already been played in this session.");
//   }
// }


//   const playGame = (amount, boxNumber, phone, sessionId) => {
//     // Check if the game has already been played in this session
//     const playedInSession = sessionStorage.getItem(`game_${sessionId}`);
//     if (playedInSession) {
//       // If the game has been played, return early
//       return;
//     }

//     console.log('Playing game with details:', { amount, boxNumber, phone, sessionId });
//     const formattedPhone = `233${phone.substring(phone.length - 9)}`;
//     axios
//       .post("/webgame/play-lucky-box-pawapay", {
//         betAmount: amount,
//         userBox: boxNumber,
//         phoneNumber: formattedPhone,
//         sessionId: sessionId,
//         currencyOperator: currencyOperator,
//         correspondent: selectedOperator,   
//         currency:currency,
//         countrycode:countrycode
        
//       })
//       .then((gameResult) => {
//         console.log("Game response:", gameResult.data);

//         //const rewards = gameResult.data.details.Rewards;
//         const rewards = gameResult.data.details.LostfakeRewards;
//         const userBox = gameResult.data.userBox;
//         const selectedBoxNumber = gameResult.data.userBox;

//         const rewardsGridHTML = ReactDOM.renderToString(
//           <RewardsGrid rewards={rewards} userBox={userBox} />
//         );

//         Swal.fire({
//           title: gameResult.data.result,
//           html: `
//             <div>You selected Box ${selectedBoxNumber}</div>
//             <div>Your prize: ${gameResult.data.prize}</div>
//             ${rewardsGridHTML}
//           `,
//           icon: "success",
//           backdrop: `
//             rgba(0,0,123,0.4)
//             left top
//             no-repeat
//           `,
//           confirmButtonText: "Play Again",
//         });

//         // Set the flag in the session storage
//         sessionStorage.setItem(`game_${sessionId}`, 'true');
//       })
//       .catch((gameError) => {
//         console.error("Error playing the game:", gameError);
//       });
//   };


//   const handleBoxClick = (boxNumber) => {
//     setSelectedBox(boxNumber === selectedBox ? null : boxNumber);
//   };



//   const boxColors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700", "#9370DB"]; // Different box colors
//   const gridStyles = {
//     display: "grid",
//     gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
//     gap: "10px",
//     marginTop: "20px",
//     marginBottom: "20px",
//   };

//   const boxStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     height: "100px",
//     border: "1px solid #ccc",
//     borderRadius: "5px",
//     cursor: "pointer",
//   };

//   const boxNumberStyle = {
//     fontSize: "1.5em",
//     color: "white",
//     fontWeight: "bold",
//   };


  
//   return (
//     <div className="col-12 col-md-5 mb-5 mt-5">
//       <div className="book-cover-holder">
//         <h1 className="headline mb-3">Pick a box</h1>
//         <div className="subheadline mb-4">Win up to {winuptoAmount} {currency}</div>
//         {error && (
//           <div
//             className="alert alert-danger mt-1 mb-1"
//             role="alert"
//             style={{ textAlign: "center" }}
//           >
//             {errorMsg}
//           </div>
//         )}
//         {loading ? (
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               textAlign: "center",
//             }}
//           >
//             <div>
//               <LoadingSpinner />
//               <h3>Processing payment...</h3>
//               <h3 className="subheadline mb-4">STK PUSH SENT TO YOUR PHONE</h3>
//               <h3
//                 className="subheadline mb-4 text-primary"
//                 style={{ fontWeight: "bold" }}
//               >
//                 {details.phone}
//               </h3>
//               <h3 className="subheadline mb-4">Enter PIN to confirm payment</h3>
//             </div>
//           </div>
//         ) : (
//           <form onSubmit={handleSubmit}>
//             <div className="mb-3">
//               <label htmlFor="phoneInput" className="form-label">
//                 Phone Number
//               </label>
//               <input
//                 type="tel"
//                 className="form-control"
//                 id="phoneInput"
//                 required
//                 autoFocus
//                 name="phone"
//                 onChange={handleChange}
//                 placeholder={`e.g., ${validPrefixes
//                   .map((prefix) => prefix + "xx")
//                   .join(" or ")}`}
//                 value={details.phone}
//               />
//             </div>
//             <div className="mb-3">
//               <label htmlFor="amountInput" className="form-label">
//                 Amount
//               </label>
//               <input
//                 type="number"
//                 className="form-control"
//                 id="amountInput"
//                 required
//                 name="amount"
//                 //placeholder={`${currency} 20 - ${currency} 1000`}
//                 placeholder={`e.g., ${currency} ${minAmount} - ${currency} ${maxAmount}`}
//                 onChange={handleChange}
//                 value={details.amount}
//               />
//             </div>

//             {/* Operators Display Section */}
//             <div style={{ textAlign: "center", marginTop: "20px" }}>
//               <label htmlFor="operatorInput" className="form-label">
//                 Choose Your Mobile Money Operator
//               </label>
//             </div>
//             <div
//               className="mb-3"
//               style={{ display: "flex", justifyContent: "space-between" }}
//             >
//               {operators.map((operator) => (
//                 <Operator
//                   key={operator.id}
//                   logo={operator.logo}
//                   name={operator.name}
//                   onClick={() => handleOperatorSelect(operator.id)}
//                   selected={selectedOperator === operatorValues[operator.id]}
//                 />
//               ))}
//             </div>

//             <label htmlFor="boxnumberInput" className="form-label">
//               Choose Your Lucky Box
//             </label>
//             <div style={gridStyles}>
//               {[1, 2, 3, 4, 5, 6].map((boxNumber, index) => (
//                 <div
//                   key={boxNumber}
//                   className="box"
//                   style={{
//                     ...boxStyle,
//                     backgroundColor: boxColors[index % boxColors.length], // Cycle through colors
//                     boxShadow:
//                       selectedBox === boxNumber
//                         ? "0px 0px 5px 3px #000"
//                         : "none", // Highlight on click
//                   }}
//                   onClick={() => handleBoxClick(boxNumber)}
//                 >
//                   <span style={boxNumberStyle}>Box {boxNumber}</span>
//                 </div>
//               ))}
//             </div>
//             {error && (
//               <div
//                 className="alert alert-danger mt-1 mb-1"
//                 role="alert"
//                 style={{ textAlign: "center" }}
//               >
//                 {errorMsg}
//               </div>
//             )}
//             <div className="col-12 col-md-auto mt-3">
//               <button type="submit" className="btn btn-primary w-100">
//                 PLAY {details.amount} {currency}
//               </button>
//             </div>
//           </form>
//         )}
//       </div>
//     </div>
//   );
  
// }



// export default PaymentForm;