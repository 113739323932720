import React from 'react';
//import './index.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import USPage from './pages/USPage';
import UKPage from './pages/UKPage';
import KEPage from './pages/KEPage';
import OtherPage from './pages/OtherPage';
import GHPage from './pages/GHpage';
import * as Ably from 'ably';
import UGPage from './pages/UGpage';
import ZAMPage from './pages/ZAMpage';
import Prizepage from './pages/Prizepage';
import Winnerspage from './pages/Winnerspage';
import HowToPlay from './pages/HowToPlay';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import GHpageV2 from './pages/GHpageV2';
import GHPageHub from './pages/GHpageHub';



const App = () => {
  return (
    
    
    <div> 
      <Routes>
        <Route path="/" element={<HomePage />} index />
        <Route path="/ke" element={<KEPage />} />
        <Route path="/prizetable" element={<Prizepage />} />
        <Route path="/winnerstable" element={<Winnerspage />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/howtoplay" element={<HowToPlay />} />
        <Route path="/ug" element={<UGPage />} />
        <Route path="/zam" element={<ZAMPage/>} />
        <Route path="/us" element={<USPage />} />
        <Route path="/uk" element={<UKPage />} />
        <Route path="/gh3" element={<GHPage />} />
        <Route path="/gh2" element={<GHpageV2 />} />
        <Route path="/gh" element={<GHPageHub />} />
        <Route path="/other" element={<OtherPage />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </div>
   
  );
};

export default App;






// import DisplayTable from "./components/DisplayTable";
// import Header from "./components/Header";
// import PaymentForm from "./components/PaymentForm";
// import Footer from "./components/Footer";
// import './App.css'; // Import the CSS file

// function App() {
//   return (
//     <div className="App container-fluid d-flex flex-column min-vh-100">
//       <Header />
//       <main className="flex-grow-1">
//         <section className="hero-section">
//           <div className="container">
//             <div className="row">
//               <PaymentForm />
//               <DisplayTable />
//             </div>
//           </div>
//         </section>
//       </main>
//       <Footer />
//     </div>
//   );
// }

// export default App;


