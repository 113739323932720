// import React from "react";
// import './Table.css'; // Import the CSS file

// const SuprizePrizeTable = () => {
//   return (
//     <div className="table-container">
//       <h2 className="text-center mb-4">Surprise Prizes</h2>
//       <div className="table-responsive">
//         <table className="table table-bordered table-hover">
//           <thead className="thead-dark">
//             <tr>
//               <th scope="col">Prize</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Nunix Blender</td>
//             </tr>
//             <tr>
//               <td>TCL 50 TV</td>
//             </tr>
//             <tr>
//               <td>Infinix Smart 8</td>
//             </tr>
//             <tr>
//               <td>Infinix Smart 8</td>
//             </tr>
//             <tr>
//               <td>T-Shirt</td>
//             </tr>
//             <tr>
//               <td>Ramtons RF/334</td>
//             </tr>
//             <tr>
//               <td>Pen</td>
//             </tr>
//             <tr>
//               <td>Night dress</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default SuprizePrizeTable;


import React from "react";
import './Table.css'; // Import the CSS file

const SuprizePrizeTable = () => {
  return (
    <div className="table-container" style={{ marginTop: '20px' }}>
      <h2 className="text-center mb-4">Surprise Prizes</h2>
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Prize</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>TCL 50 TV  </td>
            </tr>
            <tr>
              <td>Home Theatre</td>
            </tr>
            <tr>
              <td>Infinix Smart 8</td>
            </tr>
           
            <tr>
              <td>Bluetooth Speaker</td>
            </tr>
            <tr>
              <td>Ramtons RF/334</td>
            </tr>
            <tr>
              <td>Laptop</td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SuprizePrizeTable;
