import React from "react";
import DisplayTable from "../components/PawapayDisplayTable";
import Header from "../components/Header";
import PaymentForm from "../components/PawapayPaymentForm";
import Footer from "../components/Footer";
import airtelLogo from '../assets/airtel_zmb.png';
import mtnLogo from '../assets/mtn_momo_gha.png';


const UGPage = () => {
  const PawapayPaymentformProps = {
    currency: "UGX",
    currencyOperator: "UGX",
    countrycode: 256,
    minAmount: 500,
    maxAmount: 50000,
    winuptoAmount:"500,000",
    platform:"web",
    serviceCode:"3000",
    validPrefixes: ["07"],
    operators: [  
      { id: 'MTN', name: 'MTN', logo: mtnLogo },
      { id: 'Airtel', name: 'Airtel', logo: airtelLogo }
    ],
    operatorValues: {
      "MTN": "MTN_MOMO_UGA",
      "Airtel": "AIRTEL_OAPI_UGA"
    }
  };

  return (
    <div className="App container-fluid d-flex flex-column min-vh-100">
      <Header />
      <main className="flex-grow-1">
        <section className="hero-section">
          <div className="container">
            <div className="row">
              <PaymentForm {...PawapayPaymentformProps} />
              <DisplayTable
                countryCode={PawapayPaymentformProps.countrycode.toString()}
                currency={PawapayPaymentformProps.currency}
                firstDigits={PawapayPaymentformProps.validPrefixes.map(Number)}
                minBetAmount={PawapayPaymentformProps.minAmount}
                maxBetAmount={PawapayPaymentformProps.maxAmount}
                count={11}
                tableHeading="Top Recent Winners"
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default UGPage;