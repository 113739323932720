import React from 'react';

const HeaderGhanaApp = () => {
  return (
    <header className="bg-[#061126] text-white rounded-lg" style={{ margin: 0, maxWidth: '100%', height: '182px' }}>
      <div className="flex justify-between items-center h-full px-8">
        
        {/* Logo Section on Card */}
        <div className="flex items-center space-x-4 bg-[#0A1937] py-2 px-6 rounded-lg h-full">
          <img
            src="your-logo-url.png"
            alt="MegaCash Logo"
            className="h-24"
          />
          <div>
            <h1 className="text-xl font-bold">MEGACASH</h1>
            <p className="text-sm">LUCKY BOX</p>
          </div>
        </div>

        {/* Navigation Links */}
        <nav className="flex space-x-8 text-sm">
          <a href="#home" className="hover:text-gray-400">HOME</a>
          <a href="#how-to-play" className="hover:text-gray-400">HOW TO PLAY?</a>
          <a href="#faqs" className="hover:text-gray-400">FAQS</a>
          <a href="#prizes" className="hover:text-gray-400">PRIZES</a>
          <a href="#winners" className="hover:text-gray-400">WINNERS</a>
          <a href="#invite" className="hover:text-gray-400">INVITE FRIENDS</a>
        </nav>

        {/* Social Media Icons */}
        <div className="flex space-x-4">
          <a href="#tiktok">
            <img
              src="your-tiktok-icon-url.png"
              alt="TikTok"
              className="h-6 w-6"
            />
          </a>
          <a href="#instagram">
            <img
              src="your-instagram-icon-url.png"
              alt="Instagram"
              className="h-6 w-6"
            />
          </a>
          <a href="#facebook">
            <img
              src="your-facebook-icon-url.png"
              alt="Facebook"
              className="h-6 w-6"
            />
          </a>
        </div>
      </div>
    </header>
  );
};

export default HeaderGhanaApp;


