import React from 'react';
import Header from "../components/HeaderGhana";
import Footer from "../components/FooterGhana";

function Terms() {
    return (
        <div className="App container-fluid d-flex flex-column min-vh-100">
            <Header />
            <main className="flex-grow-1" style={{ marginTop: '120px' }}>
                <section className="hero-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10">
                                <h2 className="text-center mb-4">Terms and Conditions</h2>
                                <div className="terms-content">
                                    <h3>1. Introduction</h3>
                                    <p>1.1 By entering the Mega Cash Lucky Box Game (hereinafter referred to as "Lucky Box"), participants agree to be bound by these terms and conditions.</p>
                                    <p>1.2 These terms and conditions (hereinafter referred to as "The Terms") govern the participation in the Mega Cash Lucky Box Game.</p>
                                    <p>1.3 The Mega Cash Lucky Box Game is being run by Sparkhouse Promotions Ltd (hereinafter also referred to as Organizer) on the NLA Caritas Platform and regulated by the National Lotteries Authority.</p>
                                    <h3>2. Eligibility</h3>
                                    <p>2.1 All participants in the Game draw should be 18 years or above in accordance with the laws of Ghana.</p>
                                    <p>2.2 In entering the Game draw, a participant confirms their eligibility to do so and eligible to claim any prize that may won.</p>
                                    <p>2.3 Employees, affiliates, and immediate family members of the Organizer are not eligible to participate.</p>
                                    <p>2.4 Participants in the Game draw will be identified with their mobile numbers used to enter the Game draw.</p>
                                    <h3>3. Entries and Entry Fee’s</h3>
                                    <p>3.1 Entry to the Game draw may be one time or multiple times.</p>
                                    <p>3.2 The entry fee to the Lucky Box Game equates to Ghs5 to Ghs2,000.</p>
                                    <p>3.3 Entry fee to the Game is non-refundable.</p>
                                    <p>3.4 A participant may play with the same number multiple times. This action by a participant is not a guarantee of winning but may increase the chances of a participant emerging among the winners.</p>
                                    <h3>4. How to enter Game Draw</h3>
                                    <p>4.1 A participant enters the Mega Cash Lucky Box Game by dialing USSD short code advertised by the Station or Media House or via web portal www.megacashluckybox.com</p>
                                    <p>4.2 The Short code is accessible on all mobile networks in Ghana thus (MTN, Vodafone, AT-Airtel Tigo.</p>
                                    <p>4.3 Mobile numbers used for the Game by participants should be registered for mobile money on the respective mobile network.</p>
                                    <p>4.4 Participants will need to follow the prompts on the shortcode or web interface to its final completion and receive confirmation via SMS on successful entry.</p>
                                    <p>4.5 Sparkhouse Promotions Ltd will not accept any responsibility if the mobile network fails, or connectivity is unreliable while participants try to engage the game platforms.</p>
                                    <h3>5. The Mega Cash Lucky Box Game Draw</h3>
                                    <p>5.1 The Game utilizes a Random Number Generator system to select which is the correct box in a particular session.</p>
                                    <p>5.2 The Mega Cash Lucky Box Game informs a player whether they have won or not immediately after a session has been completed.</p>
                                    <p>5.3 Sparkhouse Promotions Ltd reserves the right to cancel, amend, suspend, or postpone the Game where it becomes necessary to do so.</p>
                                    <p>5.4 All Mega Cash Lucky Box Game is conducted in a fair and transparent manner.</p>
                                    <h3>6. Winner Selection</h3>
                                    <p>6.1 The game generates automatic winners.</p>
                                    <p>6.2 Multiple entries into the Game is no guarantee of winning.</p>
                                    <p>6.3 The winners who emerge in the game will be notified through the mobile number used to enter the game.</p>
                                    <p>6.4 Some winners may be contacted live to give their impressions on the Game.</p>
                                    <h3>6.5 Winner Verification</h3>
                                    <p>6.5.1. Eligibility Verification</p>
                                    <p>a) All winners must meet the eligibility criteria as specified in these Terms and Conditions. The Organizer reserves the right to verify the eligibility of the winners, which may include but is not limited to age and identity.</p>
                                    <p>b) Winners may be required to provide proof of their age and identity upon request by the Organizer. Failure to provide such proof may result in disqualification.</p>
                                    <p>6.5.2. Rightful Ownership of Mobile number</p>
                                    <p>a) Winners must be the rightful owner of the mobile number used for entering the Game. Any use of another person's mobile number without proper authorization may result in disqualification.</p>
                                    <h3>7. The Prizes</h3>
                                    <p>7.1. The Prizes will be automatically transferred into the winner’s Mobile Money Account upon completion of the USSD or web process.</p>
                                    <p>7.2. Cash Prizes for the game will be duly displayed on the game website and may be amended from time to time.</p>
                                    <p>7.3. The prizes offered are non-exchangeable and non-transferable.</p>
                                    <p>7.4. The organizer reserves the right to substitute prizes with another prize of equal or higher value if circumstances beyond our control make it necessary to do so.</p>
                                    <p>7.5. Prize Delivery</p>
                                    <p>7.5.1. Cash Prizes</p>
                                    <p>a) Cash prizes will be delivered to the winners via mobile money transfer in accordance with the payment details provided by the winners. The Organizer will request the necessary payment information, including mobile money account details, to facilitate the prize transfer.</p>
                                    <p>b) Delivery fees for cash prizes will be borne by the Organizer. Winners are not responsible for any delivery charges associated with receiving their cash prizes.</p>
                                    <p>7.6. Delivery Timeframes/Acceptance</p>
                                    <p>7.6.1. Cash Prizes are delivered instantly.</p>
                                    <p>7.6.2. The Organizer will make reasonable efforts to deliver cash prizes in a timely manner. However, delivery timeframes may vary due to circumstances beyond the control of the Organizer such as network connectivity or reliability defects. The Organizer or its media partners will not be liable for any delays or issues arising during the delivery process.</p>
                                    <p>7.6.3. Prize Acceptance</p>
                                    <p>Winners are responsible for accepting the prize upon delivery. If a prize cannot be successfully delivered to a winner due to their unavailability or refusal to accept the prize, the Organizer reserves the right to consider the prize forfeited.</p>
                                    <h3>8. Data Protection and Publicity</h3>
                                    <p>8.1. Data Collection and use</p>
                                    <p>The Organizer will collect and process participant data solely for the purpose of administering the Game. By participating in the Game, participants consent to the collection, processing, and use of their data for this purpose. Personal data collected may include, but is not limited to, names, contact information, and any other information necessary for the administration of the Game.</p>
                                    <p>8.2. Data Security</p>
                                    <p>The Organizer shall take appropriate technical and organizational measures to secure participant data against unauthorized access, loss, alteration, or disclosure. These measures will comply with the Data Protection Act, 2012 (Act 843) of Ghana, and relevant regulations.</p>
                                    <p>8.3. Data Retention</p>
                                    <p>Participant data will only be retained for as long as necessary to fulfill the purposes for which it was collected or as required by applicable laws and regulations.</p>
                                    <p>8.4. Data Sharing</p>
                                    <p>The Organizer will not sell, share, or disclose participant data to third parties, except as required by law or as necessary for the administration of the Game.</p>
                                    <p>8.5. Winners agree to the release of portions of their mobile numbers used for entering the Game draw for the purposes of announcing Winners as well as promoting the Mega Cash Lucky Box Game on radio, TV, social media, newspaper.</p>
                                    <p>8.6. Winners Phone number will not be fully displayed to protect the participant. E.g., a winning phone number 0544999555 will appear 0544x9xx55.</p>
                                    <p>8.7. Participants in the Game may apply for information regarding their play by contacting sparkhousepromotions@gmail.com.</p>
                                    <p>8.8. All data arising from entries into the Mega Cash Lucky Box Game shall be kept private and confidential and used for only authorized purposes. Please refer to our Privacy Policy.</p>
                                    <p>8.9. Participant data will be collected and processed in accordance with applicable data protection laws and the Organizer's privacy policy.</p>
                                    <h3>9. Limitation of Liability</h3>
                                    <p>9.1. Except for any liability that cannot be excluded by law, the Organizer (including its officers, employees, and agents) excludes all liability (including negligence), for any personal injury; or any loss or damage (including loss of opportunity); whether direct, indirect, special, or consequential, arising in any way out of the Mega Cash Lucky Box Game.</p>
                                    <p>9.2. The Organizer is not responsible for any damage or loss arising from the use of the mobile numbers or any unauthorized access to participant mobile number accounts.</p>
                                    <h3>10. General Terms</h3>
                                    <p>10.1. The Organizer's decision is final, and no correspondence will be entered into regarding the outcome of the Game.</p>
                                    <p>10.2. The Organizer reserves the right to amend these terms and conditions at any time, including cancellation or suspension of the Game, where it becomes necessary to do so.</p>
                                    <p>10.3. These Terms and Conditions shall be governed by and construed in accordance with the laws of Ghana, and participants submit to the exclusive jurisdiction of the courts of Ghana.</p>
                                    <p>10.4. Any questions or complaints regarding the Mega Cash Lucky Box Game should be directed to the Organizer at sparkhousepromotions@gmail.com.</p>
                                    <p>10.5. Participation in the Game implies full acceptance of these Terms and Conditions. By entering the Game, participants agree to be bound by these Terms and Conditions.</p>
                                    <p>10.6. The Organizer reserves the right to modify, suspend, or terminate the Game or disqualify any participant at its sole discretion.</p>
                                    <p>10.7. The Organizer reserves the right to disqualify any participant found to be engaging in fraudulent activities or violating these terms and conditions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default Terms;

