import React from 'react';
import spark from '../assets/spark.png';
import NLA from '../assets/nla2.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  const logoStyle = {
    width: '150px',
    height: '150px',
    objectFit: 'contain'
  };

  return (
    <footer className="text-white text-center text-lg-start" style={{ backgroundColor: '#e76a20' }}>
      {/* Grid container */}
      <div className="container p-4">
        {/* Logo row */}
        <div className="d-flex justify-content-center mb-4">
          <div className="mx-2">
            <img src={spark} alt="Spark Logo" style={logoStyle} />
          </div>
          <div className="mx-2">
            <img src={NLA} alt="NLA Logo" style={logoStyle} />
          </div>
        </div>
        {/* Grid row */}
        <div className="row justify-content-center">
          {/* Grid column */}
          <div className="col-lg-6 col-md-12 mb-4 mb-md-0 text-center">
            {/* <h5 className="text-uppercase">About</h5> */}
            <p>
              Mega Cash Box is an exciting and interactive game that offers players a thrilling experience of chance and reward. In this captivating game, users are presented with six boxes, each potentially containing cash or surprises.
            </p>
          </div>
          {/* Grid column */}

          {/* Grid column */}
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0 text-center">
            <h5 className="text-uppercase"></h5>
            <ul className="list-unstyled mb-0">
              <li>
                <Link to="/terms" className="text-white">Terms and Conditions</Link>
              </li>
              <li>
                <a href="/privacy" className="text-white">Privacy Policy</a>
              </li>
            </ul>
          </div>
          {/* Grid column */}
        </div>
        {/* Grid row */}
      </div>
      {/* Grid container */}

      {/* Copyright */}
      <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © 2024 Copyright:
        <a className="text-white" href="https://www.megacashluckybox.com/gh">Mega Cash Box</a>
      </div>
      {/* Copyright */}
    </footer>
  );
};

export default Footer;


// import React from 'react';
// import spark from '../assets/spark.png';
// import ghanalogo from '../assets/ghanabt.png';
// import NLA from '../assets/nla2.png'
// import { Link } from 'react-router-dom';

// const Footer = () => {
//   const logoStyle = { width: '150px',  height: '150px',
//     objectFit: 'contain' };

//   return (
//     <footer className="text-white text-center text-lg-start" style={{ backgroundColor: '#e76a20' }}>
//       {/* Grid container */}
//       <div className="container p-4">
//         {/* Logo row */}
//         <div className="d-flex justify-content-center mb-4">
//           <div className="mx-2">
//             <img src={spark} alt="Spark Logo" style={logoStyle} />
//           </div>
//           <div className="mx-2">
//             <img src={NLA} alt="Ghana Logo" style={logoStyle} />
//           </div>
//         </div>
//         {/* Grid row */}
//         <div className="row justify-content-center">
//           {/* Grid column */}
//           <div className="col-lg-6 col-md-12 mb-4 mb-md-0 text-center">
//             <h5 className="text-uppercase"></h5>
//             <p>
//             Mega Cash Box is an exciting and interactive game that offers players a thrilling experience of chance and reward. In this captivating game, users are presented with six boxes, each potentially containing cash or surprises.
//             </p>
//           </div>
//           {/* Grid column */}

//           {/* Grid column */}
//           <div className="col-lg-3 col-md-6 mb-4 mb-md-0 text-center">
//             <h5 className="text-uppercase"></h5>
//             <ul className="list-unstyled mb-0">
//               <li>
//                 {/* <a href="#!" className="text-white">Terms and Conditions</a> */}
//                 <Link to="/terms">Terms and Conditions</Link>
//               </li>
//               <li>
//                 <a href="#!" className="text-white">Privacy Policy</a>
//               </li>
//             </ul>
//           </div>
//           {/* Grid column */}
//         </div>
//         {/* Grid row */}
//       </div>
//       {/* Grid container */}

//       {/* Copyright */}
//       <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
//         © 2024 Copyright:
//         <a className="text-white" href="https://www.megacashluckybox.com/gh">Mega Cash Box</a>
//       </div>
//       {/* Copyright */}
//     </footer>
//   );
// };

// export default Footer;






// import React from 'react';
// import spark from '../assets/spark.png';
// import ghanalogo from '../assets/ghanabt.png';
// import NLA from '../assets/NLA.png'

// const Footer = () => {
//   const logoStyle = { height: '100px', objectFit: 'contain' };

//   return (
//     <footer className="text-white text-center text-lg-start" style={{ backgroundColor: '#e76a20' }}>
//       {/* Grid container */}
//       <div className="container p-4">
//         {/* Logo row */}
//         <div className="d-flex justify-content-center mb-4">
//           <div className="mx-2">
//             <img src={spark} alt="Spark Logo" style={logoStyle} />
//           </div>
//           <div className="mx-2">
//             <img src={NLA} alt="Ghana Logo" style={logoStyle} />
//           </div>
//         </div>
//         {/* Grid row */}
//         <div className="row justify-content-center">
//           {/* Grid column */}
//           <div className="col-lg-6 col-md-12 mb-4 mb-md-0 text-center">
//             <h5 className="text-uppercase"></h5>
//             <p>
            
//             </p>
//           </div>
//           {/* Grid column */} Mega Cash Box is an exciting and interactive game that offers players a thrilling experience of chance and reward. In this captivating game, users are presented with six boxes, each potentially containing cash, hidden treasures, or surprises
//           {/* Grid column */}
//           <div className="col-lg-3 col-md-6 mb-4 mb-md-0 text-center">
//             <h5 className="text-uppercase"></h5>
//             <ul className="list-unstyled mb-0">
//               <li>
//                 <a href="#!" className="text-white">Terms and Conditions</a>
//               </li>
//               <li>
//                 <a href="#!" className="text-white">Privacy Policy</a>
//               </li>
//             </ul>
//           </div>
//           {/* Grid column */}
//         </div>
//         {/* Grid row */}
//       </div>
//       {/* Grid container */}

//       {/* Copyright */}
//       <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
//         © 2024 Copyright:
//         <a className="text-white" href="https://www.megacashluckybox.com/gh">Mega Cash Box</a>
//       </div>
//       {/* Copyright */}
//     </footer>
//   );
// };

// export default Footer;



// import React from 'react';
// import spark from '../assets/spark.png';

// const Footer = () => {
//   return (
//     <footer className="text-white text-center text-lg-start" style={{ backgroundColor: '#e76a20' }}>
//       {/* Grid container */}
//       <div className="container p-4">
//         {/* Logo */}
//         <div className="text-center mb-4">
//           <img src={spark} alt="Spark Logo" style={{ width: '150px' }} />
//         </div>

//         <div className="text-center mb-4">
//           <img src={spark} alt="Spark Logo" style={{ width: '150px' }} />
//         </div>
//         {/* Grid row */}
//         <div className="row justify-content-center">
//           {/* Grid column */}
//           <div className="col-lg-6 col-md-12 mb-4 mb-md-0 text-center">
//             <h5 className="text-uppercase"></h5>
//             <p>
//               Mega Cash Box is an exciting, interactive game designed to bring a thrilling experience of chance and reward to its players. The game revolves around a simple yet captivating concept where users are presented with an array of six boxes, each containing hidden treasures or surprises.
//             </p>
//           </div>
//           {/* Grid column */}

//           {/* Grid column */}
//           <div className="col-lg-3 col-md-6 mb-4 mb-md-0 text-center">
//             <h5 className="text-uppercase"></h5>
//             <ul className="list-unstyled mb-0">
//               <li>
//                 <a href="#!" className="text-white">Terms and Conditions</a>
//               </li>
//               <li>
//                 <a href="#!" className="text-white">Privacy Policy</a>
//               </li>
//             </ul>
//           </div>
//           {/* Grid column */}
//         </div>
//         {/* Grid row */}
//       </div>
//       {/* Grid container */}

//       {/* Copyright */}
//       <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
//         © 2024 Copyright:
//         <a className="text-white" href="https://www.megacashluckybox.com/gh">Mega Cash Box</a>
//       </div>
//       {/* Copyright */}
//     </footer>
//   );
// };

// export default Footer;

// import React from 'react';

// const FooterGhana = () => {
//   return (
//     <footer
//       style={{
//         backgroundColor: '#ed6525', // Branding color
//         color: 'white',
//         width: '100%',
//         padding: '1rem 0', // Add some padding for better spacing
//       }}
//     >
//       <div className="container">
//         <div className="row">
//           <div className="col-12 col-md-6">
//             <p style={{ margin: 0 }}>&copy; 2024 Chapabox. All rights reserved.</p>
//           </div>
//           <div className="col-12 col-md-6 text-md-end">
//             <ul className="list-inline" style={{ margin: 0, padding: 0 }}>
//               <li className="list-inline-item" style={{ display: 'inline-block', marginRight: '1rem' }}>
//                 <a href="#" style={{ color: 'white', textDecoration: 'none' }}>
//                   Terms and Conditions
//                 </a>
//               </li>
//               <li className="list-inline-item" style={{ display: 'inline-block', marginRight: '1rem' }}>
//                 <a href="#" style={{ color: 'white', textDecoration: 'none' }}>
//                   About
//                 </a>
//               </li>
//               <li className="list-inline-item" style={{ display: 'inline-block', marginRight: '1rem' }}>
//                 <a href="#" style={{ color: 'white', textDecoration: 'none' }}>
//                   Privacy Policy
//                 </a>
//               </li>
//               <li className="list-inline-item" style={{ display: 'inline-block' }}>
//                 <a href="#" style={{ color: 'white', textDecoration: 'none' }}>
//                   Contact
//                 </a>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default FooterGhana;